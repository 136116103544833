import React, {useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {contextArrFooter} from '../../db/db_footer';

// import './NavBar.scss'

function NavBar(props) {
	let {locale} = props;
	let location = useLocation();
	
	const navItems = [
		{
			id: 1,
			url: '/' + locale + '/',
			classes: (location.pathname === '/ru/' || location.pathname === '/ua/') ? 'lng-menu1_footer active' : 'lng-menu1_footer',
			exact: true
		},
		{id: 2, url: 'ground', classes: 'lng-menu2_footer', exact: false},
		{id: 3, url: 'sowing', classes: 'lng-menu3_footer', exact: false},
		{id: 4, url: 'protection', classes: 'lng-menu4_footer', exact: false},
		{id: 5, url: 'complex', classes: 'lng-menu5_footer', exact: false}
	]
	
	useEffect(() => {
		for (let key in contextArrFooter) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrFooter[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<nav className="menu">
			<ul>
				{navItems.map(item => (
					<li key={item.id}>
						<NavLink exact={item.exact} to={item.url} className={item.classes}/>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default NavBar;

export const contextArrCookies = {
	// Page: Cookies
	cookies_offer_h1: {
		ua: 'Політика cookies',
		ru: 'Политика cookies',
	},
	cookies_offer_h3: {
		ua: 'Під час користування сайтом можуть оброблятись окремі види інформації',
		ru: 'При использовании сайта могут обрабатываться отдельные виды информации',
	},
	cookies_offer_btn: {
		ua: 'Детальнiше',
		ru: 'Детальнее',
	},
	
	// Text
	cookies_text: {
		ua: `
		<h2>Політика cookies</h2><br/>
		<h3>Обробка іншої інформації</h3><br/>
		Під час користування сайтом можуть оброблятись окремі види інформації, як віднесеної до персональних даних, так і такої, що не є персональними даними у розумінні чинного законодавства.<br/>
		Інформація про Користувачів обробляється під час користування сайтом завдяки, зокрема:<br/>
		- файлам cookies (файли, що створюються сайтом для зберігання інформації про користувача, спрощують роботу із сайтом, дозволяють зберігати налаштування сайту, пропонувати найбільш відповідну інформацію).<br/>
		`,
		ru: `
		<h2>Политика cookies</h2><br/>
		<h3>Обработка другой информации</h3><br/>
		При использовании сайта могут обрабатываться отдельные виды информации, как отнесенной к персональным данным, так и не являющиеся персональными данными в понимании действующего законодательства.<br/>
		Информация о Пользователях обрабатывается при использовании сайта, в частности:<br/>
		- файлам cookies (файлы, создаваемые сайтом для хранения информации о пользователе, упрощают работу с сайтом, позволяют сохранять настройки сайта, предлагать наиболее подходящую информацию).<br/>
		`,
	}
}

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Picture from '../../components/Picture/Picture';
import TitleH3 from '../../components/Title/TitleH3';
import './ListImage.scss';

const ListImage = (props) => {
	
	return (
		<section id={props.id} className="list-image">
			<div className="container wrap">
				<TitleH2 h2Class={props.h2Class}/>
				<div className="wrapper">
					<div className="left" style={{order: props.lt}}>
						<ul>
							{props.list.map(item =>
								<li key={item.id}>
									<TitleH3 h3Class={item.classesH3}/>
									<p className={item.classesP}/>
									<span className={item.classesSpan}/>
								</li>
							)}
						</ul>
					</div>
					<div className="right" style={{order: props.rt}}>
						<Picture classes={props.shadow} src={props.image} alt={props.id}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ListImage;

import groundItemImg1 from '../images/icons/item1.png';
import groundItemImg2 from '../images/icons/item2.png';
import groundItemImg3 from '../images/icons/item3.png';
import groundItemImg4 from '../images/icons/item4.png';
import groundItemImg5 from '../images/icons/item5.png';
import groundItemImg6 from '../images/icons/item6.png';

import times from '../images/icons/times.png';
import check from '../images/icons/check-mark.png';

import sl1 from '../images/brands/slide1.jpg';
import sl2 from '../images/brands/slide2.jpg';
import sl3 from '../images/brands/slide3.jpg';
import sl4 from '../images/brands/slide4.jpg';
import sl5 from '../images/brands/slide5.jpg';
import sl6 from '../images/brands/slide6.jpg';
import sl7 from '../images/brands/slide7.jpg';
import sl8 from '../images/brands/slide8.jpg';

import groundItemServiceImg1 from '../images/icons/services/item01.jpg';
import groundItemServiceImg2 from '../images/icons/services/item02.jpg';
import groundItemServiceImg3 from '../images/icons/services/item03.jpg';
import groundItemServiceImg4 from '../images/icons/services/item04.jpg';
import groundItemServiceImg5 from '../images/icons/services/item05.jpg';
import groundItemServiceImg6 from '../images/icons/services/item-2.jpg';

import groundItemSuccessImg1 from '../images/icons/services/item-1.jpg';
import groundItemSuccessImg2 from '../images/icons/services/item-2.jpg';
import groundItemSuccessImg3 from '../images/icons/services/item-3.jpg';

export const listSixItems = [
	{id: 1, classes: 'lng-ground_item_p1', pic: groundItemImg1},
	{id: 2, classes: 'lng-ground_item_p2', pic: groundItemImg2},
	{id: 3, classes: 'lng-ground_item_p3', pic: groundItemImg3},
	{id: 4, classes: 'lng-ground_item_p4', pic: groundItemImg4},
	{id: 5, classes: 'lng-ground_item_p5', pic: groundItemImg5},
	{id: 6, classes: 'lng-ground_item_p6', pic: groundItemImg6},
]

export const listTechnics = [sl1, sl2, sl3, sl4, sl5, sl6, sl7, sl8]

export const listPrinciples = [
	{id: 1, classesH3: 'lng-ground_principles1_h3', classesP: 'dn', classesSpan: 'lng-ground_principles_span1'},
	{id: 2, classesH3: 'lng-ground_principles2_h3', classesP: 'dn', classesSpan: 'lng-ground_principles_span2'},
	{id: 3, classesH3: 'lng-ground_principles3_h3', classesP: 'dn', classesSpan: 'lng-ground_principles_span3'}
]

export const listMethod = [
	{id: 1, classesH3: 'dn', classesP: 'lng-ground_method_p1', classesSpan: 'dn'},
	{id: 2, classesH3: 'dn', classesP: 'lng-ground_method_p2', classesSpan: 'dn'},
	{id: 3, classesH3: 'dn', classesP: 'lng-ground_method_p3', classesSpan: 'dn'},
	{id: 4, classesH3: 'dn', classesP: 'lng-ground_method_p4', classesSpan: 'dn'},
	{id: 5, classesH3: 'dn', classesP: 'lng-ground_method_p5', classesSpan: 'dn'}
]

export const listGroundItemsServices = [
	{id: 1, classes: 'lng-ground_services_p1', pic: groundItemServiceImg1},
	{id: 2, classes: 'lng-ground_services_p2', pic: groundItemServiceImg2},
	{id: 3, classes: 'lng-ground_services_p3', pic: groundItemServiceImg3},
	{id: 4, classes: 'lng-ground_services_p4', pic: groundItemServiceImg4},
	{id: 5, classes: 'lng-ground_services_p5', pic: groundItemServiceImg5},
	{id: 6, classes: 'lng-ground_services_p6', pic: groundItemServiceImg6}
]

export const listWorking = [
	{id: 1, classesH3: 'lng-ground_working1_h3', classesP: 'dn', classesSpan: 'lng-ground_working_span1'},
	{id: 2, classesH3: 'lng-ground_working2_h3', classesP: 'dn', classesSpan: 'lng-ground_working_span2'},
	{id: 3, classesH3: 'lng-ground_working3_h3', classesP: 'dn', classesSpan: 'lng-ground_working_span3'}
]

export const listGroundItemsResult = [
	{id: 1, classes: 'lng-ground_result_p1', h3Class: 'lng-ground_result1_h3'},
	{id: 2, classes: 'lng-ground_result_p2', h3Class: 'lng-ground_result2_h3'},
	{id: 3, classes: 'lng-ground_result_p3', h3Class: 'lng-ground_result3_h3'},
	{id: 4, classes: 'lng-ground_result_p4', h3Class: 'lng-ground_result4_h3'},
	{id: 5, classes: 'lng-ground_result_p5', h3Class: 'lng-ground_result5_h3'},
	{id: 6, classes: 'lng-ground_result_p6', h3Class: 'lng-ground_result6_h3'},
	{id: 7, classes: 'lng-ground_result_p7', h3Class: 'lng-ground_result7_h3'}
]

export const listSliderTariffs = [
	{
		id: 1,
		head: 'S',
		el1: times,
		el2: times,
		el3: times,
		el4: times,
		el5: times,
		el6: times,
		el7: times,
		el8: times,
		el9: times,
		el10: check,
		el11: times,
		el12: times,
		el13: check,
		el14: times,
		el15: times,
		price: '51$'
	},
	{
		id: 2,
		head: 'M',
		el1: check,
		el2: times,
		el3: times,
		el4: check,
		el5: times,
		el6: times,
		el7: check,
		el8: check,
		el9: times,
		el10: check,
		el11: check,
		el12: times,
		el13: times,
		el14: times,
		el15: times,
		price: '63$'
	},
	{
		id: 3,
		head: 'L',
		el1: check,
		el2: check,
		el3: check,
		el4: times,
		el5: check,
		el6: times,
		el7: check,
		el8: check,
		el9: times,
		el10: check,
		el11: check,
		el12: times,
		el13: times,
		el14: times,
		el15: check,
		price: '73$'
	},
	{
		id: 4,
		head: 'XL',
		el1: check,
		el2: check,
		el3: check,
		el4: times,
		el5: times,
		el6: check,
		el7: check,
		el8: check,
		el9: times,
		el10: times,
		el11: times,
		el12: check,
		el13: check,
		el14: check,
		el15: check,
		price: '285$'
	}
]

export const listGroundItemsSuccess = [
	{id: 1, classes: 'lng-ground_success_p1', pic: groundItemSuccessImg1},
	{id: 2, classes: 'lng-ground_success_p2', pic: groundItemSuccessImg2},
	{id: 3, classes: 'lng-ground_success_p3', pic: groundItemSuccessImg3}
]

export const listRadius = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_radius_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_radius_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_radius_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_radius_li4'}
]

export const listСonditions = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_сonditions_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_сonditions_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-ground_сonditions_li3'}
]

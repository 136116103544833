export const contextArrGround = {
	// Page: Ground - Підготовка грунту
	ground_offer_h1: {
		ua: 'Обробіток ґрунту в подільському регіоні',
		ru: 'Подготовка поля к посеву в регионе Подолья',
	},
	ground_offer_h3: {
		ua: 'Підготуємо поле до будь-якої культури зі строгим дотриманням строків і агротехнічних вимог',
		ru: 'Подготовим поле для любой культуры со строгим соблюдением сроков и агротехнических требований',
	},
	ground_offer_btn: {
		ua: 'Замовити послугу',
		ru: 'Заказать услугу',
	},
	ground_offer_h2: {
		ua: 'Технології обробітку',
		ru: 'Технологии обработки',
	},
	ground_item_p1: {
		ua: 'Лущення',
		ru: 'Лущение',
	},
	ground_item_p2: {
		ua: 'Культивація',
		ru: 'Культивация',
	},
	ground_item_p3: {
		ua: 'Подрібнення',
		ru: 'Измельчение',
	},
	ground_item_p4: {
		ua: 'Оранка',
		ru: 'Вспашка',
	},
	ground_item_p5: {
		ua: 'Дискування',
		ru: 'Дискование',
	},
	ground_item_p6: {
		ua: 'Рихлення',
		ru: 'Рыхление',
	},
	
	// Slider Brands
	ground_technics_h2: {
		ua: 'Наша техніка',
		ru: 'Наша техника',
	},
	ground_technics_p: {
		ua: 'Парк <b>більше 30 одиниць техніки</b>, для виконання всіх виробничих агрооперацій. Серед них такі бренди: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
		ru: 'Парк <b>более 30 единиц техники</b>, для выполнения всех производственных агро операций. Среди них такие бренды: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
	},
	
	// Banner #1
	ground_banner_title: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	ground_banner_p: {
		ua: 'Об\'єднує в собі всі комплекси агробiзнесу',
		ru: 'Объединяет в себя все комплексы агробизнеса',
	},
	ground_banner_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Slider ParkTechnics
	ground_park_technics_h2: {
		ua: 'Для обробки поля використовуємо парк техніки',
		ru: 'Для обработки поля используем парк техники',
	},
	ground_sl1_h3: {
		ua: 'Kello Bilt 225 TSW',
		ru: 'Kello Bilt 225 TSW',
	},
	ground_sl1_h4: {
		ua: 'Важка дискова борона',
		ru: 'Тяжелая дисковая борона',
	},
	ground_sl1_p1: {
		ua: 'Маса борони: <b>8000 кг</b>',
		ru: 'Масcа бороны: <b>8000 кг</b>',
	},
	ground_sl1_p2: {
		ua: 'Робоча глибина: <b>50-250 мм</b>',
		ru: 'Рабочая глубина: <b>50-250 мм</b>',
	},
	ground_sl1_p3: {
		ua: 'Діаметр дисків: <b>710 мм</b>',
		ru: 'Диаметр дисков: <b>710 мм</b>',
	},
	ground_sl1_p4: {
		ua: 'Ширина захвату: <b>5.5 м</b>',
		ru: 'Ширина захвата: <b>5.5 м</b>',
	},
	ground_sl1_p5: {
		ua: 'Розроблена для використання в екстремальних умовах. Завдяки високій потужності підходить для оброблення культур з міцним кореневищем, подрібнення кукурудзяного стеблостою, закладання великої кількості поживних залишків',
		ru: 'Разработана для использования в экстремальных условиях. Благодаря высокой мощности подходит для обработки культур с крепким корневищем, измельчения кукурузного стеблестоя, закладки большого количества питательных остатков',
	},
	ground_sl2_h3: {
		ua: 'Horsch Joker 8 RT',
		ru: 'Horsch Joker 8 RT',
	},
	ground_sl2_h4: {
		ua: 'Компактна дискова борона',
		ru: 'Компактная дисковая борона',
	},
	ground_sl2_p1: {
		ua: '<b>60 дисків</b> в два ряди',
		ru: '<b>60 дисков</b> в два ряда',
	},
	ground_sl2_p2: {
		ua: 'Робоча глибина: <b>30-150 мм</b>',
		ru: 'Рабочая глубина: <b>30-150 мм</b>',
	},
	ground_sl2_p3: {
		ua: 'Діаметр дисків: <b>520 мм</b>',
		ru: 'Диаметр дисков: <b>520 мм</b>',
	},
	ground_sl2_p4: {
		ua: 'Ширина захвату: <b>8 м</b>',
		ru: 'Ширина захвата: <b>8 м</b>',
	},
	ground_sl2_p5: {
		ua: 'Розпушує верхній шар грунту<br>Подрібнює і перемішує пожнивні залишки<br>Вирівнює поверхню грунту<br>Підходить для передпосівного обробітку<br>Можна використовувати на полях з великою кількістю рослинних залишків',
		ru: 'Разрыхляет верхний шар грунта<br>Измельчает и перемешивает пожнивные остатки<br>Выравнивает поверхность почвы<br>Подходить для предпосевной обработки<br>Можно использовать на полях с большим количеством растительных остатков',
	},
	ground_sl3_h3: {
		ua: 'Schulte 5026',
		ru: 'Schulte 5026',
	},
	ground_sl3_h4: {
		ua: 'Мульчувач',
		ru: 'Мульчирователь',
	},
	ground_sl3_p1: {
		ua: 'Min висота зрізу: <b>25 мм</b>',
		ru: 'Min высота среза: <b>25 мм</b>',
	},
	ground_sl3_p2: {
		ua: 'Ширина захвату: <b>8 м</b>',
		ru: 'Ширина захвата: <b>8 м</b>',
	},
	ground_sl3_p3: {
		ua: 'Діаметр дисків: <b>420 мм</b>',
		ru: 'Диаметр дисков: <b>420 мм</b>',
	},
	ground_sl3_p4: {
		ua: 'Робоча висота: <b>30-380 мм</b>',
		ru: 'Рабочая высота: <b>30-380 мм</b>',
	},
	ground_sl3_p5: {
		ua: 'Скошує різні види стерні – кукурудзи, ріпаку, соняшника, пшениці<br>Рівномірно подрібнює пожнивні залишки<br>Рівномірно розподіляє подрібнену масу  по всій ширині захвату<br>Повторює рельєф поля',
		ru: 'Скашивает различные виды стерни – кукурузы, рапса, подсолнечника, пшеницы<br>Равномерно измельчает пожнивные остатки<br>Равномерно распределяет измельченную массу по всей ширине захвата<br>Повторяет рельеф поля',
	},
	ground_sl4_h3: {
		ua: 'Gregoire Besson DXRV',
		ru: 'Gregoire Besson DXRV',
	},
	ground_sl4_h4: {
		ua: 'Дискова борона',
		ru: 'Дисковая борона',
	},
	ground_sl4_p1: {
		ua: '<b>36 дисків</b> в два ряди',
		ru: '<b>36 дисків</b> в два ряда',
	},
	ground_sl4_p2: {
		ua: '<b>9 лап</b> в два ряда',
		ru: '<b>9 лап</b> в два ряда',
	},
	ground_sl4_p3: {
		ua: 'Ширина захвату: <b>4.7 м</b>',
		ru: 'Ширина захвата: <b>4.7 м</b>',
	},
	ground_sl4_p4: {
		ua: 'Робоча глибина лап: <b>до 450 мм</b>',
		ru: 'Рабочая глубина лап: <b>до 450 мм</b>',
	},
	ground_sl4_p5: {
		ua: 'Розпушує верхній шар грунту<br>Відновлює водо- і повітропроникність глибоких шарів<br>Подрібнює і перемішує пожнивні залишки<br>Вирівнює поверхню грунту',
		ru: 'Разрыхляет верхний слой почвы<br>Восстанавливает водо- и воздухопроницаемость глубоких слоев<br>Измельчает и перемешивает пожнивные остатки<br>Выравнивает поверхность почвы',
	},
	ground_sl5_h3: {
		ua: 'Case IH Tiger Mate',
		ru: 'Case IH Tiger Mate',
	},
	ground_sl5_h4: {
		ua: 'Культиватор',
		ru: 'Культиватор',
	},
	ground_sl5_p1: {
		ua: 'Робоча глибина: <b>до 150 мм</b>',
		ru: 'Рабочая глубина: <b>до 150 мм</b>',
	},
	ground_sl5_p2: {
		ua: 'Діаметр колес: <b>435 мм</b>',
		ru: 'Диаметр колес: <b>435 мм</b>',
	},
	ground_sl5_p3: {
		ua: 'Ширина захвату: <b>12.2 м</b>',
		ru: 'Ширина захвата: <b>12.2 м</b>',
	},
	ground_sl5_p4: {
		ua: 'Робоча глибина: <b>до 450 мм</b>',
		ru: 'Рабочая глубина: <b>до 450 мм</b>',
	},
	ground_sl5_p5: {
		ua: 'Розпушує верхній шар грунту<br>Вирівнює поверхню поля<br>Ефективно зрізає бур\'яни<br>Рівномірно розподіляє рослинну масу по всій ширині захвату',
		ru: 'Разрыхляет верхний слой почвы<br>Выравнивает поверхность поля<br>Эффективно срезает сорняки<br>Равномерно распределяет растительную массу по всей ширине захвата',
	},
	ground_sl6_h3: {
		ua: 'Strom',
		ru: 'Strom',
	},
	ground_sl6_h4: {
		ua: 'Глибокорозпушувач',
		ru: 'Глубокорыхлитель',
	},
	ground_sl6_p1: {
		ua: 'Робоча глибина: <b>250-500 мм</b>',
		ru: 'Рабочая глубина: <b>250-500 мм</b>',
	},
	ground_sl6_p2: {
		ua: '<b>7 лап</b> в два ряда',
		ru: '<b>7 лап</b> в два ряда',
	},
	ground_sl6_p3: {
		ua: 'Ширина захвату: <b>3 м</b>',
		ru: 'Ширина захвата: <b>3 м</b>',
	},
	ground_sl6_p4: {
		ua: 'Маса: <b>770 кг</b>',
		ru: 'Масcа: <b>770 кг</b>',
	},
	ground_sl6_p5: {
		ua: 'Розпушує глибокі шари грунту<br>Відновлює їх водо- і повітропроникніст, руйнує підплужну підошву<br>Попереджує ерозію грунту',
		ru: 'Разрыхляет глубокие слои почвы<br>Восстанавливает их водо- и воздухопроницаемость, разрушает подплужную подошву<br>Предупреждает эрозию почвы',
	},
	ground_sl7_h3: {
		ua: 'DN-224',
		ru: 'DN-224',
	},
	ground_sl7_h4: {
		ua: 'Розкидач добрив',
		ru: 'Разбрасыватель удобрений',
	},
	ground_sl7_p1: {
		ua: 'Робоча ширина: <b>12-24 м</b>',
		ru: 'Рабочая ширина: <b>12-24 м</b>',
	},
	ground_sl7_p2: {
		ua: 'Загальна ширина: <b>2.36 м</b>',
		ru: 'Общая ширина: <b>2.36 м</b>',
	},
	ground_sl7_p3: {
		ua: 'Ємність ящика: <b>900-2100 л</b>',
		ru: 'Емкость ящика: <b>900-2100 л</b>',
	},
	ground_sl7_p4: {
		ua: 'Маса: <b>350 кг</b>',
		ru: 'Масcа: <b>350 кг</b>',
	},
	ground_sl7_p5: {
		ua: 'Розкидання на кордоні поля під контролем системи ECOBORD і дефлектора половини ширини (стандартна комплектація) або TRIBORD (на замовлення)',
		ru: 'Разбрасывание на границе поля под контролем системы ECOBORD и дефлектора половины ширины (стандартная комплектация) или TRIBORD (под заказ)',
	},
	ground_sl8_h3: {
		ua: 'АПВУ-9',
		ru: 'АПВУ-9',
	},
	ground_sl8_h4: {
		ua: 'Агрегат для ін’єкційного внесення',
		ru: 'Агрегат для инъекционного внесения',
	},
	ground_sl8_p1: {
		ua: 'Робоча ширина: <b>6 м</b>',
		ru: 'Рабочая ширина: <b>6 м</b>',
	},
	ground_sl8_p2: {
		ua: 'Відстань між дисками: <b>25 cм</b>',
		ru: 'Расстояние между дисками: <b>25 cм</b>',
	},
	ground_sl8_p3: {
		ua: 'Об\'єм бункера: <b>2 куб.м</b>',
		ru: 'Объем бункера: <b>2 куб.м</b>',
	},
	ground_sl8_p4: {
		ua: 'Продуктивність: <b>40-60 га/добу</b>',
		ru: 'Производительность: <b>40-60 га/сутки</b>',
	},
	ground_sl8_p5: {
		ua: 'Обприскувач оснащений: технологією Section Control, яка дозволяє автоматично контролювати відключення секцій, системою вирівнювання штанги, автоматичним контролем нижнього рівня, системою Автопілот, системою контролем та фіксацією даних про площу (га), час та об\'єм розчину, який був витрачений на обприскування',
		ru: 'Опрыскиватель оснащен: технологией Section Control, которая позволяет автоматически контролировать отключения секций, системой выравнивания штанги, автоматическим контролем нижнего уровня, системой Автопилот, системой контролем и фиксацией данных о площади (га), время и объем раствора, который был затрачен на опрыскивание',
	},
	
	// Principles
	ground_principles_h2: {
		ua: 'Наші принципи при виконанні послуги обробітку',
		ru: 'Наши принципы при выполнении услуги обработки',
	},
	ground_principles1_h3: {
		ua: 'Робимо точні заміри поля',
		ru: 'Делаем точные замеры поля',
	},
	ground_principles_span1: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5см',
	},
	ground_principles2_h3: {
		ua: 'Застосовуємо паралельне водіння',
		ru: 'Применяем параллельное вождение',
	},
	ground_principles_span2: {
		ua: 'Завдяки точному сигналу отримуємо кращу якість роботи агрегата і менші витрати ПММ',
		ru: 'Благодаря точному сигналу получаем лучшее качество работы агрегата и меньшие затраты ГСМ',
	},
	ground_principles3_h3: {
		ua: 'Дотримуємось найважливіших вимог',
		ru: 'Соблюдаем самые важные требования',
	},
	ground_principles_span3: {
		ua: 'Наші принципи при виконанні послуги обробітку',
		ru: 'Наши принципы при выполнении услуги обработки',
	},
	
	// Method
	ground_method_h2: {
		ua: 'Наші принципи при виконанні послуги обробітку',
		ru: 'Наши принципы при выполнении услуги обработки',
	},
	ground_method_p1: {
		ua: 'Початок вегітації',
		ru: 'Начало вегитации',
	},
	ground_method_p2: {
		ua: 'Під час посіву',
		ru: 'Во время посева',
	},
	ground_method_p3: {
		ua: 'Основне годування',
		ru: 'Основное кормление',
	},
	ground_method_p4: {
		ua: 'Перед посівом',
		ru: 'Перед посевом',
		
	},
	ground_method_p5: {
		ua: 'Після посіву',
		ru: 'После посева',
	},
	
	// Banner #2
	ground_banner_title2: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	ground_banner_p2: {
		ua: 'Об\'єднує в собі всі комплекси агробiзнесу',
		ru: 'Объединяет в себя все комплексы агробизнеса',
	},
	ground_banner_btn2: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Slider Technics Fertilization
	ground_technics_fertilization_h2: {
		ua: 'Для внесення добрив використовуємо парк техніки',
		ru: 'Для внесения удобрений используем парк техники',
	},
	ground_sl9_h3: {
		ua: 'Jоhn Deere 4030R',
		ru: 'Jоhn Deere 4030R',
	},
	ground_sl9_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	ground_sl9_p1: {
		ua: 'Кліренс: <b>152 см</b>',
		ru: 'Клиренс: <b>152 см</b>',
	},
	ground_sl9_p2: {
		ua: 'Об\'єм бака: <b>3000 л</b>',
		ru: 'Объем бака: <b>3000 л</b>',
	},
	ground_sl9_p3: {
		ua: 'Робоча швидкість: <b>16-25 км/г</b>',
		ru: 'Рабочая скорость: <b>16-25 км/ч</b>',
	},
	ground_sl9_p4: {
		ua: 'Ширина захвату: <b>30, 36 м</b>',
		ru: 'Ширина захвата: <b>30, 36 м</b>',
	},
	ground_sl9_p5: {
		ua: 'Обприскувач оснащений: технологією Section Control, яка дозволяє автоматично контролювати відключення секцій, системою вирівнювання штанги, автоматичним контролем нижнього рівня, системою Автопілот',
		ru: 'Опрыскиватель оснащен: технологией Section Control, позволяющей автоматически контролировать отключение секций, системой выравнивания штанги, автоматическим контролем нижнего уровня, системой автопилота',
	},
	ground_sl10_h3: {
		ua: 'Jоhn Deere 4730',
		ru: 'Jоhn Deere 4730',
	},
	ground_sl10_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	ground_sl10_p1: {
		ua: 'Кліренс: <b>152 см</b>',
		ru: 'Клиренс: <b>152 см</b>',
	},
	ground_sl10_p2: {
		ua: 'Об\'єм бака: <b>3000 л</b>',
		ru: 'Объем бака: <b>3000 л</b>',
	},
	ground_sl10_p3: {
		ua: 'Робоча швидкість: <b>16-25 км/г</b>',
		ru: 'Рабочая скорость: <b>16-25 км/ч</b>',
	},
	ground_sl10_p4: {
		ua: 'Ширина захвату: <b>30 м</b>',
		ru: 'Ширина захвата: <b>30 м</b>',
	},
	ground_sl10_p5: {
		ua: 'Обприскувач оснащений: технологією Section Control, яка дозволяє автоматично контролювати відключення секцій, системою вирівнювання штанги, автоматичним контролем нижнього рівня, системою Автопілот',
		ru: 'Опрыскиватель оснащен: технологией Section Control, позволяющей автоматически контролировать отключение секций, системой выравнивания штанги, автоматическим контролем нижнего уровня, системой автопилота',
	},
	ground_sl11_h3: {
		ua: 'Саse ІН SPX4430',
		ru: 'Саse ІН SPX4430',
	},
	ground_sl11_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	ground_sl11_p1: {
		ua: 'Кліренс: <b>135 см</b>',
		ru: 'Клиренс: <b>135 см</b>',
	},
	ground_sl11_p2: {
		ua: 'Об\'єм бака: <b>4500 л</b>',
		ru: 'Объем бака: <b>4500 л</b>',
	},
	ground_sl11_p3: {
		ua: 'Робоча швидкість: <b>16-25 км/г</b>',
		ru: 'Рабочая скорость: <b>16-25 км/ч</b>',
	},
	ground_sl11_p4: {
		ua: 'Ширина захвату: <b>36 м</b>',
		ru: 'Ширина захвата: <b>36 м</b>',
	},
	ground_sl11_p5: {
		ua: 'Зберігає норму внесення при зміні швидкості руху  агрегата і рельєфу, обладнаний: вдосконаленою системою вирівнювання тиску, системою автоматичного управління висотою штанги AutoBoom, системою автоматичного управління секціями штанги AccuBoom, яка автоматично відключає секції штанги, обладнаний системою Автопілот',
		ru: 'Сохраняет норму внесения при изменении скорости движения агрегата и рельефа, оборудован: усовершенствованной системой выравнивания давления, системой автоматического управления высотой штанги AutoBoom, системой автоматического управления секциями штанги AccuBoom, которая автоматически отключает секции штанги, оборудован системой',
	},
	
	// Services
	ground_services_h2: {
		ua: 'При виконанні послуги внесення добрив використовуємо принципи точного землеробства:',
		ru: 'При выполнении услуги внесения удобрений используем принципы точного земледелия:',
	},
	ground_services_p1: {
		ua: 'Враховуємо особливості поля та культури',
		ru: 'Учитываем особенности поля и культуры',
	},
	ground_services_p2: {
		ua: 'Враховуємо хімічний стан ґрунту',
		ru: 'Учитываем химическое состояние почвы',
	},
	ground_services_p3: {
		ua: 'Рахуємо правильне співвідношення ТМЦ',
		ru: 'Считаем правильное соотношение ТМЦ',
	},
	ground_services_p4: {
		ua: 'Виконуємо диференційоване внесення добрив',
		ru: 'Выполняем дифференцированное внесение удобрений',
	},
	ground_services_p5: {
		ua: 'Скорочуємо витрати та покращуємо врожайність',
		ru: 'Сокращаем расходы и улучшаем урожайность',
	},
	ground_services_p6: {
		ua: 'Ми ефективно використовуємо ваш час',
		ru: 'Мы эффективно используем ваше время',
	},
	
	// Working
	ground_working_h2: {
		ua: 'Як ми працюємо:',
		ru: 'Как мы работаем:',
	},
	ground_working1_h3: {
		ua: 'Вивчаємо конфігурацію поля',
		ru: 'Изучаем конфигурацию поля',
	},
	ground_working_span1: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5см',
	},
	ground_working2_h3: {
		ua: 'Точний аналіз ґрунту',
		ru: 'Точный анализ почвы',
	},
	ground_working_span2: {
		ua: 'Визначаємо хімічний склад поля та співвідношення на різних ділянках',
		ru: 'Определяем химический состав поля и соотношение на разных участках',
	},
	ground_working3_h3: {
		ua: 'Вираховуємо найкращі пропорції добрив',
		ru: 'Вычисляем наилучшие пропорции удобрений',
	},
	ground_working_span3: {
		ua: 'Розуміючи конфігурацію поля та склад ґрунту, пропонуємо добрива у потрібній кількості',
		ru: 'Понимая конфигурацию поля и состав почвы, предлагаем удобрения в нужном количестве',
	},
	
	// Result
	ground_result_h2: {
		ua: 'В результаті ви отримаєте:',
		ru: 'В результате вы получите:',
	},
	ground_result1_h3: {
		ua: 'Точні дані',
		ru: 'Точные данные',
	},
	ground_result_p1: {
		ua: '<b>Точні дані</b> про рельєф та конфігурацію вашого поля',
		ru: '<b>Точные данные</b> о рельефе и конфигурации вашего поля',
	},
	ground_result2_h3: {
		ua: 'Важливi показники',
		ru: 'Важные показатели',
	},
	ground_result_p2: {
		ua: '<b>Важливi показники</b> про склад ґрунту з рівнями макроелементів у полі',
		ru: '<b>Важные показатели</b> о составе почвы с уровнями макроэлементов в поле',
	},
	ground_result3_h3: {
		ua: 'Точні пропорції',
		ru: 'Точные пропорции',
	},
	ground_result_p3: {
		ua: 'Рекомендації щодо вибору добрив та <b>точні пропорції</b> для внесення',
		ru: 'Рекомендации по выбору удобрений и <b>точные пропорции</b> для внесения',
	},
	ground_result4_h3: {
		ua: 'Рекомендації',
		ru: 'Рекомендации',
	},
	ground_result_p4: {
		ua: '<b>Рекомендації</b> по вибору найбільш підходящих для вас технологічних операцій',
		ru: '<b>Рекомендации</b> по выбору наиболее подходящих для вас технологических операций',
	},
	ground_result5_h3: {
		ua: 'Найкращi результати',
		ru: 'Лучшие результаты',
	},
	ground_result_p5: {
		ua: 'Пропозицію по вибору техніки для забезпечення <b>найкращих результатів</b> обробітку ґрунту',
		ru: 'Предложение по выбору техники для обеспечения <b>наилучших результатов</b> обработки почвы',
	},
	ground_result6_h3: {
		ua: 'Точні розрахунки',
		ru: 'Точные расчеты',
	},
	ground_result_p6: {
		ua: '<b>Точні розрахунки</b> об’єму робіт по вартості та строкам з урахуванням ваших побажань',
		ru: '<b>Точные расчеты</b> объема работ по стоимости и срокам с учетом ваших пожеланий',
	},
	ground_result7_h3: {
		ua: 'Важливо враховувати:',
		ru: 'Важно учитывать:',
	},
	ground_result_p7: {
		ua: 'Дана послуга платна, але якщо ви вирішите <b>співпрацювати з нами</b>, ми вирахуємо вартість діагностики від вартості комплексу',
		ru: 'Данная услуга платная, но если вы решите <b>сотрудничать с нами</b>, мы рассчитаем стоимость диагностики от стоимости комплекса',
	},
	
	// Banner #3
	ground_banner_title3: {
		ua: 'Вкажіть ваш контактний номер',
		ru: 'Укажите ваш контактный номер',
	},
	ground_banner_p3: {
		ua: 'щоб обговорити підготовку до посіву',
		ru: 'чтобы обсудить подготовку к посеву',
	},
	ground_banner_btn3: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Tariffs
	ground_tariffs_h2: {
		ua: 'Тарифікація послуг',
		ru: 'Тарификация услуг',
	},
	ground_tariffs_h3: {
		ua: 'Що входить в пакет',
		ru: 'Что входит в пакет',
	},
	ground_tariffs_desc1: {
		ua: 'Обміри поля RTK дроном і створення контурів',
		ru: 'Обмеры поля RTK дроном и создание контуров',
	},
	ground_tariffs_desc2: {
		ua: 'Створення карт ущільнення грунту',
		ru: 'Создание карт уплотнения грунта',
	},
	ground_tariffs_desc3: {
		ua: 'Подрібнення рослинних решток, Shulte',
		ru: 'Измельчение растительных остатков, Shulte',
	},
	ground_tariffs_desc4: {
		ua: 'Аналіз грунту (середній зразок поля)',
		ru: 'Анализ почвы (средний образец поля)',
	},
	ground_tariffs_desc5: {
		ua: 'Аналіз грунту (сітка 20га)',
		ru: 'Анализ грунта (сетка 20га)',
	},
	ground_tariffs_desc6: {
		ua: 'Аналіз грунту (сітка 10га)',
		ru: 'Анализ грунта (сетка 10га)',
	},
	ground_tariffs_desc7: {
		ua: 'Створення карт диф. внесення мін. добрив',
		ru: 'Создание карт диф. внесение мин. удобрений',
	},
	ground_tariffs_desc8: {
		ua: 'Внесення добрив розкидачем (диф. Внесення)',
		ru: 'Внесение удобрений разбрасывателем (диф. внесение)',
	},
	ground_tariffs_desc9: {
		ua: 'Оранка',
		ru: 'Вспашка',
	},
	ground_tariffs_desc10: {
		ua: 'Комбінований обробіток',
		ru: 'Комбинированная обработка',
	},
	ground_tariffs_desc11: {
		ua: 'Лущення / культивація',
		ru: 'Шелушение / культивация',
	},
	ground_tariffs_desc12: {
		ua: 'Глибоке розпушування (Strom 7 лап)',
		ru: 'Глубокое рыхление (Strom 7 лап)',
	},
	ground_tariffs_desc13: {
		ua: 'Дискування (борона Kello Bilt, до 22 см)',
		ru: 'Дискование (борона Kello Bilt, до 22 см)',
	},
	ground_tariffs_desc14: {
		ua: 'ТМЦ (добрива) - змінюється в залежності від вмісту поживних речовин в грунті',
		ru: 'ТМЦ (удобрения) - изменяется в зависимости от содержания питательных веществ в почве',
	},
	ground_tariffs_desc15: {
		ua: 'Агротехнологічний супровід підготовки грунту',
		ru: 'Агротехнологическое сопровождение подготовки почвы',
	},
	ground_tariffs_desc_after: {
		ua: '<sup>*</sup>будь-яку послугу ви можете замовити окремо від пакетів',
		ru: '<sup>*</sup>любую услугу вы можете заказать отдельно от пакетов',
	},
	ground_tariffs_btn: {
		ua: 'Безкоштовна консультація',
		ru: 'Бесплатная консультация',
	},
	
	// Radius
	ground_radius_h2: {
		ua: 'Встигаємо до наших клієнтів завжди і всюди',
		ru: 'Успеваем к нашим клиентам всегда и везде',
	},
	ground_radius_li1: {
		ua: 'Наша техніка знаходиться на кількох локаціях, щоб ми могли швидко добратися до клієнтів',
		ru: 'Наша техника находится в нескольких локациях, чтобы мы могли быстро добираться к клиентам',
	},
	ground_radius_li2: {
		ua: 'Працюємо в Подільському регіоні, в радіусі 200 км від Вінниці',
		ru: 'Работаем по региону Подолья в радиусе 200 км',
	},
	ground_radius_li3: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5 см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5 см',
	},
	ground_radius_li4: {
		ua: 'Ключові технологічні параметри: перекриття між проходами, швидкість, глибина обробітку',
		ru: 'Ключевые технологические параметры: перекрытие между проходами, скорость, глубина обработки',
	},
	
	// Сonditions
	ground_сonditions_h2: {
		ua: 'Пропонуємо гнучкі умови оплати',
		ru: 'Предлагаем гибкие условия оплаты',
	},
	ground_сonditions_li1: {
		ua: 'Після <b>вивчення вашої анкети чи діагностики</b> буде зрозумілою точна вартість робіт',
		ru: 'После <b>изучения вашей анкеты или диагностики</b> будет понятна точная стоимость работ',
	},
	ground_сonditions_li2: {
		ua: 'Ми пропонуємо на вибір <b>2 варіанта оплати</b> - по факту початку робіт чи відстрочка платежу',
		ru: 'Мы предлагаем на выбор <b>2 варианта оплаты</b> – по факту начала работ или отсрочка платежа',
	},
	ground_сonditions_li3: {
		ua: '<b>Відправляйте заявку</b> через форму нижче, щоб отримати консультацію чи замовити послугу',
		ru: '<b>Отправляйте заявку</b> через форму ниже, чтобы получить консультацию или заказать услугу',
	},
	
	// Banner #4
	ground_banner_title4: {
		ua: 'Відправляйте заявку на безкоштовну консультацію',
		ru: 'Отправляйте заявку на бесплатную консультацию',
	},
	ground_banner_p4: {
		ua: 'якщо у вас є питання про підготовку поля до посіву - телефонуйте нам!',
		ru: 'якщо у вас є питання про підготовку поля до посіву - звоните нам!',
	},
	ground_banner_btn4: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Success
	ground_success_h2: {
		ua: 'Ваш успіх - наша мета',
		ru: 'Ваш успех - наша цель',
	},
	ground_success_p1: {
		ua: 'Контролюємо якість виконання робіт',
		ru: 'Контролируем качество выполнения работ',
	},
	ground_success_p2: {
		ua: 'Працюємо в полі цілодобово',
		ru: 'Работаем в поле круглосуточно',
	},
	ground_success_p3: {
		ua: 'Цілодобова робота служби підтримки',
		ru: 'Круглосуточная работа службы поддержки',
	},
}

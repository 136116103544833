import React, {useState} from 'react';
import {Route, Switch} from 'react-router-dom';

import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Ground from './pages/Ground/Ground';
import Sowing from './pages/Sowing/Sowing';
import NotFound from './pages/NotFound/NotFound';
import Protection from './pages/Protection/Protection';
import Complex from './pages/Complex/Complex';
import Footer from './components/Footer/Footer';
import Politics from './pages/Politics/Politics';
import Cookies from './pages/Cookies/Cookies';
import Information from './pages/Information/Information';
import Modal from './components/Modal/Modal';
import Festival from './pages/Festival/Festival';
import Btn from './components/Btn/Btn';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

import './App.scss';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const App = (props) => {
	let locale = props.locale;
	
	const [modalActive, setModalActive] = useState(false);
	
	if (modalActive) {
		document.querySelector('body').classList.add('not-scroll')
	} else {
		document.querySelector('body').classList.remove('not-scroll')
	}
	
	return (
		<>
			<Header {...props} />
			
			<ScrollToTop>
				<Switch>
					<Route exact path={'/' + locale + '/'}>
						<Home onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/ground'}>
						<Ground onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/sowing'}>
						<Sowing onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/protection'}>
						<Protection onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/complex'}>
						<Complex onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/politics'}>
						<Politics onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/cookies'}>
						<Cookies onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/information'}>
						<Information onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/festival'}>
						<Festival onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path={'/' + locale + '/*'}>
						<NotFound onClick={() => setModalActive(true)} {...props} />
					</Route>
					<Route path="/*/*">
						<NotFound onClick={() => setModalActive(true)} {...props} />
					</Route>
				</Switch>
			</ScrollToTop>
			
			<Footer onClick={() => setModalActive(true)} {...props} />
			
			<Modal active={modalActive} setActive={setModalActive}>
				<form>
					<div className={'close'} onClick={() => setModalActive(false)}>&times;</div>
					<ul>
						<li>
							<label htmlFor="name">Name:</label>
							<input type="text" id="name" name="user_name" required/>
						</li>
						<li>
							<label htmlFor="mail">E-mail:</label>
							<input type="email" id="mail" name="user_mail" required/>
						</li>
						<li>
							<label htmlFor="msg">Message:</label>
							<textarea id="msg" name="user_message" required/>
						</li>
						<li>
							<Btn btnClass={'btn lng-modal_btn_text'} type={'submit'}/>
						</li>
					</ul>
				</form>
			</Modal>
		</>
	)
}

export default App;

import React from 'react';
import Btn from '../../components/Btn/Btn';
import Picture from '../../components/Picture/Picture';
import TitleH1 from '../../components/Title/TitleH1';
import TitleH3 from '../../components/Title/TitleH3';
import './Offer.scss';

const Offer = (props) => {
	return (
		<section id={props.id} className="offer">
			<div className="offer-bg">
				<Picture classes={props.classImgMax} src={props.srcMax} alt={props.classImgMax}/>
				<Picture classes={props.classImgMin} src={props.srcMin} alt={props.classImgMin}/>
			</div>
			<div className="wrap">
				<div className="container">
					<div className="wrap-title">
						<TitleH1 {...props}/>
						<TitleH3 {...props}/>
						<Btn {...props} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Offer;

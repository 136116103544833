import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination, EffectFade} from 'swiper';
import TitleH3 from '../Title/TitleH3';
import TitleH4 from '../Title/TitleH4';
import Picture from '../Picture/Picture';
import SliderBigItem from './SliderBigItem';
import './SliderBig.scss';

SwiperCore.use([Navigation, Pagination, EffectFade]);

const SliderBig = (props) => {
	// const element = props.item
	
	return (
		<div id={props.idsl} className={'slider'}>
			<Swiper
				loop={false}
				autoHeight={true}
				slidesPerView={1}
				effect={'fade'}
				pagination={{
					clickable: true,
					element: '.swiper-pagination'
				}}
				navigation={{
					prevEl: `.prev-${props.idsl}`,
					nextEl: `.next-${props.idsl}`
				}}
			>
				{props.listSl.map((item, element) => {
					return (
						<SwiperSlide key={`slide${element + 1}`}>
							<div className="slide-item">
								<div className="wrapper">
									<div className="left">
										<div className="left-bg"/>
										<Picture src={`${item.pic}`} alt={`slide${element + 1}`}/>
									</div>
									<div className="right">
										<TitleH3 h3Class={`${item.classesH3}`}/>
										<TitleH4 h4Class={`${item.classesH4}`}/>
										<ul>
											<SliderBigItem icon={item.icon1} text={item.text1}/>
											<SliderBigItem icon={item.icon2} text={item.text2}/>
											<SliderBigItem icon={item.icon3} text={item.text3}/>
											<SliderBigItem icon={item.icon4} text={item.text4}/>
										</ul>
										<div className="box-text">
											<p className={`${item.text5}`}/>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			
			{/* Navigation buttons */}
			<div className="nav-slider">
				<div className={`prev swiper-button-prev prev-${props.idsl}`}/>
				<div className={`next swiper-button-next next-${props.idsl}`}/>
			</div>
		</div>
	);
};

export default SliderBig;

export const contextArrHome = {
	// Page: Homepage
	home_offer_h1: {
		ua: 'Аутсорсинг виробничих процесів в агробізнесі',
		ru: 'Аутсорсинг промышленных процессов в агробизнесе',
	},
	home_offer_h3: {
		ua: 'Основний і передпосівний обробіток грунту, посівні роботи, захист рослин, збір і продаж врожаю',
		ru: 'Основная и предпосевная обработка грунта, посевные работы, защита растений, сбор и продажа урожая',
	},
	home_offer_btn: {
		ua: 'Отримати консультацію',
		ru: 'Заказать консультацию',
	},
	
	home_technology_h2: {
		ua: 'Якісно виконуємо щоденні задачі аграрія',
		ru: 'Качественно выполняем ежедневные задания агрария',
	},
	home_technology_p: {
		ua: 'Ідея бізнесу полягає в тому, щоб аграрії передавали частину робіт на аутсорсинг, як це вже давно відбувається в інших сферах життя по всьому світу. <b>Ми беремо на себе всю щоденну роботу</b>, а керівникам звільняємо час для розвитку бізнесу, реалізуємо можливість аграрному підприємству бути більш прибутковим.',
		ru: 'Идея бизнеса заключается в том, чтобы аграрии передавали часть работы на аутсорсинг, как это уже давно происходит в других сферах жизни по всему миру. <b>Мы берём на себя всю повседневную работу</b>, а управленцам освобождаем время для развития бизнеса, реализуем возможность аграрному предприятию быть более прибыльным.',
	},
	home_grows_h2: {
		ua: 'Для тих, хто вирощує',
		ru: 'Для тех, кто выращивает',
	},
	home_grows_p1: {
		ua: 'Працюємо по технології точного землеробства',
		ru: 'Работаем по технологии точного земледелия',
	},
	home_grows_p2: {
		ua: 'Заміряємо поле і створюємо електронну карту',
		ru: 'Замеряем поле и создаем электронную карту',
	},
	home_grows_p3: {
		ua: 'Формуємо карту посіву виходячи з даних NDVI та картографії врожайності',
		ru: 'Формируем карту посева исходя из данных NDVI и картографии урожайности',
	},
	home_grows_p4: {
		ua: 'Формуємо карту внесення добрив, виходячи з даних аналізів NPK',
		ru: 'Формируем карту внесения удобрений, исходя из данных анализов NPK',
	},
	home_grows_p5: {
		ua: 'Працюємо з платним сигналом RTK, для виконання точності робіт',
		ru: 'Работаем с платным сигналом RTK, для выполнения точности работ',
	},
	home_grows_p6: {
		ua: 'Контролюємо результати з висоти польоту (агроскаутинг дронами)',
		ru: 'Контролируем результаты с высоты полета (агроскаутинг дронами)',
	},
	home_grows_p7: {
		ua: 'Маємо повний набір техніки: від борони до безпілотних літальних апаратів, для виконання сучасних рішень',
		ru: 'Есть полный набор техники: от бороны до беспилотных летательных аппаратов, для выполнения современных решений',
	},
	home_grows_p8: {
		ua: 'Робимо агрооперації, з одночасним внесенням добрив',
		ru: 'Проводим агрооперации, с одновременным внесением удобрений',
	},
	
	// We have
	home_wehave_h2: {
		ua: 'Що в нас для цього є',
		ru: 'Что у нас для этого есть',
	},
	home_wehave_span1: {
		ua: '20 рокiв',
		ru: '20 лет',
	},
	home_wehave_p1: {
		ua: 'Працюємо на агроринку більше 20 років',
		ru: 'Работаем на агрорынке более 20 лет',
	},
	home_wehave_span2: {
		ua: '50+ Профеciоналiв',
		ru: '50+ Профессионалов',
	},
	home_wehave_p2: {
		ua: 'Штат команди CAREFIELD нараховує більше 50 працівників. Серед них 22 механізатора і 4 агронома вищої кваліфікації, а також польова команда механізаторів, операторів. Навчаємо штат по мірі виникнення інновацій',
		ru: 'Штат команды CAREFIELD насчитывает более 50 работников. Среди них 22 механизатора и   4 агронома высшей квалификации, а также полевая команда механизаторов, операторов. Обучаем штат по мере появления инноваций',
	},
	home_wehave_span3: {
		ua: '15 країн',
		ru: '15 стран',
	},
	home_wehave_p3: {
		ua: 'Заснуванню компанії CAREFIELD передувало подолання тисяч кілометрів і перейняття досвіду близько 15 країн, в тому числі і лідерів індустрії: Аргентини, Чілі, США, Німеччини, країн Азії',
		ru: 'Основанию компании CAREFIELD предшествовало преодоление тысяч километров и охват опыта около 15 стран, в том числе и лидеров индустрии: Аргентины, США, Чили, Германии, стран Азии',
	},
	home_wehave_span4: {
		ua: '1500 Га',
		ru: '1500 Га',
	},
	home_wehave_p4: {
		ua: 'Власне насіннєве господарство (1500 Га, в тому числі 800 га з дощуванням, 150 га з підземним крапельним зрошенням), де реалізовані інновації і технології в точному землеробстві з використанням аутсорсингу',
		ru: 'Собственное семеноводческое хозяйство (1500Га, в т.ч 800 Га с дождеванием, 150Га подземного капельного орошения), где реализованы инновации и технологии в точном земледелии с применением аутсорсинга',
	},
	home_wehave_span5: {
		ua: 'Більше 30 одиниць техніки',
		ru: 'Больше 30 единиц техники',
	},
	home_wehave_p5: {
		ua: 'Загальна кількість одиниць техніки в нашому парку - бiльш нiж 30 агрегатів',
		ru: 'Общее количество единиц техники в нашем парке - более 30 агрегатов',
	},
	home_wehave_span6: {
		ua: 'Потужність парку',
		ru: 'Мощность парка',
	},
	home_wehave_p6: {
		ua: '40 тис.га - підготовки ґрунту;\n' +
			'20 тис.га внесення рідких добрив;\n' +
			'20 тис.га - послуга посіву;\n' +
			'15 тис.тон - протруювання насіння;\n' +
			'150 тис.га - обприскування за сезон;\n' +
			'50 тис.га - обприскування дронами;\n' +
			'10 тис.га - збирання врожаю',
		ru: '40 тыс.га - подготовки почвы;\n' +
			'20 тыс.га внесения жидких удобрений;\n' +
			'20 тыс.га - услуга посева;\n' +
			'15 тыс.тонн - протравливание семян;\n' +
			'150 тыс.га - опрыскивание за сезон;\n' +
			'50 тыс.га - опрыскивание дронами;\n' +
			'10 тыс.га - сбор урожая',
	},
	
	// Service
	home_services_h2: {
		ua: 'Послуги компанії',
		ru: 'Услуги компании',
	},
	home_service1_h3: {
		ua: 'Підготовка ґрунту',
		ru: 'Подготовка грунта',
	},
	home_service1_p: {
		ua: 'Основний та перед посівний обробіток ґрунту, внесення сухих та рідких мінеральних добрив',
		ru: 'Основная и предпосевная обработка почвы, внесение сухих и жидких минеральных удобрений',
	},
	home_service2_h3: {
		ua: 'Посівні роботи',
		ru: 'Посевные работы',
	},
	home_service2_p: {
		ua: 'Протруювання насіння, посів і внесення стартових добрив',
		ru: 'Протравливание семян, посев и внесение стартовых удобрений',
	},
	home_service3_h3: {
		ua: 'Захист рослин',
		ru: 'Защита растений',
	},
	home_service3_p: {
		ua: 'Захист самохідними обприскувачами і дронами, листове та прикореневе внесення добрив',
		ru: 'Защита самоходными опрыскивателями и дронами, листовое и прикорневое внесение удобрений',
	},
	home_service4_h3: {
		ua: 'Збір і продаж',
		ru: 'Уборка и продажа',
	},
	home_service4_p: {
		ua: 'Десикація, збір врожаю та реалізація на внутрішньому та зовнішньому ринках',
		ru: 'Десикация, сбор урожая и реализация на внутреннем и внешнем рынках',
	},
	home_service1_btn: {
		ua: 'Детальніше',
		ru: 'Подробней',
	},
	home_service2_btn: {
		ua: 'Детальніше',
		ru: 'Подробней',
	},
	home_service3_btn: {
		ua: 'Детальніше',
		ru: 'Подробней',
	},
	home_service4_btn: {
		ua: 'Детальніше',
		ru: 'Подробней',
	},
	
	// Slider Technics
	home_technics_h2: {
		ua: 'Наша техніка',
		ru: 'Наша техника',
	},
	home_technics_p: {
		ua: 'Парк <b>більше 30 одиниць техніки</b>, для виконання всіх виробничих агрооперацій. Серед них такі бренди: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
		ru: 'Парк <b>более 30 единиц техники</b>, для выполнения всех производственных агро операций. Среди них такие бренды: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
	},
	
	// Success
	home_success_h2: {
		ua: 'Найкращі агротехнології - наша мета',
		ru: 'Лучшие агротехнологии - наша цель',
	},
	home_success_p1: {
		ua: 'Знаходимо тiльки якiсну техніку зі всього свiту',
		ru: 'Находим только качественную технику по всему миру',
	},
	home_success_p2: {
		ua: 'Найкращі інноваційні агротехнології та впровадження зі всього світу',
		ru: 'Лучшие инновационные агротехнологии и внедрения по всему миру',
	},
	home_success_p3: {
		ua: 'Ми здійснюємо цiлодобову технічну підтримку техніки на території України',
		ru: 'Мы осуществляем круглосуточную техническую поддержку техники на территории Украины',
	},
	
	// Slider ParkHardTechnics
	home_park_hard_technics_h2: {
		ua: 'Наша важка артилерія',
		ru: 'Наша тяжелая артиллерия',
	},
	home_sl1_h3: {
		ua: 'Jоhn Deere 8370R',
		ru: 'Jоhn Deere 8370R',
	},
	home_sl1_h4: {
		ua: 'Трактор',
		ru: 'Трактор',
	},
	home_sl1_p1: {
		ua: 'Max потужність: <b>270 к.с</b>',
		ru: 'Маx мощность: <b>270 л.с</b>',
	},
	home_sl1_p2: {
		ua: 'Max швидкiсть: <b>50 км/г</b>',
		ru: 'Max скорость: <b>50 км/ч</b>',
	},
	home_sl1_p3: {
		ua: 'КПП: <b>John Deere AutoPower</b>',
		ru: 'КПП: <b>John Deere AutoPower</b>',
	},
	home_sl1_p4: {
		ua: 'Маса: <b>6.3 т</b>',
		ru: 'Масса: <b>6.3 т</b>',
	},
	home_sl1_p5: {
		ua: 'Трактор відноситься до найпотужніших колісних моделей серії 8R , які випускаються компанією. Він призначений до виконання широкого спектру сільськогосподарських робіт на ділянках з різним рельєфом і типом грунту',
		ru: 'Трактор относится к самым мощным колесным моделям серии 8R, выпускаемым компанией. Он предназначен для выполнения широкого спектра сельскохозяйственных работ на участках с разным рельефом и типом почвы',
	},
	home_sl2_h3: {
		ua: 'Jоhn Deere 6195R',
		ru: 'Jоhn Deere 6195R',
	},
	home_sl2_h4: {
		ua: 'Трактор',
		ru: 'Трактор',
	},
	home_sl2_p1: {
		ua: 'Max потужність: <b>210 к.с</b>',
		ru: 'Маx мощность: <b>210 л.с</b>',
	},
	home_sl2_p2: {
		ua: 'Max швидкiсть: <b>50 км/г</b>',
		ru: 'Max скорость: <b>50 км/ч</b>',
	},
	home_sl2_p3: {
		ua: 'КПП: <b>John Deere AutoPower</b>',
		ru: 'КПП: <b>John Deere AutoPower</b>',
	},
	home_sl2_p4: {
		ua: 'Маса: <b>7.5 т</b>',
		ru: 'Масса: <b>7.5 т</b>',
	},
	home_sl2_p5: {
		ua: 'Трактор відноситься до найпотужніших колісних моделей серії 5R , які випускаються компанією. Він призначений до виконання широкого спектру сільськогосподарських робіт на ділянках з різним рельєфом і типом грунту',
		ru: 'Трактор относится к самым мощным колесным моделям серии 5R, выпускаемым компанией. Он предназначен для выполнения широкого спектра сельскохозяйственных работ на участках с разным рельефом и типом почвы',
	},
	home_sl3_h3: {
		ua: 'Jоhn Deere 6216R',
		ru: 'Jоhn Deere 6216R',
	},
	homed_sl3_h4: {
		ua: 'Трактор',
		ru: 'Трактор',
	},
	home_sl3_p1: {
		ua: 'Max потужність: <b>255 к.с</b>',
		ru: 'Маx мощность: <b>255 л.с</b>',
	},
	home_sl3_p2: {
		ua: 'Max швидкiсть: <b>50 км/г</b>',
		ru: 'Max скорость: <b>50 км/ч</b>',
	},
	home_sl3_p3: {
		ua: 'КПП: <b>John Deere AutoPower</b>',
		ru: 'КПП: <b>John Deere AutoPower</b>',
	},
	home_sl3_p4: {
		ua: 'Маса: <b>8.5 т</b>',
		ru: 'Масса: <b>8.5 т</b>',
	},
	home_sl3_p5: {
		ua: 'Простора кабіна ComfortView у тракторі серії 6R забезпечує круговий огляд і має зразкову ергономіку: усі органи керування й інтерфейси логічно розташовані, і оператор може користуватися ними інтуїтивно. Нова консоль CommandArm доповнюється новим дисплеєм CommandCenter 4-го покоління з підтримкою Bluetooth, а додатковий дисплей Gen4 4640 може бути встановлений на правій кутовій стійці',
		ru: 'Просторная кабина ComfortView в тракторе серии 6R обеспечивает круговой обзор и имеет образцовую эргономику: все органы управления и интерфейсы расположены логически, и оператор может пользоваться ими интуитивно. Новая консоль CommandArm дополняется новым дисплеем CommandCenter 4 поколения с поддержкой Bluetooth, а дополнительный дисплей Gen4 4640 может быть установлен на правой угловой стойке',
	},
	home_sl4_h3: {
		ua: 'Claas Lexion 780',
		ru: 'Claas Lexion 780',
	},
	home_sl4_h4: {
		ua: 'Комбайн',
		ru: 'Комбайн',
	},
	home_sl4_p1: {
		ua: 'Ширина захвату: <b>9 м</b>',
		ru: 'Ширина захвата: <b>9 м</b>',
	},
	home_sl4_p2: {
		ua: 'Вивантаження зерна: <b>100 л/с</b>',
		ru: 'Выгрузка зерна: <b>100 л/с</b>',
	},
	home_sl4_p3: {
		ua: 'Об\'єм бункера: <b>10.5 т</b>',
		ru: 'Обьем бункера: <b>10.5 т</b>',
	},
	home_sl4_p4: {
		ua: 'Max потужність: <b>480 к.с</b>',
		ru: 'Маx мощность: <b>480 л.с</b>',
	},
	home_sl4_p5: {
		ua: 'Машина має унікальні технічні та експлуатаційні характеристики. У переліку переваг: поєднання компактних розмірів та зручного доступу до настроювальних та регулювальних робочих точок; рівень повноцінної автоматизації та комп\'ютеризації; можливості колісно-гусеничної версії розширено за рахунок ефективної роботи агрегату на перезволожених та пухких ґрунтах',
		ru: 'Машина обладает уникальными техническими и эксплуатационными характеристиками. В перечне достоинств: сочетание компактных размеров и удобного доступа к настроечным и регулировочным рабочим точкам; уровень полноценной автоматизации и компьютеризации; возможности колесно-гусеничной версии расширены за счет эффективной работы агрегата на переувлажненных и рыхлых грунтах',
	},
	home_sl5_h3: {
		ua: 'Jоhn Deere T670i',
		ru: 'Jоhn Deere T670i',
	},
	home_sl5_h4: {
		ua: 'Комбайн',
		ru: 'Комбайн',
	},
	home_sl5_p1: {
		ua: 'Ширина захвату: <b>8.5 м</b>',
		ru: 'Ширина захвата: <b>8.5 м</b>',
	},
	home_sl5_p2: {
		ua: 'Вивантаження зерна: <b>92 л/с</b>',
		ru: 'Выгрузка зерна: <b>92 л/с</b>',
	},
	home_sl5_p3: {
		ua: 'Об\'єм бункера: <b>10.2 т</b>',
		ru: 'Обьем бункера: <b>10.2 т</b>',
	},
	home_sl5_p4: {
		ua: 'Max потужність: <b>430 к.с</b>',
		ru: 'Маx мощность: <b>430 л.с</b>',
	},
	home_sl5_p5: {
		ua: 'Зробить роботу продуктивною, комфортною та ефективною завдяки інтелектуальним технологіям та інноваційним послугам. Приймальна камера зі збільшеною пропускною здатністю, 4 ланцюгами та запобіжною муфтою 900Нм, багатобарабанна молотильно-сепаруюча система із шириною пропускного тракту 1670 мм, 6-циліндровий двигун John Deere PowerTech',
		ru: 'Машина обладает уникальными техническими и эксплуатационными характеристиками. В перечне достоинств: сочетание компактных размеров и удобного доступа к настроечным и регулировочным рабочим точкам; уровень полноценной автоматизации и компьютеризации; возможности колесно-гусеничной версии расширены за счет эффективной работы агрегата на переувлажненных и рыхлых грунтах',
	},
	home_sl6_h3: {
		ua: 'Jоhn Deere 9RX 640',
		ru: 'Jоhn Deere 9RX 640',
	},
	home_sl6_h4: {
		ua: 'Трактор',
		ru: 'Трактор',
	},
	home_sl6_p1: {
		ua: 'Max потужність: <b>690 к.с</b>',
		ru: 'Маx мощность: <b>690 л.с</b>',
	},
	home_sl6_p2: {
		ua: 'Max швидкiсть: <b>40 км/г</b>',
		ru: 'Max скорость: <b>40 км/ч</b>',
	},
	home_sl6_p3: {
		ua: 'КПП: <b>John Deere AutoPower</b>',
		ru: 'КПП: <b>John Deere AutoPower</b>',
	},
	home_sl6_p4: {
		ua: 'Маса: <b>25.5 т</b>',
		ru: 'Масса: <b>25.5 т</b>',
	},
	home_sl6_p5: {
		ua: 'Машини 9RX мають більш високу потужність, а завдяки новим можливостям баластування вони також мають підвищене тягове зусилля. У цих машин пляма контакту більше на величину до 119%, ніж у колісних машин, і до 42%, ніж у машин з двома гусеницями. Тягове зусилля підтримується повністю навіть під час розворотів під навантаженнями, а пошкодження грунту є мінімальним',
		ru: 'Машины 9RX обладают более высокой мощностью, а благодаря новым возможностям балластировки они также имеют повышенное тяговое усилие. У этих машин пятно контакта больше на величину до 119%, чем у колесных машин, и до 42%, чем у машин с 2 гусеницами. Тяговое усилие поддерживается в полной мере даже во время разворотов под нагрузками, а повреждение почвы является минимальным',
	},
	
	// Text Box
	home_benefits_h2: {
		ua: 'Головні переваги CAREFIELD',
		ru: 'Главные преимущества CAREFIELD',
	},
	home_benefits1_h3: {
		ua: 'Широкий спектр технiки та насіння',
		ru: 'Широкий спектр техники и семян',
	},
	home_benefits_p1: {
		ua: 'Працює з насінням пшениці, ячменю, вівса, гороху, сої, нуту, бобів, соняшника олійного і кондитерського. <b>Повний перелік культур</b> уточнюємо по запиту. Можливість використання кількох апаратів одночасно',
		ru: 'Работает с семенами пшеницы, ячменя, овса, гороха, сои, нута, бобов, подсолнечника масличного и кондитерского. <b>Полный список культур</b> уточняем по запросу. Возможность использования нескольких аппаратов одновременно',
	},
	home_benefits2_h3: {
		ua: 'Зручне транспортування і подача зерна',
		ru: 'Удобная транспортировка и подача зерна',
	},
	home_benefits_p2: {
		ua: 'Ми налаштовуємо комплекс протягом кількох годин. <b>Простий монтаж без витрат</b> на будівництво, утримання та оренду. Для початку роботи достатньо одного оператора',
		ru: 'Мы настроиваем комплекс в течении несколько часов. <b>Простой монтаж без затрат</b> на строительство, содержание и аренды. Для начала работы достаточно одного оператора',
	},
	home_benefits3_h3: {
		ua: 'Якість і ефективність',
		ru: 'Качество и эффективность',
	},
	home_benefits_p3: {
		ua: 'Використуємо одночасно кілька діючих методів. <b>Робимо якісну підготовку</b> посівного матеріалу та високу точність дозування. Ви отримуєте максимум результату за мінімальних витрат',
		ru: 'Используем одновременно несколько действующих методов. <b>Проводим качественную подготовку</b> посевного материала и высокую точность дозировки. Вы получаете максимум результата при минимальных затратах',
	},
	home_benefits4_h3: {
		ua: 'Рівномірність і акуратність',
		ru: 'Равномерность и аккуратность',
	},
	home_benefits_p4: {
		ua: 'Порційна подача посівного матеріалу з робочим розчином і бережне перемішування <b>сприяють рівномірному нанесенню</b>, не травмуючи зерно при обробці',
		ru: 'Порционная подача посевного материала с рабочим раствором и бережное перемешивание <b>способствуют равномерному нанесению</b>, не травмируя зерно при обработке',
	},
	
	// Best Brands
	home_best_brands_h2: {
		ua: 'Ми продаємо найкращу техніку світових лідерів агробізнесу',
		ru: 'Мы продаем лучшую технику мировых лидеров агробизнеса',
	},
	home_best_brands1_h3: {
		ua: 'John Deere<sup>®</sup>',
		ru: 'John Deere<sup>®</sup>',
	},
	home_best_brands_span1: {
		ua: 'Спирається на більш ніж <b>180-річний досвід роботи</b> та терабайти точних даних, щоб дізнатися їх та їх бізнес краще, ніж будь-хто інший. <b>Інноваційні технології</b> допомагають досягти високих результатів на полі та у фінансовій звітності',
		ru: 'Опирается на более чем <b>180-летний опыт работы</b> и терабайты точных данных, чтобы узнать их и их бизнес лучше, чем кто-либо другой. <b>Инновационнаые технологии</b> помогают добиватся высоких результатов на поле и в финансовой отчетности',
	},
	home_best_brands2_h3: {
		ua: 'CLAAS<sup>®</sup>',
		ru: 'CLAAS<sup>®</sup>',
	},
	home_best_brands_span2: {
		ua: 'CLAAS-це сімейне підприємство, <b>засноване в 1913</b> році і є одним із провідних світових виробників сільськогосподарської техніки. Компанія зі штаб-квартирою в Харзевінкелі, Вестфалія, є <b>лідером європейського ринку</b> зернозбиральних комбайнів',
		ru: 'CLAAS-это семейное предприятие, <b>основанное в 1913</b> году и являющееся одним из ведущих мировых производителей сельскохозяйственной техники. Компания со штаб-квартирой в Харзевинкеле, Вестфалия, является <b>лидером европейского рынка</b> зерноуборочных комбайнов',
	},
	home_best_brands3_h3: {
		ua: 'Dorez<sup>®</sup>',
		ru: 'Dorez<sup>®</sup>',
	},
	home_best_brands_span3: {
		ua: 'Компанія Dorez була створена ще <b>наприкінці XVIII століття</b> і свою історію розпочала з виробництва плугів, а з 1985 року почала спеціалізуватися на виготовленні <b>обладнання для протруювання насіння</b>. Сьогодні продукція компанії використовується більш ніж у 20 країнах світу',
		ru: 'Компания Dorez была создана еще <b>в конце XVIII века</b> и начала свою историю с производства плугов, а с 1985 года начала специализироваться на изготовлении <b>оборудования для протравливания семян</b>. Сегодня продукция компании используется в более чем 20 странах мира',
	},
	
	// Banner #1
	home_banner_title: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	home_banner_p: {
		ua: 'Об\'єднує в собі всі вищеперераховані комплекси',
		ru: 'Объединяет в себя все вышеперечисленные комплексы',
	},
	home_banner_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// For Who
	home_for_who_h2: {
		ua: 'Для кого ми працюємо',
		ru: 'Для кого мы работаем',
	},
	home_for_who_li1: {
		ua: '<b>В тебе є земля і ти хочеш вирощувати, але не знаєш як, чи не маєш ресурси?</b> Ми виростимо за свої кошти, а ти гарантовано отримаєш гроші',
		ru: '<b>У тебя есть земля и ты хочешь выращивать, но не знаешь как или не имеешь ресурсы?</b> Мы вырастим за свои средства, а ты гарантированно получишь деньги',
	},
	home_for_who_li2: {
		ua: '<b>В тебе господарство і ти вирощуєш сам?</b> Ми заберемо рутину на себе і звільним твій час для інших справ',
		ru: '<b>У тебя хозяйство и ты выращиваешь сам?</b> Мы возьмем рутину на себя и освободим твоё время для других дел',
	},
	home_for_who_li3: {
		ua: '<b>В тебе великий земельний банк і не встигаєш контролювати?</b> Візьмемо на себе виконання робіт з гарантією результату',
		ru: '<b>У тебя большой земельный банк и ты не успеваешь его контролировать?</b> Возьмем на себя выполнение работ с гарантией результата',
	},
	home_for_who_li4: {
		ua: '<b>В тебе декілька бізнесів?</b> Ми забезпечимо прогнозований результат в аграрному напрямку',
		ru: '<b>У тебя несколько бизнесов?</b> Мы обеспечим прогнозируемый результат в аграрном направлении',
	},
	home_for_who_li5: {
		ua: '<b>Ти вирощуєш, та виробляєш?</b> Ми виконаємо технології точно в строк, звільнимо твій час для стратегічного розвитку',
		ru: '<b>Ты выращиваешь и производишь?</b> Мы выполним технологии точно в срок, освободим твоё время для стратегического развития',
	},
	
	// Radius
	home_radius_h2: {
		ua: 'Встигаємо до наших клієнтів завжди і всюди',
		ru: 'Успеваем к нашим клиентам всегда и везде',
	},
	home_radius_li1: {
		ua: 'Працюємо в Подільському регіоні, <b>в радіусі 200 км від Вінниці</b>',
		ru: 'Работаем по региону Подолья, <b>в радиусе 200 км</b>',
	},
	home_radius_li2: {
		ua: 'Наша техніка знаходиться <b>на кількох локаціях</b>, щоб ми могли швидко добратися до клієнтів',
		ru: 'Наша техника находится <b>в нескольких локациях</b>, чтобы мы могли быстро добираться к клиентам',
	},
	home_radius_li3: {
		ua: '<b>Ключові технологічні параметри:</b> перекриття між проходами, швидкість, глибина обробітку',
		ru: '<b>Ключевые технологические параметры:</b> перекрытие между проходами, скорость, глубина обработки',
	},
	home_radius_li4: {
		ua: 'Проводимо обмір полів <b>з використанням RTK-сигналу</b> з точністю вимірювання +- 2,5 см',
		ru: 'Проводим обмер полей <b>с использованием RTK-сигнала</b> с точностью измерения +- 2,5 см',
	},
	
	home_agropark_h2: {
		ua: 'Агропарк “ZGAR”',
		ru: 'Агропарк “ZGAR”',
	},
	home_agropark_p: {
		ua: 'В агропарку ми поєднуємо нетворкінг і розвиток. Тут <b>проходять тематичні івенти для підвищення навиків</b>, консультації, навчання, нетворкінг, нові зв\'язки і партнерство, покази інноваційних технологій і техніки.',
		ru: 'В агропарке мы совмещаем нетворкинг и развитие. Здесь <b>происходят тематические ивенты для повышения навыков</b>, консультации, обучение, нетворкинг, новые связи и партнёрство, показы инновационных технологий и техники.',
	},
	home_agropark_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Success Profit
	home_success_profit_h2: {
		ua: 'Ми не заробляємо для вас гроші -<br />ми робимо ваш бізнес прибутковим',
		ru: 'Мы не зарабатываем для вас деньги –<br />мы делаем ваш бизнес прибыльным',
	},
	home_success_profit_p1: {
		ua: 'Знаходимо тiльки якiсну техніку зі всього свiту',
		ru: 'Находим только качественную технику по всему миру',
	},
	home_success_profit_p2: {
		ua: 'Найкращі інноваційні агротехнології та впровадження зі всього світу',
		ru: 'Лучшие инновационные агротехнологии и внедрения по всему миру',
	},
	home_success_profit_p3: {
		ua: 'Ми здійснюємо цiлодобову технічну підтримку техніки',
		ru: 'Мы осуществляем круглосуточную техническую поддержку техники',
	},
}

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Picture from '../../components/Picture/Picture';
import image from '../../images/sec2_bg.jpg';
import quoteUp from '../../images/icons/quotes-up.png';
import quoteDown from '../../images/icons/quotes-down.png';
import './Technology.scss'

const Technology = (props) => {
	return (
		<section id={'technology'} className={'technology'}>
			<div className="container">
				<div className="wrap">
					<TitleH2 h2Class={'lng-home_technology_h2'}/>
					<div className="wrapper df fww">
						<div className="left">
							<Picture classes={'shadow'} src={image} alt={'technology-image'}/>
						</div>
						<div className="right">
							<Picture src={quoteUp} alt={'quoteUp'}/>
							<p className={'lng-home_technology_p'}/>
							<Picture src={quoteDown} alt={'quoteUp'}/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Technology;

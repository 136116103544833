import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import SlideXItem from './SlideXItem';

SwiperCore.use([Navigation, Pagination]);

const SliderX = (props) => {
	return (
		<div id={props.idsl} className={`${props.idsl} slider`}>
			<Swiper
				loop={false}
				autoHeight={true}
				slidesPerView={4}
				pagination={{
					clickable: true,
					element: '.swiper-pagination'
				}}
				navigation={{
					prevEl: `.prev-${props.idsl}`,
					nextEl: `.next-${props.idsl}`
				}}
				breakpoints={{
					1200: {
						slidesPerView: 4,
					},
					992: {
						slidesPerView: 2,
					},
					576: {
						slidesPerView: 1,
					},
					340: {
						slidesPerView: 1,
					}
				}}
			>
				{props.listSl.map((item, element) => {
					return (
						<SwiperSlide key={`slide${element + 1}`}>
							<SlideXItem {...item} {...props}/>
						</SwiperSlide>
					);
				})}
			</Swiper>
			
			{/* Navigation buttons */}
			<div className="nav-slider">
				<div className={`prev swiper-button-prev prev-${props.idsl}`}/>
				<div className={`next swiper-button-next next-${props.idsl}`}/>
			</div>
		</div>
	);
};

export default SliderX;

import protectionItemServiceImg1 from '../images/icons/services/item01.jpg';
import protectionItemServiceImg2 from '../images/icons/services/item02.jpg';
import protectionItemServiceImg3 from '../images/icons/services/item03.jpg';
import protectionItemServiceImg4 from '../images/icons/services/item04.jpg';
import protectionItemServiceImg5 from '../images/icons/services/item05.jpg';
import protectionItemServiceImg6 from '../images/icons/services/item-2.jpg';

import protectionItemTechnologyImg1 from '../images/icons/sec3_1.jpg';
import protectionItemTechnologyImg2 from '../images/icons/i-price1.png';
import protectionItemTechnologyImg3 from '../images/icons/services/item03.jpg';

import protectionItemLogisticsImg1 from '../images/icons/services/item04.jpg';
import protectionItemLogisticsImg2 from '../images/icons/services/item-2.jpg';
import protectionItemLogisticsImg3 from '../images/icons/tablet.png';

import protectionItemProvideReportsImg1 from '../images/icons/sec3_2.jpg';
import protectionItemProvideReportsImg2 from '../images/icons/dron.png';
import protectionItemProvideReportsImg3 from '../images/icons/icon-1.png';

import protectionItemSuccessImg1 from '../images/icons/services/item04.jpg';
import protectionItemSuccessImg2 from '../images/icons/i-price1.png';
import protectionItemSuccessImg3 from '../images/icons/services/item-2.jpg';

import slpr1 from '../images/brands/slide1.jpg';
import slpr2 from '../images/brands/slide2.jpg';
import slpr3 from '../images/brands/slide3.jpg';
import slpr4 from '../images/brands/slide4.jpg';
import slpr5 from '../images/brands/slide5.jpg';
import slpr6 from '../images/brands/slide6.jpg';
import slpr7 from '../images/brands/slide7.jpg';
import slpr8 from '../images/brands/slide8.jpg';
import slpr9 from '../images/brands/slide9.jpg';
import slpr10 from '../images/brands/slide10.jpg';
import slpr11 from '../images/brands/slide11.jpg';
import slpr12 from '../images/brands/slide12.jpg';

import slbr1 from '../images/protection/slider3/slide1.jpg';
import slbr2 from '../images/protection/slider3/slide2.jpg';
import slbr3 from '../images/protection/slider3/slide3.jpg';
import slbr4 from '../images/protection/slider3/slide4.jpg';
import slbr5 from '../images/protection/slider3/slide5.jpg';
import slbr6 from '../images/protection/slider3/slide6.jpg';
import slbr7 from '../images/protection/slider3/slide7.jpg';
import slbr8 from '../images/protection/slider3/slide8.jpg';
import slbr9 from '../images/protection/slider3/slide9.jpg';

export const listProtectionItemsServices = [
	{id: 1, classes: 'lng-protection_services_p1', pic: protectionItemServiceImg1},
	{id: 2, classes: 'lng-protection_services_p2', pic: protectionItemServiceImg2},
	{id: 3, classes: 'lng-protection_services_p3', pic: protectionItemServiceImg3},
	{id: 4, classes: 'lng-protection_services_p4', pic: protectionItemServiceImg4},
	{id: 5, classes: 'lng-protection_services_p5', pic: protectionItemServiceImg5},
	{id: 6, classes: 'lng-protection_services_p6', pic: protectionItemServiceImg6}
]

export const listProtectionPrinciples = [
	{id: 1, classesH3: 'lng-protection_principles1_h3', classesP: 'dn', classesSpan: 'lng-protection_principles_span1'},
	{id: 2, classesH3: 'lng-protection_principles2_h3', classesP: 'dn', classesSpan: 'lng-protection_principles_span2'},
	{id: 3, classesH3: 'lng-protection_principles3_h3', classesP: 'dn', classesSpan: 'lng-protection_principles_span3'}
]

export const listProtectionPlant = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_plant_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_plant_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_plant_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_plant_li4'},
	{id: 5, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_plant_li5'}
]

export const listProtectionItemsTechnology = [
	{id: 1, classes: 'lng-protection_technology_p1', pic: protectionItemTechnologyImg1},
	{id: 2, classes: 'lng-protection_technology_p2', pic: protectionItemTechnologyImg2},
	{id: 3, classes: 'lng-protection_technology_p3', pic: protectionItemTechnologyImg3}
]

export const listProtectionPreventionTreatment = [
	{
		id: 1,
		classesH3: 'lng-protection_prevention_treatment1_h3',
		classesSpan: 'lng-protection_prevention_treatment_span1',
		classesP: 'dn'
	},
	{
		id: 2,
		classesH3: 'lng-protection_prevention_treatment2_h3',
		classesSpan: 'lng-protection_prevention_treatment_span2',
		classesP: 'dn'
	},
	{
		id: 3,
		classesH3: 'lng-protection_prevention_treatment3_h3',
		classesSpan: 'lng-protection_prevention_treatment_span3',
		classesP: 'dn'
	},
]

export const listProtectionItemsLogistics = [
	{id: 1, classes: 'lng-protection_logistics_p1', pic: protectionItemLogisticsImg1},
	{id: 2, classes: 'lng-protection_logistics_p2', pic: protectionItemLogisticsImg2},
	{id: 3, classes: 'lng-protection_logistics_p3', pic: protectionItemLogisticsImg3}
]

export const listProtectionLogisticsWater = [
	{
		id: 1,
		classesH3: 'lng-protection_logistics_water1_h3',
		classesSpan: 'lng-protection_logistics_water_span1',
		classesP: 'dn'
	},
	{
		id: 2,
		classesH3: 'lng-protection_logistics_water2_h3',
		classesSpan: 'lng-protection_logistics_water_span2',
		classesP: 'dn'
	},
]

export const listProtectionItemsProvideReports = [
	{id: 1, classes: 'lng-protection_provide_reports_p1', pic: protectionItemProvideReportsImg1},
	{id: 2, classes: 'lng-protection_provide_reports_p2', pic: protectionItemProvideReportsImg2},
	{id: 3, classes: 'lng-protection_provide_reports_p3', pic: protectionItemProvideReportsImg3}
]

export const listTechnicsProtection = [slpr1, slpr2, slpr3, slpr4, slpr5, slpr6, slpr7, slpr8, slpr9, slpr10, slpr11, slpr12]

export const listProtectionResult = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li4'},
	{id: 5, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li5'},
	{id: 6, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-protection_result_li6'}
]

export const listProtectionItemsResult = [
	{id: 1, classes: 'lng-protection_result_desc_p1', h3Class: 'lng-protection_result1_desc_h3'},
	{id: 2, classes: 'lng-protection_result_desc_p2', h3Class: 'lng-protection_result2_desc_h3'},
	{id: 3, classes: 'lng-protection_result_desc_p3', h3Class: 'lng-protection_result3_desc_h3'},
	{id: 4, classes: 'lng-protection_result_desc_p4', h3Class: 'lng-protection_result4_desc_h3'},
	{id: 5, classes: 'lng-protection_result_desc_p5', h3Class: 'lng-protection_result5_desc_h3'},
	{id: 6, classes: 'lng-protection_result_desc_p6', h3Class: 'lng-protection_result6_desc_h3'},
]

export const listTechnicsProtectionBrands = [slbr1, slbr2, slbr3, slbr4, slbr5, slbr6, slbr7, slbr8, slbr9]

export const listProtectionItemsSuccess = [
	{id: 1, classes: 'lng-protection_success_p1', pic: protectionItemSuccessImg1},
	{id: 2, classes: 'lng-protection_success_p2', pic: protectionItemSuccessImg2},
	{id: 3, classes: 'lng-protection_success_p3', pic: protectionItemSuccessImg3}
]

import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import LangRouting from './routing/LangRouting';

ReactDOM.render(
	<Router>
		<LangRouting />
	</Router>,
	document.getElementById('root')
)

import sowingItemSuccessImg1 from '../images/icons/sec3_1.jpg';
import sowingItemSuccessImg2 from '../images/icons/sec3_2.jpg';
import sowingItemSuccessImg3 from '../images/icons/sec3_3.jpg';

import jd1 from '../images/sowing/john_deere1.jpg';
import jd2 from '../images/sowing/john_deere2.jpg';
import jd3 from '../images/sowing/john_deere3.jpg';
import cl1 from '../images/sowing/claas_sl1.jpg';
import cl2 from '../images/sowing/claas_sl2.jpg';
import cl3 from '../images/sowing/claas_sl3.jpg';

import times from '../images/icons/times.png';
import check from '../images/icons/check-mark.png';

import sowingItemExtraImg1 from '../images/icons/services/item-1.jpg';
import sowingItemExtraImg2 from '../images/icons/services/item-2.jpg';
import sowingItemExtraImg3 from '../images/icons/services/item-3.jpg';

export const listPrinciplesSowing = [
	{id: 1, classesH3: 'lng-sowing_principles1_h3', classesP: 'dn', classesSpan: 'lng-sowing_principles_span1'},
	{id: 2, classesH3: 'lng-sowing_principles2_h3', classesP: 'dn', classesSpan: 'lng-sowing_principles_span2'},
	{id: 3, classesH3: 'lng-sowing_principles3_h3', classesP: 'dn', classesSpan: 'lng-sowing_principles_span3'}
]

export const listSowingItemsSuccess = [
	{id: 1, classes: 'lng-sowing_success_p1', pic: sowingItemSuccessImg1},
	{id: 2, classes: 'lng-sowing_success_p2', pic: sowingItemSuccessImg2},
	{id: 3, classes: 'lng-sowing_success_p3', pic: sowingItemSuccessImg3}
]

export const listGrowsSowing = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p4'},
	{id: 5, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p5'},
	{id: 6, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p6'},
	{id: 7, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p7'},
	{id: 8, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_grows_p8'}
]

export const listTextColBoxSowing = [
	{id: 1, classesH3: 'lng-sowing_benefits_john_deere1_h3', classesP: 'lng-sowing_benefits_john_deere_p1'},
	{id: 2, classesH3: 'lng-sowing_benefits_john_deere2_h3', classesP: 'lng-sowing_benefits_john_deere_p2'},
	{id: 3, classesH3: 'lng-sowing_benefits_john_deere3_h3', classesP: 'lng-sowing_benefits_john_deere_p3'},
	{id: 4, classesH3: 'lng-sowing_benefits_john_deere4_h3', classesP: 'lng-sowing_benefits_john_deere_p4'}
]

export const listJohnDeere = [jd1, jd2, jd3]

export const listBenefitsClaas = [
	{id: 1, classes: 'lng-sowing_benefits_claas_li1'},
	{id: 2, classes: 'lng-sowing_benefits_claas_li2'},
	{id: 3, classes: 'lng-sowing_benefits_claas_li3'},
	{id: 4, classes: 'lng-sowing_benefits_claas_li4'}
]

export const listClaas = [cl1, cl2, cl3]

export const listSowingSliderTariffs = [
	{
		id: 1,
		head: 'S',
		el1: times,
		el2: times,
		el3: times,
		el4: times,
		el5: times,
		el6: times,
		el7: times,
		el8: check,
		el9: times,
		el10: times,
		el11: check,
		price: '37$'
	},
	{
		id: 2,
		head: 'M',
		el1: times,
		el2: check,
		el3: check,
		el4: times,
		el5: times,
		el6: times,
		el7: times,
		el8: check,
		el9: times,
		el10: times,
		el11: check,
		price: '39$'
	},
	{
		id: 3,
		head: 'L',
		el1: check,
		el2: check,
		el3: check,
		el4: times,
		el5: check,
		el6: check,
		el7: times,
		el8: check,
		el9: check,
		el10: times,
		el11: times,
		price: '66$'
	},
	{
		id: 4,
		head: 'XL',
		el1: check,
		el2: check,
		el3: check,
		el4: check,
		el5: check,
		el6: check,
		el7: check,
		el8: check,
		el9: check,
		el10: check,
		el11: times,
		price: '72$'
	}
]

export const listSowingItemsExtra = [
	{id: 1, classes: 'lng-sowing_extra_p1', pic: sowingItemExtraImg1},
	{id: 2, classes: 'lng-sowing_extra_p2', pic: sowingItemExtraImg2},
	{id: 3, classes: 'lng-sowing_extra_p3', pic: sowingItemExtraImg3}
]

export const listSowingRadius = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_radius_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_radius_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_radius_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-sowing_radius_li4'}
]

import React from 'react';
import ListItem from './ListItem';

function ListItems(props) {
	const list = [];
	for (let i = props.minRow; i <= props.maxRow; i++) {
		list[i] = i;
	}
	
	return (
		<ul>
			{list.map(item =>
				<ListItem key={[item]} className={`${props.classListDesc}${[item]}`}/>
			)}
		</ul>
	);
}

export default ListItems;

import React from 'react';
import logo from './logo.png'
import logoTriangles from './logoTriangles.png'
import {Link} from 'react-router-dom';
import './Logo.scss'

const Logo = (props) => {
	return (
		<Link className="logo" to={"/" + props.locale + "/"}>
			<img src={logo} alt={'logo'}/>
			<img src={logoTriangles} alt={'logo'}/>
		</Link>
	);
};

export default Logo;

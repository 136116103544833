import React from 'react';

const CustomLink = (props) => {
	return (
		<li>
			<a href={props.href}>
				<img src={props.img} alt={props.img}/>
				<span className={props.classes}>{props.text}</span>
			</a>
		</li>
	);
};

export default CustomLink;

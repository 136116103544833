import React from 'react';

function TextBox(props) {
	return (
		<section id={props.id}>
			<div className="container">
				<p className={props.classesP}/>
			</div>
		</section>
	);
}

export default TextBox;

import festivalItemNumbersImg1 from '../images/icons/sec3_2.jpg';
import festivalItemNumbersImg2 from '../images/icons/transaction-1.png';
import festivalItemNumbersImg3 from '../images/icons/transaction-2.png';

export const listFestivalPrinciples = [
	{id: 1, classesH3: 'lng-festival_principles1_h3', classesP: 'dn', classesSpan: 'lng-festival_principles_span1'},
	{id: 2, classesH3: 'lng-festival_principles2_h3', classesP: 'dn', classesSpan: 'lng-festival_principles_span2'},
	{id: 3, classesH3: 'lng-festival_principles3_h3', classesP: 'dn', classesSpan: 'lng-festival_principles_span3'}
]

export const listFestivalAbout = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li4'},
	{id: 5, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li5'},
	{id: 6, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-festival_about_li6'}
]

export const listFestivalItemsNumbers = [
	{id: 1, classes: 'lng-festival_numbers_p1', pic: festivalItemNumbersImg1},
	{id: 2, classes: 'lng-festival_numbers_p2', pic: festivalItemNumbersImg2},
	{id: 3, classes: 'lng-festival_numbers_p3', pic: festivalItemNumbersImg3},
]

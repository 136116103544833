import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination, EffectFade} from 'swiper';
import './Slider.scss';

SwiperCore.use([Navigation, Pagination, EffectFade]);

const Slider = (props) => {
	// const element = props.el
	
	return (
		<div id={props.idsl} className={'slider'}>
			<Swiper
				loop={false}
				autoHeight={false}
				slidesPerView={1}
				effect={'fade'}
				pagination={{
					clickable: true,
					element: '.swiper-pagination'
				}}
				navigation={{
					prevEl: `.prev-${props.idsl}`,
					nextEl: `.next-${props.idsl}`
				}}
			>
				{props.list.map((i, element) => {
					return (
						<SwiperSlide key={`slide${element + 1}`}>
							<div className="img-box">
								<img className={props.shadow} src={`${i}`} alt={`slide${element + 1}`}/>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			
			{/* Navigation buttons */}
			<div className="nav-slider">
				<div className={`prev swiper-button-prev prev-${props.idsl}`}/>
				<div className={`next swiper-button-next next-${props.idsl}`}/>
			</div>
		</div>
	);
};

export default Slider;

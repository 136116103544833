import React from 'react';
import './BurgerIcon.scss'

function BurgerIcon(props) {
	return (
		<button id="burger" onClick={props.click} className={props.classes} style={{display: 'none'}}>
			<div className="burger-i">
				<span />
				<span />
			</div>
		</button>
	)
}

export default BurgerIcon

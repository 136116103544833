import React from 'react';
import TitleH3 from '../Title/TitleH3';
import Picture from '../Picture/Picture';

function SlideXItem(props) {
	const listItems = [];
	for (let i = props.minRow; i <= props.maxRow; i++) {
		listItems[i] = i;
	}
	
	return (
		<div className="slide-item">
			<TitleH3 text={props.head}/>
			<ul>
				{listItems.map(str =>
					<li key={[str]}><Picture src={props[`el${str}`]}/></li>
				)}
			</ul>
			<span>{props.price}</span>
		</div>
	);
}

export default SlideXItem;

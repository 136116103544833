import React from 'react';
import './Card.scss'

const Card = (props) => {
	return (
		<li id='card' className={props.classes}>
			<div className="desc" style={{borderLeftColor: props.color}}>
				<span className={props.classSpan} />
				<p className={props.classP} />
			</div>
		</li>
	);
};

export default Card;

import React from 'react';
import Picture from '../../components/Picture/Picture';
import TitleH2 from '../../components/Title/TitleH2';
import './ListImages.scss';

const ListImages = (props) => {
	return (
		<section id={props.id} className={'list-items'}>
			<div className="container wrap">
				<div className="wrapper">
					<div className="left">
						<TitleH2 h2Class={props.classesH2}/>
						<ul>
							{props.list.map(item =>
								<li key={item.id} className={item.classes}/>
							)}
						</ul>
					</div>
					<div className="right">
						<Picture classes={'shadow'} src={props.image1}/>
						<Picture classes={'shadow'} src={props.image2}/>
						<Picture classes={'shadow'} src={props.image3}/>
					</div>
				</div>
				{props.children}
			</div>
		</section>
	);
};

export default ListImages;

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import TitleH3 from '../../components/Title/TitleH3';
import ListItems from '../../components/ListItems/ListItems';
import Btn from '../../components/Btn/Btn';
import SliderX from '../../components/SliderX/SliderX';

import './Tariffs.scss';

function Tariffs(props) {
	return (
		<section id={props.id} className={'tariffs'}>
			<div className="container">
				<TitleH2 h2Class={props.h2Class}/>
				<div className="wrapper">
					<div className="left">
						<TitleH3 h3Class={props.h3Class}/>
						<ListItems {...props}/>
					</div>
					<div className="right">
						<SliderX {...props} />
					</div>
				</div>
				<div className="wrapper-down">
					<p className={props.classDescAfter}/>
					<Btn {...props} />
				</div>
			</div>
		</section>
	);
}

export default Tariffs;

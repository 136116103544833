export const contextArrProtection = {
	// Page: Protection
	protection_offer_h1: {
		ua: 'Комплекснi інноваційнi рішення із захисту рослин',
		ru: 'Комплексные инновационные решения по защите растений',
	},
	protection_offer_h3: {
		ua: 'Використовуємо інноваційні технології змішування та дозування добрив',
		ru: 'Используем инновационные технологии смешивания и дозировки удобрений',
	},
	protection_offer_btn: {
		ua: 'Отримати консультацію',
		ru: 'Заказать консультацию',
	},
	
	// Services
	protection_services_h2: {
		ua: 'При виконанні послуги внесення добрив використовуємо принципи точного землеробства:',
		ru: 'При выполнении услуги внесения удобрений используем принципы точного земледелия:',
	},
	protection_services_p1: {
		ua: 'Враховуємо особливості поля та культури',
		ru: 'Учитываем особенности поля и культуры',
	},
	protection_services_p2: {
		ua: 'Враховуємо хімічний стан ґрунту',
		ru: 'Учитываем химическое состояние почвы',
	},
	protection_services_p3: {
		ua: 'Рахуємо правильне співвідношення ТМЦ',
		ru: 'Считаем правильное соотношение ТМЦ',
	},
	protection_services_p4: {
		ua: 'Виконуємо диференційоване внесення добрив',
		ru: 'Выполняем дифференцированное внесение удобрений',
	},
	protection_services_p5: {
		ua: 'Скорочуємо витрати та покращуємо врожайність',
		ru: 'Сокращаем расходы и улучшаем урожайность',
	},
	protection_services_p6: {
		ua: 'Ми ефективно використовуємо ваш час',
		ru: 'Мы эффективно используем ваше время',
	},
	
	// Principles
	protection_principles_h2: {
		ua: 'Наші принципи при виконанні послуги обробітку',
		ru: 'Наши принципы при выполнении услуги обработки',
	},
	protection_principles1_h3: {
		ua: 'Робимо точні заміри поля',
		ru: 'Делаем точные замеры поля',
	},
	protection_principles_span1: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5см',
	},
	protection_principles2_h3: {
		ua: 'Застосовуємо паралельне водіння',
		ru: 'Применяем параллельное вождение',
	},
	protection_principles_span2: {
		ua: 'Завдяки точному сигналу отримуємо кращу якість роботи агрегата і менші витрати ПММ',
		ru: 'Благодаря точному сигналу получаем лучшее качество работы агрегата и меньшие затраты ГСМ',
	},
	protection_principles3_h3: {
		ua: 'Дотримуємось найважливіших вимог',
		ru: 'Соблюдаем самые важные требования',
	},
	protection_principles_span3: {
		ua: 'Наші принципи при виконанні послуги обробітку',
		ru: 'Наши принципы при выполнении услуги обработки',
	},
	
	// Banner #1
	protection_banner_title: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	protection_banner_p: {
		ua: 'Комплексні інноваційні рішення із захисту рослин',
		ru: 'Комплексное инновационное решение по защите растений',
	},
	protection_banner_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Protection Plant
	protection_plant_h2: {
		ua: 'Як працює послуга захисту рослин',
		ru: 'Как работает услуга защиты растений',
	},
	protection_plant_li1: {
		ua: 'Розробляємо <b>технологію захисту</b>',
		ru: 'Разрабатываем <b>технологию защиты</b>',
	},
	protection_plant_li2: {
		ua: 'Проводимо <b>профілактику та лікування</b>',
		ru: 'Проводим <b>профилактику и лечение</b>',
	},
	protection_plant_li3: {
		ua: 'Організовуємо <b>логістику ТМЦ</b>',
		ru: 'Организуем <b>логистику ТМЦ</b>',
	},
	protection_plant_li4: {
		ua: 'Організовуємо <b>логістику води</b>',
		ru: 'Организуем <b>логистику воды</b>',
	},
	protection_plant_li5: {
		ua: 'Організовуємо <b>внесення ЗЗР та своєчасно надаємо звіти</b> для контролю',
		ru: 'Организуем <b>внесение СЗР и своевременно предоставляем отчеты</b> по контролю',
	},
	
	// Technology
	protection_technology_h2: {
		ua: 'Розробляємо технологію захисту',
		ru: 'Разрабатываем технологию защиты',
	},
	protection_technology_p1: {
		ua: 'Пропонуємо варіанти роботи на Вашому полі',
		ru: 'Предлагаем варианты работы на Вашем поле',
	},
	protection_technology_p2: {
		ua: 'Даємо перелік необхідних ТМЦ і цінові пропозиції',
		ru: 'Даем перечень необходимых ТМЦ и ценовые предложения',
	},
	protection_technology_p3: {
		ua: 'Готуємо платіжний календар з урахуванням побажань замовника',
		ru: 'Готовим платежный календарь с учетом пожеланий заказчика',
	},
	
	// Prevention Treatment
	protection_prevention_treatment_h2: {
		ua: 'Проводимо профілактику та лікування',
		ru: 'Проводим профилактику и лечение',
	},
	protection_prevention_treatment1_h3: {
		ua: 'Варіанти роботи на полі',
		ru: 'Варианты работы на поле',
	},
	protection_prevention_treatment2_h3: {
		ua: 'Перелік ТМЦ і ціни',
		ru: 'Перечень ТМЦ и цены',
	},
	protection_prevention_treatment3_h3: {
		ua: 'Платіжний календар',
		ru: 'Платежный календарь',
	},
	protection_prevention_treatment_span1: {
		ua: 'Готуємо платіжний календар з урахуванням побажань замовника',
		ru: 'Готовим платежный календарь с учетом пожеланий заказчика',
	},
	protection_prevention_treatment_span2: {
		ua: 'Готуємо платіжний календар з урахуванням побажань замовника',
		ru: 'Готовим платежный календарь с учетом пожеланий заказчика',
	},
	protection_prevention_treatment_span3: {
		ua: 'Готуємо платіжний календар з урахуванням побажань замовника',
		ru: 'Готовим платежный календарь с учетом пожеланий заказчика',
	},
	
	// Logistics
	protection_logistics_h2: {
		ua: 'Організовуємо логістику ТМЦ',
		ru: 'Организуем логистику ТМЦ',
	},
	protection_logistics_p1: {
		ua: 'Ви отримуєте рівно стільки ТМЦ, скільки необхідно Вашому полю',
		ru: 'Вы получаете ровно столько ТМЦ, сколько необходимо Вашему полю',
	},
	protection_logistics_p2: {
		ua: 'Вивільняємо час менеджерів від рутинних завдань',
		ru: 'Высвобождаем время менеджеров от рутинных задач',
	},
	protection_logistics_p3: {
		ua: 'Ми організовуємо утилізацію тари для агрохімії',
		ru: 'Мы организовываем утилизацию тары для агрохимии',
	},
	
	// Prevention Treatment
	protection_logistics_water_h2: {
		ua: 'Організовуємо логістику води',
		ru: 'Организуем логистику воды',
	},
	protection_logistics_water1_h3: {
		ua: 'Вивільняємо техніку',
		ru: 'Высвобождаем технику',
	},
	protection_logistics_water2_h3: {
		ua: 'Висока продуктивність',
		ru: 'Высокая производительность',
	},
	protection_logistics_water_span1: {
		ua: 'Вивільняємо техніку замовника',
		ru: 'Высвобождаем технику заказчика',
	},
	protection_logistics_water_span2: {
		ua: 'Забезпечуємо високу продуктивність за рахунок наявності змішувального вузла і налаштованої системи підвозу води',
		ru: 'Обеспечиваем высокую производительность за счет наличия смесительного узла и настроенной системы подвоза воды',
	},
	
	// Provide Report
	protection_provide_reports_h2: {
		ua: 'Організовуємо внесення ЗЗР та своєчасно надаємо звіти для контролю',
		ru: 'Организуем внесение СЗР и своевременно предоставляем отчеты по контролю',
	},
	protection_provide_reports_p1: {
		ua: 'Вносимо своєчасно, оскільки працюємо в радіусі 200 км від Вінниці і маємо великий парк техніки',
		ru: 'Вносим своевременно, поскольку работаем в радиусе 200 км от Винницы и есть большой парк техники',
	},
	protection_provide_reports_p2: {
		ua: 'Налаштовуємо систему автоматизованого контролю для внесення згідно норм',
		ru: 'Настраиваем систему автоматизированного контроля для внесения согласно нормам',
	},
	protection_provide_reports_p3: {
		ua: 'Забезпечуємо постійний контроль якості внесення біологічних добрив та агрохiмії',
		ru: 'Обеспечиваем постоянный контроль качества внесения биоудобрений и агрохимии',
	},
	
	// Banner #2
	protection_banner2_title: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	protection_banner2_p: {
		ua: 'Вносимо ЗЗР дронами та самохідними обприскувачами',
		ru: 'Вносим СЗР дронами и самоходными опрыскивателями',
	},
	protection_banner2_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Slider Technics Park Drons
	protection_drons_h2: {
		ua: 'Вносим ЗЗР дронами',
		ru: 'Вносим СЗР дронами',
	},
	protection_drons_sl1_h3: {
		ua: 'DJI Agras T20',
		ru: 'DJI Agras T20',
	},
	protection_drons_sl1_h4: {
		ua: 'Дрон',
		ru: 'Дрон',
	},
	protection_drons_sl1_p1: {
		ua: 'Висота: <b>200 м</b>',
		ru: 'Висота: <b>200 м</b>',
	},
	protection_drons_sl1_p2: {
		ua: 'Об\'єм бака: <b>20 л</b>',
		ru: 'Объем бака: <b>20 л</b>',
	},
	protection_drons_sl1_p3: {
		ua: 'Робочий радіус: <b>3.5 км</b>',
		ru: 'Рабочий радиус: <b>3.5 км</b>',
	},
	protection_drons_sl1_p4: {
		ua: 'Вага нетто: <b>6.7 кг</b>',
		ru: 'Масса нетто: <b>6.7 кг</b>',
	},
	protection_drons_sl1_p5: {
		ua: 'Вдосконалиними можливостями розпилення ЗЗР, усебічно спрямованим цифровим радаром для сканування площі в 3Д на 360°, можливість працювати автономно на різних угіддях, можливість уникати перешкод при польотах',
		ru: 'Усовершенствованными возможностями распыления СЗР, всесторонне направленным цифровым радаром для сканирования площади в 3Д на 360°, возможность работать автономно на разных угодьях, избегать препятствий при полетах',
	},
	protection_drons_sl2_h3: {
		ua: 'DJI Agras T30',
		ru: 'DJI Agras T30',
	},
	protection_drons_sl2_h4: {
		ua: 'Дрон',
		ru: 'Дрон',
	},
	protection_drons_sl2_p1: {
		ua: 'Висота: <b>300 м</b>',
		ru: 'Висота: <b>300 м</b>',
	},
	protection_drons_sl2_p2: {
		ua: 'Об\'єм бака: <b>30 л</b>',
		ru: 'Объем бака: <b>30 л</b>',
	},
	protection_drons_sl2_p3: {
		ua: 'Робочий радіус: <b>4.0 км</b>',
		ru: 'Рабочий радиус: <b>4.0 км</b>',
	},
	protection_drons_sl2_p4: {
		ua: 'Вага нетто: <b>7.8 кг</b>',
		ru: 'Масса нетто: <b>7.8 кг</b>',
	},
	protection_drons_sl2_p5: {
		ua: 'Вдосконалиними можливостями розпилення ЗЗР, усебічно спрямованим цифровим радаром для сканування площі в 3Д на 360°, можливість працювати автономно на різних угіддях, можливість уникати перешкод при польотах, збільшення ефективності роботи у польових умовах на 33%, оптимальна ширина розпилення у 9 м',
		ru: 'Усовершенствованными возможностями распыления СЗР, всесторонне направленным цифровым радаром для сканирования площади в 3Д на 360°, возможность работать автономно на разных угодьях, возможность избегать помех при полетах, увеличение эффективности работы в полевых условиях на 33%, оптимальная ширина распыления в 9 м',
	},
	
	// Slider Brands
	protection_technics_h2: {
		ua: 'Наш парк інноваційної техніки світових лідерів агрохімії',
		ru: 'Наш парк инновационной техники мировых лидеров агрохимии',
	},
	protection_technics_p: {
		ua: 'Парк <b>більше 10 одиниць техніки</b>, для виконання всіх виробничих операцій для агрохімії. Серед них такі бренди: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
		ru: 'Парк <b>более 10 единиц техники</b>, для выполнения всех производственных операций для агрохимии. Среди них такие бренды: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
	},
	
	// Slider Protection Park Technics
	protection_park_technics_h2: {
		ua: 'Для внесення ЗЗР ми використовуємо парк техніки',
		ru: 'Для внесения СЗР мы используем парк техники',
	},
	protection_park_technics_sl1_h3: {
		ua: 'John Deere 4030 R',
		ru: 'John Deere 4030 R',
	},
	protection_park_technics_sl1_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	protection_park_technics_sl1_p1: {
		ua: 'Кліренс: <b>152 см</b>',
		ru: 'Клиренс: <b>152 см</b>',
	},
	protection_park_technics_sl1_p2: {
		ua: 'Об\'єм бака: <b>3000 л</b>',
		ru: 'Объем бака: <b>3000 л</b>',
	},
	protection_park_technics_sl1_p3: {
		ua: 'Робоча швидкість: <b>16-25 км/г</b>',
		ru: 'Рабочая скорость: <b>16-25 км/ч</b>',
	},
	protection_park_technics_sl1_p4: {
		ua: 'Ширина захвату: <b>30, 36 м</b>',
		ru: 'Ширина захвата: <b>30, 36 м</b>',
	},
	protection_park_technics_sl1_p5: {
		ua: 'Оприскувач оснащений: технологією Section Control, яка дозволяє автоматично контролювати відключення секцій, системою вирівнювання штанги, автоматичним контролем нижнього рівня, системою Автопілот',
		ru: 'Опрыскиватель оснащен: технологией Section Control, позволяющей автоматически контролировать отключение секций, системой выравнивания штанги, автоматическим контролем нижнего уровня, системой автопилота',
	},
	protection_park_technics_sl2_h3: {
		ua: 'CASE IH SPX 4430',
		ru: 'CASE IH SPX 4430',
	},
	protection_park_technics_sl2_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	protection_park_technics_sl2_p1: {
		ua: 'Кліренс: <b>135 см</b>',
		ru: 'Клиренс: <b>135 см</b>',
	},
	protection_park_technics_sl2_p2: {
		ua: 'Об\'єм бака: <b>4500 л</b>',
		ru: 'Объем бака: <b>4500 л</b>',
	},
	protection_park_technics_sl2_p3: {
		ua: 'Робоча швидкість: <b>16-25 км/г</b>',
		ru: 'Рабочая скорость: <b>16-25 км/ч</b>',
	},
	protection_park_technics_sl2_p4: {
		ua: 'Ширина захвату: <b>36 м</b>',
		ru: 'Ширина захвата: <b>36 м</b>',
	},
	protection_park_technics_sl2_p5: {
		ua: 'Вдосконаленою системою вирівнювання тиску, системою автоматичного управління висотою штанги AutoBoom, системою автоматичного управління секціями штанги AccuBoom, яка автоматично відключає секції штанги, обладнаний системою Автопілот',
		ru: 'Усовершенствованная система выравнивания давления, система автоматического управления высотой штанги AutoBoom, система автоматического управления секциями штанги AccuBoom, которая автоматически отключает секции штанги, оборудованный системой Автопилот',
	},
	protection_park_technics_sl3_h3: {
		ua: 'Jacto Uniport 3030',
		ru: 'Jacto Uniport 3030',
	},
	protection_park_technics_sl3_h4: {
		ua: 'Обприскувач',
		ru: 'Опрыскиватель',
	},
	protection_park_technics_sl3_p1: {
		ua: 'Кліренс: <b>155 см</b>',
		ru: 'Клиренс: <b>155 см</b>',
	},
	protection_park_technics_sl3_p2: {
		ua: 'Об\'єм бака: <b>3800 л</b>',
		ru: 'Объем бака: <b>3800 л</b>',
	},
	protection_park_technics_sl3_p3: {
		ua: 'Робоча швидкість: <b>16-30 км/г</b>',
		ru: 'Рабочая скорость: <b>16-30 км/ч</b>',
	},
	protection_park_technics_sl3_p4: {
		ua: 'Ширина захвату: <b>32 м</b>',
		ru: 'Ширина захвата: <b>32 м</b>',
	},
	protection_park_technics_sl3_p5: {
		ua: 'Трансмісія з інтелектуальним електронним керуванням та незалежним автоматичним контролем пробуксовки, гальмівною системою з функцією ABS інтегрованою в ГСТ, пневматичною підвіскою із гідравлічними амортизаторами, що забезпечує відмінну плавність ходу, системою UNITRACK',
		ru: 'Трансмиссия с интеллектуальным электронным управлением и независимым автоматическим контролем пробуксовки, тормозной системой с функцией ABS интегрированной в ГСТ, пневматической подвеской с гидравлическими амортизаторами, что обеспечивает отличную плавность хода, UNITRACK',
	},
	
	// Result
	protection_result_h2: {
		ua: 'В результаті ви отримаєте:',
		ru: 'В результате вы получите:',
	},
	protection_result_li1: {
		ua: 'Гарантуємо <b>ефективний результат</b>',
		ru: 'Гарантируем <b>эффективный результат</b>',
	},
	protection_result_li2: {
		ua: 'Точну <b>калькуляцію витрат агрохімії</b>',
		ru: 'Точную <b>калькуляцию затрат агрохимии</b>',
	},
	protection_result_li3: {
		ua: 'Співпраця з <b>провідними виробниками ЗЗР</b>',
		ru: 'Сотрудничество с <b>ведущими производителями СЗР</b>',
	},
	protection_result_li4: {
		ua: 'Сучасний <b>парк необхідної техніки</b>',
		ru: 'Современный <b>парк необходимой техники</b>',
	},
	protection_result_li5: {
		ua: 'Жорсткий <b>контроль за нормами внесення</b>',
		ru: 'Жесткий <b>контроль за нормами внесения</b>',
	},
	protection_result_li6: {
		ua: 'Оптимізацію <b>здійснення заходів</b>',
		ru: 'Оптимизацию <b>осуществления мероприятий</b>',
	},
	
	// Result Description
	protection_result_desc_h2: {
		ua: 'Ваш результат - наша мета',
		ru: 'Ваш результат - наша цель',
	},
	protection_result1_desc_h3: {
		ua: 'Гарантуємо ефективний результат',
		ru: 'Гарантируем эффективный результат',
	},
	protection_result_desc_p1: {
		ua: 'CAREFIELD несе відповідальність за результат. Ми вносимо необхідну кількість ЗЗР, <b>використовуємо автоматичну систему змішування та дозування</b>, гарантуємо захист ЗЗР на етапі зберігання',
		ru: 'CAREFIELD несет ответственность за результат. Мы вносим необходимое количество СЗР, <b>используем автоматическую систему смешивания и дозировки</b>, гарантируем защиту СЗР на этапе хранения',
	},
	protection_result2_desc_h3: {
		ua: 'Точну калькуляцію витрат агрохімії',
		ru: 'Точную калькуляцию затрат агрохимии',
	},
	protection_result_desc_p2: {
		ua: 'Багаторічний досвід та новітні технології, що використовують спеціалісти CAREFIELD, дозволяють <b>здійснити детальну та зрозумілу оцінку</b> обов\'язкових та позапланових витрат',
		ru: 'Многолетний опыт и новейшие технологии, используемые специалистами CAREFIELD, позволяют <b>провести детальную и понятную оценку</b> обязательных и внеплановых затрат',
	},
	protection_result3_desc_h3: {
		ua: 'Співпраця з провідними виробниками ЗЗР',
		ru: 'Сотрудничество с ведущими производителями СЗР',
	},
	protection_result_desc_p3: {
		ua: 'CAREFIELD працює з перевіреними виробниками ЗЗР протягом багатьох років. <b>Тестування ЗЗР проводяться професійною командою</b> з використанням необхідного обладнання',
		ru: 'CAREFIELD работает с проверенными производителями СЗР на протяжении многих лет. <b>Тестирование СЗР проводится профессиональной командой</b> с использованием необходимого оборудования',
	},
	protection_result4_desc_h3: {
		ua: 'Сучасний парк необхідної техніки',
		ru: 'Современный парк необходимой техники',
	},
	protection_result_desc_p4: {
		ua: 'Наявність у CAREFIELD всієї <b>необхідної техніки</b> забезпечить своєчасну та повноцінну логістику води',
		ru: 'Наличие в CAREFIELD всей <b>необходимой техники</b> обеспечит своевременную и полноценную логистику воды',
	},
	protection_result5_desc_h3: {
		ua: 'Жорсткий контроль за нормами внесення',
		ru: 'Жесткий контроль за нормами внесения',
	},
	protection_result_desc_p5: {
		ua: 'Лише <b>професіонали та належний рівень технічного обслуговування</b> можуть запобігти втратам врожайності',
		ru: 'Только <b>профессионалы и надлежащий уровень технического обслуживания</b> могут предотвратить потери урожайности',
	},
	protection_result6_desc_h3: {
		ua: 'Оптимізацію здійснення заходів',
		ru: 'Оптимизацию осуществления мероприятий',
	},
	protection_result_desc_p6: {
		ua: 'CAREFIELD враховує <b>особливості перебігу зовнішніх та внутрішніх процесів</b> у провадженні усіх видів робіт',
		ru: 'CAREFIELD учитывает <b>особенности протекания внешних и внутренних процессов</b> в производстве всех видов работ',
	},
	
	// Slider Brands
	protection_fertilizers_brands_h2: {
		ua: 'Світові лідері агрохімії',
		ru: 'Мировые лидеры агрохимии',
	},
	protection_fertilizers_brands_p: {
		ua: 'Асортимент становить <b>більше ніж 35 одиниць</b> продукції світових лідерів агрохімії, для виконання всіх виробничих операцій. Серед них такі бренди: AVGUST, BAYER, ADAMA, PIONEER, ALFA AGRO SMART, SYNGENTA',
		ru: 'Ассортимент составляет <b>более 35 единиц</b> продукции мировых лидеров агрохимии, для выполнения всех производственных операций. Среди них такие бренды: AVGUST, BAYER, ADAMA, PIONEER, ALFA AGRO SMART, SYNGENTA',
	},
	
	// Banner #3
	protection_banner3_title: {
		ua: 'Повний комплекс CAREFIELD',
		ru: 'Полный комплекс CAREFIELD',
	},
	protection_banner3_p: {
		ua: 'Щоб замовити послугу по внесенню засобів захисту рослин, вкажіть ваш номер',
		ru: 'Чтобы заказать услугу по внесению средств защиты растений, укажите ваш номер',
	},
	protection_banner3_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Success
	protection_success_h2: {
		ua: 'При виконанні послуги внесення добрив ми робимо ваш бізнес прибутковим',
		ru: 'При выполнении услуги внесения удобрений мы делаем ваш бизнес прибыльным',
	},
	protection_success_p1: {
		ua: 'Враховуємо особливості поля та культури, хімічний стан ґрунту',
		ru: 'Учитываем особенности поля и культуры, химическое состояние почвы',
	},
	protection_success_p2: {
		ua: 'Проводимо точну калькуляцію витрат на ЗЗР та співвідношення ТМЦ',
		ru: 'Проводимо точну калькуляцію витрат на ЗЗР та співвідношення ТМЦ',
	},
	protection_success_p3: {
		ua: 'Здійснюємо аналіз та контроль 24/7 всього виробничого процесу',
		ru: 'Осуществляем анализ и контроль 24/7 всего производственного процесса',
	},
	
}

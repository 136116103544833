import React, {useEffect} from 'react';
import {contextArrFestival} from '../../db/db_page_festival';
import {listFestivalPrinciples, listFestivalAbout, listFestivalItemsNumbers} from '../../db/db_lists_festival';
import {listTechnicsProtection} from '../../db/db_lists_protection';
import './Festival.scss';

import offerMax from '../../images/festival/offer-bg.jpg';
import offerMin from '../../images/festival/offer-bg-mob.jpg';
import imagePrinciples from '../../images/festival/principles-bg.jpg';
import imageAbout from '../../images/festival/about-bg.jpg';
import imageBanner from '../../images/banner_box12.jpg';

import Offer from '../../sections/Offer/Offer';
import ListImage from '../../sections/ListImage/ListImage';
import Technics from '../../sections/Technics/Technics';
import ListImageText from '../../sections/ListImageText/ListImageText';
import BannerBox from '../../sections/BannerBox/BannerBox';

const Festival = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrFestival) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrFestival[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'festival'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-festival_offer_h1'}
				h3Class={'lng-festival_offer_h3'}
				btnClass={'btn lng-festival_offer_btn'}
				onClick={props.onClick}
				{...props}
			/>
			<ListImage
				id={'principles'}
				lt={1}
				rt={2}
				h2Class={'lng-festival_principles_h2'}
				list={listFestivalPrinciples}
				image={imagePrinciples}
				shadow={'shadow'}
			/>
			<ListImage
				id={'festival-about'}
				lt={2}
				rt={1}
				h2Class={'lng-festival_about_h2'}
				image={imageAbout}
				list={listFestivalAbout}
				shadow={'shadow'}
			/>
			<Technics
				idsl={'technics-slider'}
				el={'el'}
				classesH2={'lng-festival_participants_h2'}
				classesP={'lng-festival_participants_p'}
				list={listTechnicsProtection}
			/>
			<ListImageText
				h2Class={'lng-festival_numbers_h2'}
				listItems={listFestivalItemsNumbers}
			/>
			<BannerBox
				image={imageBanner}
				classesH2={'lng-festival_banner_title'}
				classesP={'lng-festival_banner_p'}
				classesBtn={'lng-festival_banner_btn btn'}
				onClick={props.onClick}
			/>
		</main>
	);
};

export default Festival;

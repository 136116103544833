export const contextArrInformation = {
	// Page: Information
	information_offer_h1: {
		ua: 'Обробка інформації',
		ru: 'Обработка информации',
	},
	information_offer_h3: {
		ua: 'Під час користування сайтом можуть оброблятись окремі види інформації',
		ru: 'При использовании сайта могут обрабатываться отдельные виды информации',
	},
	information_offer_btn: {
		ua: 'Детальнiше',
		ru: 'Детальнее',
	},
	
	// Text
	information_text: {
		ua: `
		<h2>Обробка інформації</h2><br/>
		<h3>Обробка іншої інформації</h3><br/>
		Під час користування сайтом можуть оброблятись окремі види інформації, як віднесеної до персональних даних, так і такої, що не є персональними даними у розумінні чинного законодавства.<br/>
		Інформація про Користувачів обробляється під час користування сайтом завдяки, зокрема:<br/>
		- плагіну Facebook (плагін, що забезпечує з’єднання Інтернет-магазину з профілем у Facebook, завдяки якому Facebook отримує інформацію про відвідування Користувачем сайту);<br/>
		- Google Analytics (служба веб аналітики, що відстежує трафік сайту та може використовувати зібрану інформацію для роботи з оголошеннями власної рекламної мережі).<br/>
		`,
		ru: `
		<h2>Обработка информации</h2><br/>
		<h3>Обработка другой информации</h3><br/>
		При использовании сайта могут обрабатываться отдельные виды информации, как отнесенной к персональным данным, так и не являющиеся персональными данными в понимании действующего законодательства.<br/>
		Информация о Пользователях обрабатывается при использовании сайта, в частности:<br/>
		- плагин Facebook (плагин, обеспечивающий соединение Интернет-магазина с профилем в Facebook, благодаря которому Facebook получает информацию о посещении Пользователем сайта);<br/>
		- Google Analytics (служба веб-аналитики, отслеживающая трафик сайта и может использовать собранную информацию для работы с объявлениями собственной рекламной сети).<br/>
		`,
	}
}

import React from 'react';
import './Btn.scss'

const Btn = (props) => {
	return (
		<button onClick={props.onClick} className={props.btnClass}
		        style={props.styles} type={props.type}/>
	);
};

export default Btn;

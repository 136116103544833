import React, {useState} from 'react';
import SingleTitleContainer from './SingleTitleContainer';
import './SingleContainer.scss';

function SingleContainer(props) {
	const [expanded, setExpanded] = useState(false);
	
	return (
		<li id={`single-container${props.id}`} className={'single-container'}>
			<div className="single-container-title">
				<SingleTitleContainer onClick={() => setExpanded(!expanded)} {...props}/>
			</div>
			<div className={expanded ? 'single-container-panel' : 'dn single-container-panel'}>
				{props.children}
			</div>
		</li>
	);
}

export default SingleContainer;

import React from 'react';
import TitleH3 from '../../components/Title/TitleH3';

function TextColBoxItem(props) {
	return (
		<li>
			<TitleH3 {...props}/>
			<p className={props.classesP}/>
		</li>
	);
}

export default TextColBoxItem;

import homeSl1 from '../images/home/slide-tr3.png';
import homeSl2 from '../images/home/slide-tr2.png';
import homeSl3 from '../images/home/slide-tr1.png';
import homeSl4 from '../images/home/slide1.png';
import homeSl5 from '../images/home/slide2.png';
import homeSl6 from '../images/home/slide3.png';

import groundSl0 from '../images/ground/ground-sl0.png';
import groundSl1 from '../images/ground/ground-sl1.png';
import groundSl2 from '../images/ground/ground-sl2.png';
import groundSl3 from '../images/ground/ground-sl3.png';
import groundSl4 from '../images/ground/ground-sl4.png';
import groundSl5 from '../images/ground/ground-sl5.png';
import groundSl6 from '../images/ground/ground-sl6.png';
import groundSl7 from '../images/ground/ground-sl7.png';
import groundSl8 from '../images/ground/ground-sl8.png';
import groundSl9 from '../images/ground/ground-sl9.png';

import groundSl10 from '../images/ground/ground-sl10.png';
import groundSl11 from '../images/ground/ground-sl11.png';

import parkTechnicsSl1 from '../images/protection/slider2/slide-1.png';
import parkTechnicsSl2 from '../images/protection/slider2/slide-2.png';
import parkTechnicsSl3 from '../images/protection/slider2/slide-3.png';

import seedingSl1 from '../images/sowing/sl_park_seeding/slide1.png';
import seedingSl2 from '../images/sowing/sl_park_seeding/slide2.png';
import seedingSl3 from '../images/sowing/sl_park_seeding/slide3.png';
import seedingSl4 from '../images/sowing/sl_park_seeding/slide4.png';
import seedingSl5 from '../images/sowing/sl_park_seeding/slide5.png';

import iconDepth from '../images/icons/slider/i-depth.png';
import iconDiameter from '../images/icons/slider/i-diameter.png';
import iconSize from '../images/icons/slider/i-size.png';
import iconSpeed from '../images/icons/slider/i-speed.png';
import iconTara from '../images/icons/slider/i-tara.png';
import iconWeight from '../images/icons/slider/i-weight.png';
import iconWheel from '../images/icons/slider/i-wheel.png';
import iconZub from '../images/icons/slider/i-zub.png';
import iconZerno from '../images/icons/slider/i-zerno.jpg';
import iconQuantity from '../images/icons/slider/i-quantity.png';
import iconPower from '../images/icons/slider/i-power.png';
import iconMT from '../images/icons/slider/i-kpp.png';

import dronSl1 from '../images/protection/slider1/dron1.png';
import dronSl2 from '../images/protection/slider1/dron2.png';

export const listParkHardTechnics = [
	{
		id: 1,
		pic: homeSl1,
		classesH3: 'lng-home_sl1_h3',
		classesH4: 'lng-home_sl1_h4',
		icon1: iconPower,
		text1: 'lng-home_sl1_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl1_p2',
		icon3: iconMT,
		text3: 'lng-home_sl1_p3',
		icon4: iconWeight,
		text4: 'lng-home_sl1_p4',
		text5: 'lng-home_sl1_p5',
	},
	{
		id: 2,
		pic: homeSl2,
		classesH3: 'lng-home_sl2_h3',
		classesH4: 'lng-home_sl2_h4',
		icon1: iconPower,
		text1: 'lng-home_sl2_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl2_p2',
		icon3: iconMT,
		text3: 'lng-home_sl2_p3',
		icon4: iconWeight,
		text4: 'lng-home_sl2_p4',
		text5: 'lng-home_sl2_p5',
	},
	{
		id: 3,
		pic: homeSl3,
		classesH3: 'lng-home_sl3_h3',
		classesH4: 'lng-home_sl3_h4',
		icon1: iconPower,
		text1: 'lng-home_sl3_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl3_p2',
		icon3: iconMT,
		text3: 'lng-home_sl3_p3',
		icon4: iconWeight,
		text4: 'lng-home_sl3_p4',
		text5: 'lng-home_sl3_p5',
	},
	{
		id: 4,
		pic: homeSl4,
		classesH3: 'lng-home_sl4_h3',
		classesH4: 'lng-home_sl4_h4',
		icon1: iconSize,
		text1: 'lng-home_sl4_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl4_p2',
		icon3: iconTara,
		text3: 'lng-home_sl4_p3',
		icon4: iconPower,
		text4: 'lng-home_sl4_p4',
		text5: 'lng-home_sl4_p5',
	},
	{
		id: 5,
		pic: homeSl5,
		classesH3: 'lng-home_sl5_h3',
		classesH4: 'lng-home_sl5_h4',
		icon1: iconSize,
		text1: 'lng-home_sl5_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl5_p2',
		icon3: iconTara,
		text3: 'lng-home_sl5_p3',
		icon4: iconPower,
		text4: 'lng-home_sl5_p4',
		text5: 'lng-home_sl5_p5',
	},
	{
		id: 6,
		pic: homeSl6,
		classesH3: 'lng-home_sl6_h3',
		classesH4: 'lng-home_sl6_h4',
		icon1: iconPower,
		text1: 'lng-home_sl6_p1',
		icon2: iconSpeed,
		text2: 'lng-home_sl6_p2',
		icon3: iconMT,
		text3: 'lng-home_sl6_p3',
		icon4: iconWeight,
		text4: 'lng-home_sl6_p4',
		text5: 'lng-home_sl6_p5',
	},
]

export const listParkTechnics = [
	{
		id: 1,
		pic: groundSl1,
		classesH3: 'lng-ground_sl1_h3',
		classesH4: 'lng-ground_sl1_h4',
		icon1: iconWeight,
		text1: 'lng-ground_sl1_p1',
		icon2: iconDepth,
		text2: 'lng-ground_sl1_p2',
		icon3: iconDiameter,
		text3: 'lng-ground_sl1_p3',
		icon4: iconSize,
		text4: 'lng-ground_sl1_p4',
		text5: 'lng-ground_sl1_p5',
	},
	{
		id: 2,
		pic: groundSl2,
		classesH3: 'lng-ground_sl2_h3',
		classesH4: 'lng-ground_sl2_h4',
		icon1: iconWeight,
		text1: 'lng-ground_sl2_p1',
		icon2: iconDepth,
		text2: 'lng-ground_sl2_p2',
		icon3: iconDiameter,
		text3: 'lng-ground_sl2_p3',
		icon4: iconSize,
		text4: 'lng-ground_sl2_p4',
		text5: 'lng-ground_sl2_p5',
	},
	{
		id: 3,
		pic: groundSl3,
		classesH3: 'lng-ground_sl3_h3',
		classesH4: 'lng-ground_sl3_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl3_p1',
		icon2: iconWeight,
		text2: 'lng-ground_sl3_p2',
		icon3: iconDiameter,
		text3: 'lng-ground_sl3_p3',
		icon4: iconDepth,
		text4: 'lng-ground_sl3_p4',
		text5: 'lng-ground_sl3_p5',
	},
	{
		id: 4,
		pic: groundSl4,
		classesH3: 'lng-ground_sl4_h3',
		classesH4: 'lng-ground_sl4_h4',
		icon1: iconWheel,
		text1: 'lng-ground_sl4_p1',
		icon2: iconZub,
		text2: 'lng-ground_sl4_p2',
		icon3: iconSize,
		text3: 'lng-ground_sl4_p3',
		icon4: iconDepth,
		text4: 'lng-ground_sl4_p4',
		text5: 'lng-ground_sl4_p5',
	},
	{
		id: 5,
		pic: groundSl5,
		classesH3: 'lng-ground_sl5_h3',
		classesH4: 'lng-ground_sl5_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl5_p1',
		icon2: iconDiameter,
		text2: 'lng-ground_sl5_p2',
		icon3: iconSize,
		text3: 'lng-ground_sl5_p3',
		icon4: iconDepth,
		text4: 'lng-ground_sl5_p4',
		text5: 'lng-ground_sl5_p5',
	},
	{
		id: 6,
		pic: groundSl6,
		classesH3: 'lng-ground_sl6_h3',
		classesH4: 'lng-ground_sl6_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl6_p1',
		icon2: iconZub,
		text2: 'lng-ground_sl6_p2',
		icon3: iconSize,
		text3: 'lng-ground_sl6_p3',
		icon4: iconWeight,
		text4: 'lng-ground_sl6_p4',
		text5: 'lng-ground_sl6_p5',
	},
	{
		id: 7,
		pic: groundSl7,
		classesH3: 'lng-ground_sl7_h3',
		classesH4: 'lng-ground_sl7_h4',
		icon1: iconSize,
		text1: 'lng-ground_sl7_p1',
		icon2: iconSize,
		text2: 'lng-ground_sl7_p2',
		icon3: iconTara,
		text3: 'lng-ground_sl7_p3',
		icon4: iconWeight,
		text4: 'lng-ground_sl7_p4',
		text5: 'lng-ground_sl7_p5',
	},
	{
		id: 8,
		pic: groundSl8,
		classesH3: 'lng-ground_sl8_h3',
		classesH4: 'lng-ground_sl8_h4',
		icon1: iconSize,
		text1: 'lng-ground_sl8_p1',
		icon2: iconSize,
		text2: 'lng-ground_sl8_p2',
		icon3: iconTara,
		text3: 'lng-ground_sl8_p3',
		icon4: iconSpeed,
		text4: 'lng-ground_sl8_p4',
		text5: 'lng-ground_sl8_p5',
	}
]

export const listParkFertilization = [
	{
		id: 1,
		pic: groundSl9,
		classesH3: 'lng-ground_sl9_h3',
		classesH4: 'lng-ground_sl9_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl9_p1',
		icon2: iconTara,
		text2: 'lng-ground_sl9_p2',
		icon3: iconSpeed,
		text3: 'lng-ground_sl9_p3',
		icon4: iconSize,
		text4: 'lng-ground_sl9_p4',
		text5: 'lng-ground_sl9_p5',
	},
	{
		id: 2,
		pic: groundSl10,
		classesH3: 'lng-ground_sl10_h3',
		classesH4: 'lng-ground_sl10_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl10_p1',
		icon2: iconTara,
		text2: 'lng-ground_sl10_p2',
		icon3: iconSpeed,
		text3: 'lng-ground_sl10_p3',
		icon4: iconSize,
		text4: 'lng-ground_sl10_p4',
		text5: 'lng-ground_sl10_p5',
	},
	{
		id: 3,
		pic: groundSl11,
		classesH3: 'lng-ground_sl11_h3',
		classesH4: 'lng-ground_sl11_h4',
		icon1: iconDepth,
		text1: 'lng-ground_sl11_p1',
		icon2: iconTara,
		text2: 'lng-ground_sl11_p2',
		icon3: iconSpeed,
		text3: 'lng-ground_sl11_p3',
		icon4: iconDepth,
		text4: 'lng-ground_sl11_p4',
		text5: 'lng-ground_sl11_p5',
	},
]

export const listParkTechnicsSowing = [
	{
		id: 0,
		pic: groundSl0,
		classesH3: 'lng-sowing_sl0_h3',
		classesH4: 'lng-sowing_sl0_h4',
		icon1: iconWeight,
		text1: 'lng-sowing_sl0_p1',
		icon2: iconSpeed,
		text2: 'lng-sowing_sl0_p2',
		icon3: iconZerno,
		text3: 'lng-sowing_sl0_p3',
		icon4: iconZerno,
		text4: 'lng-sowing_sl0_p4',
		text5: 'lng-sowing_sl0_p5',
	},
	{
		id: 1,
		pic: groundSl1,
		classesH3: 'lng-sowing_sl1_h3',
		classesH4: 'lng-sowing_sl1_h4',
		icon1: iconWeight,
		text1: 'lng-sowing_sl1_p1',
		icon2: iconDepth,
		text2: 'lng-sowing_sl1_p2',
		icon3: iconDiameter,
		text3: 'lng-sowing_sl1_p3',
		icon4: iconSize,
		text4: 'lng-sowing_sl1_p4',
		text5: 'lng-sowing_sl1_p5',
	},
	{
		id: 2,
		pic: groundSl2,
		classesH3: 'lng-sowing_sl2_h3',
		classesH4: 'lng-sowing_sl2_h4',
		icon1: iconWeight,
		text1: 'lng-sowing_sl2_p1',
		icon2: iconDepth,
		text2: 'lng-sowing_sl2_p2',
		icon3: iconDiameter,
		text3: 'lng-sowing_sl2_p3',
		icon4: iconSize,
		text4: 'lng-sowing_sl2_p4',
		text5: 'lng-sowing_sl2_p5',
	},
	{
		id: 3,
		pic: groundSl3,
		classesH3: 'lng-sowing_sl3_h3',
		classesH4: 'lng-sowing_sl3_h4',
		icon1: iconDepth,
		text1: 'lng-sowing_sl3_p1',
		icon2: iconWeight,
		text2: 'lng-sowing_sl3_p2',
		icon3: iconDiameter,
		text3: 'lng-sowing_sl3_p3',
		icon4: iconDepth,
		text4: 'lng-sowing_sl3_p4',
		text5: 'lng-sowing_sl3_p5',
	},
	{
		id: 4,
		pic: groundSl4,
		classesH3: 'lng-sowing_sl4_h3',
		classesH4: 'lng-sowing_sl4_h4',
		icon1: iconWheel,
		text1: 'lng-sowing_sl4_p1',
		icon2: iconZub,
		text2: 'lng-sowing_sl4_p2',
		icon3: iconSize,
		text3: 'lng-sowing_sl4_p3',
		icon4: iconDepth,
		text4: 'lng-sowing_sl4_p4',
		text5: 'lng-sowing_sl4_p5',
	},
	{
		id: 5,
		pic: groundSl5,
		classesH3: 'lng-sowing_sl5_h3',
		classesH4: 'lng-sowing_sl5_h4',
		icon1: iconDepth,
		text1: 'lng-sowing_sl5_p1',
		icon2: iconDiameter,
		text2: 'lng-sowing_sl5_p2',
		icon3: iconSize,
		text3: 'lng-sowing_sl5_p3',
		icon4: iconDepth,
		text4: 'lng-sowing_sl5_p4',
		text5: 'lng-sowing_sl5_p5',
	},
	{
		id: 6,
		pic: groundSl6,
		classesH3: 'lng-sowing_sl6_h3',
		classesH4: 'lng-sowing_sl6_h4',
		icon1: iconDepth,
		text1: 'lng-sowing_sl6_p1',
		icon2: iconZub,
		text2: 'lng-sowing_sl6_p2',
		icon3: iconSize,
		text3: 'lng-sowing_sl6_p3',
		icon4: iconWeight,
		text4: 'lng-sowing_sl6_p4',
		text5: 'lng-sowing_sl6_p5',
	},
	{
		id: 7,
		pic: groundSl7,
		classesH3: 'lng-sowing_sl7_h3',
		classesH4: 'lng-sowing_sl7_h4',
		icon1: iconSize,
		text1: 'lng-sowing_sl7_p1',
		icon2: iconSize,
		text2: 'lng-sowing_sl7_p2',
		icon3: iconTara,
		text3: 'lng-sowing_sl7_p3',
		icon4: iconWeight,
		text4: 'lng-sowing_sl7_p4',
		text5: 'lng-sowing_sl7_p5',
	},
]

export const listParkSeedingSowing = [
	{
		id: 1,
		pic: seedingSl1,
		classesH3: 'lng-sowing_park_seeding_sl1_h3',
		classesH4: 'lng-sowing_park_seeding_sl1_h4',
		icon1: iconQuantity,
		text1: 'lng-sowing_park_seeding_sl1_p1',
		icon2: iconTara,
		text2: 'lng-sowing_park_seeding_sl1_p2',
		icon3: iconSize,
		text3: 'lng-sowing_park_seeding_sl1_p3',
		icon4: iconSpeed,
		text4: 'lng-sowing_park_seeding_sl1_p4',
		text5: 'lng-sowing_park_seeding_sl1_p5',
	},
	{
		id: 2,
		pic: seedingSl2,
		classesH3: 'lng-sowing_park_seeding_sl2_h3',
		classesH4: 'lng-sowing_park_seeding_sl2_h4',
		icon1: iconQuantity,
		text1: 'lng-sowing_park_seeding_sl2_p1',
		icon2: iconTara,
		text2: 'lng-sowing_park_seeding_sl2_p2',
		icon3: iconSize,
		text3: 'lng-sowing_park_seeding_sl2_p3',
		icon4: iconSpeed,
		text4: 'lng-sowing_park_seeding_sl2_p4',
		text5: 'lng-sowing_park_seeding_sl2_p5',
	},
	{
		id: 3,
		pic: seedingSl3,
		classesH3: 'lng-sowing_park_seeding_sl3_h3',
		classesH4: 'lng-sowing_park_seeding_sl3_h4',
		icon1: iconQuantity,
		text1: 'lng-sowing_park_seeding_sl3_p1',
		icon2: iconTara,
		text2: 'lng-sowing_park_seeding_sl3_p2',
		icon3: iconSize,
		text3: 'lng-sowing_park_seeding_sl3_p3',
		icon4: iconSpeed,
		text4: 'lng-sowing_park_seeding_sl3_p4',
		text5: 'lng-sowing_park_seeding_sl3_p5',
	},
	{
		id: 4,
		pic: seedingSl4,
		classesH3: 'lng-sowing_park_seeding_sl4_h3',
		classesH4: 'lng-sowing_park_seeding_sl4_h4',
		icon1: iconQuantity,
		text1: 'lng-sowing_park_seeding_sl4_p1',
		icon2: iconTara,
		text2: 'lng-sowing_park_seeding_sl4_p2',
		icon3: iconSize,
		text3: 'lng-sowing_park_seeding_sl4_p3',
		icon4: iconSpeed,
		text4: 'lng-sowing_park_seeding_sl4_p4',
		text5: 'lng-sowing_park_seeding_sl4_p5',
	},
	{
		id: 5,
		pic: seedingSl5,
		classesH3: 'lng-sowing_park_seeding_sl5_h3',
		classesH4: 'lng-sowing_park_seeding_sl5_h4',
		icon1: iconQuantity,
		text1: 'lng-sowing_park_seeding_sl5_p1',
		icon2: iconTara,
		text2: 'lng-sowing_park_seeding_sl5_p2',
		icon3: iconSize,
		text3: 'lng-sowing_park_seeding_sl5_p3',
		icon4: iconDepth,
		text4: 'lng-sowing_park_seeding_sl5_p4',
		text5: 'lng-sowing_park_seeding_sl5_p5',
	},
]

export const listParkDrons = [
	{
		id: 1,
		pic: dronSl1,
		classesH3: 'lng-protection_drons_sl1_h3',
		classesH4: 'lng-protection_drons_sl1_h4',
		icon1: iconDepth,
		text1: 'lng-protection_drons_sl1_p1',
		icon2: iconTara,
		text2: 'lng-protection_drons_sl1_p2',
		icon3: iconSpeed,
		text3: 'lng-protection_drons_sl1_p3',
		icon4: iconWeight,
		text4: 'lng-protection_drons_sl1_p4',
		text5: 'lng-protection_drons_sl1_p5',
	},
	{
		id: 2,
		pic: dronSl2,
		classesH3: 'lng-protection_drons_sl2_h3',
		classesH4: 'lng-protection_drons_sl2_h4',
		icon1: iconDepth,
		text1: 'lng-protection_drons_sl2_p1',
		icon2: iconTara,
		text2: 'lng-protection_drons_sl2_p2',
		icon3: iconSpeed,
		text3: 'lng-protection_drons_sl2_p3',
		icon4: iconSize,
		text4: 'lng-protection_drons_sl2_p4',
		text5: 'lng-protection_drons_sl2_p5',
	},
]

export const listProtectionParkTechnics = [
	{
		id: 1,
		pic: parkTechnicsSl1,
		classesH3: 'lng-protection_park_technics_sl1_h3',
		classesH4: 'lng-protection_park_technics_sl1_h4',
		icon1: iconDepth,
		text1: 'lng-protection_park_technics_sl1_p1',
		icon2: iconTara,
		text2: 'lng-protection_park_technics_sl1_p2',
		icon3: iconSpeed,
		text3: 'lng-protection_park_technics_sl1_p3',
		icon4: iconSize,
		text4: 'lng-protection_park_technics_sl1_p4',
		text5: 'lng-protection_park_technics_sl1_p5',
	},
	{
		id: 2,
		pic: parkTechnicsSl2,
		classesH3: 'lng-protection_park_technics_sl2_h3',
		classesH4: 'lng-protection_park_technics_sl2_h4',
		icon1: iconDepth,
		text1: 'lng-protection_park_technics_sl2_p1',
		icon2: iconTara,
		text2: 'lng-protection_park_technics_sl2_p2',
		icon3: iconSpeed,
		text3: 'lng-protection_park_technics_sl2_p3',
		icon4: iconWeight,
		text4: 'lng-protection_park_technics_sl2_p4',
		text5: 'lng-protection_park_technics_sl2_p5',
	},
	{
		id: 3,
		pic: parkTechnicsSl3,
		classesH3: 'lng-protection_park_technics_sl3_h3',
		classesH4: 'lng-protection_park_technics_sl3_h4',
		icon1: iconDepth,
		text1: 'lng-protection_park_technics_sl3_p1',
		icon2: iconTara,
		text2: 'lng-protection_park_technics_sl3_p2',
		icon3: iconSpeed,
		text3: 'lng-protection_park_technics_sl3_p3',
		icon4: iconWeight,
		text4: 'lng-protection_park_technics_sl3_p4',
		text5: 'lng-protection_park_technics_sl3_p5',
	},

]

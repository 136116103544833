import React, {useEffect} from 'react';
import './Complex.scss'

import {contextArrComplex} from '../../db/db_page_complex';
import {
	listComplexItemsHarvesting,
	listComplexPhases,
	listComplexReloaderHopper,
	listComplexWorking,
	listComplexPaymentHarvest,
	listComplexNonStandardQuality,
	listComplexItemsNonStandardQuality,
	listSComplexRadius,
	listComplexExport,
	listComplexExport2
} from '../../db/db_lists_complex';

import offerMax from '../../images/complex/offer-bg.jpg';
import offerMin from '../../images/complex/offer-bg-mob.jpg';
import imageBannerContainer1 from '../../images/complex/collection-bg.jpg';
import imageWorking from '../../images/complex/tractors.jpg';
import imagePhases from '../../images/complex/phases-bg.jpg';
import imageBannerContainer2 from '../../images/complex/non-standart-bg.jpg';
import imageBannerContainer3 from '../../images/complex/export-bg.jpg';
import imageReloaderHopper from '../../images/complex/bunker.jpg';
import imagePaymentHarvest from '../../images/complex/payment-harvest-bg.jpg';
import imageNonStandardQuality from '../../images/complex/non-standard-quality-bg.jpg';
import imageRadius from '../../images/radius.jpg';
import imageExport from '../../images/complex/export2.jpg';
import iconTitleDiagnostics from '../../images/icons/i-money.jpg';

import Offer from '../../sections/Offer/Offer';
import TitleH2 from '../../components/Title/TitleH2';
import SingleContainer from '../../sections/SingleContainer/SingleContainer';
import ListImage from '../../sections/ListImage/ListImage';
import ListImageText from '../../sections/ListImageText/ListImageText';
import TextList from '../../sections/TextList/TextList';

const Complex = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrComplex) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrComplex[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'complex'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-complex_offer_h1'}
				h3Class={'lng-complex_offer_h3'}
				btnClass={'btn lng-complex_offer_btn'}
				onClick={props.onClick}
				{...props}
			/>
			<section>
				<div className="container">
					<TitleH2 h2Class={'lng-complex_title_h2'}/>
					<ul>
						<SingleContainer
							key={1}
							id={1}
							image={imageBannerContainer1}
							classesH2={'lng-complex_banner_title1'}
							classesP={'lng-complex_banner_p1'}
							classesBtn={'lng-complex_banner_btn1 btn'}
						>
							<ListImage
								id={'working'}
								lt={1}
								rt={2}
								h2Class={'lng-complex_working_h2'}
								list={listComplexWorking}
								image={imageWorking}
								shadow={''}
							/>
							<ListImageText
								h2Class={'lng-complex_harvesting_h2'}
								listItems={listComplexItemsHarvesting}
							/>
							<ListImage
								id={'phases'}
								lt={2}
								rt={1}
								h2Class={'lng-complex_phases_h2'}
								image={imagePhases}
								list={listComplexPhases}
								shadow={'shadow'}
							/>
							<ListImage
								id={'reloader-hopper'}
								lt={1}
								rt={2}
								h2Class={'lng-complex_reloader_hoppers_h2'}
								image={imageReloaderHopper}
								list={listComplexReloaderHopper}
								shadow={''}
							/>
							<ListImage
								id={'payment-harvest'}
								lt={2}
								rt={1}
								h2Class={'lng-complex_payment_harvest_h2'}
								image={imagePaymentHarvest}
								list={listComplexPaymentHarvest}
								shadow={''}
							/>
						</SingleContainer>
						
						<SingleContainer
							key={2}
							id={2}
							image={imageBannerContainer2}
							classesH2={'lng-complex_banner_title2'}
							classesP={'lng-complex_banner_p2'}
							classesBtn={'lng-complex_banner_btn2 btn'}
						>
							<ListImage
								id={'non-standard-quality'}
								lt={2}
								rt={1}
								h2Class={'lng-complex_non_standard_quality_h2'}
								image={imageNonStandardQuality}
								list={listComplexNonStandardQuality}
								shadow={''}
							/>
							<ListImageText
								h2Class={'lng-complex_non_standard_quality2_h2'}
								listItems={listComplexItemsNonStandardQuality}
							/>
							<ListImage
								id={'radius'}
								lt={1}
								rt={2}
								h2Class={'lng-complex_radius_h2'}
								image={imageRadius}
								list={listSComplexRadius}
								shadow={'shadow'}
							/>
						</SingleContainer>
						
						<SingleContainer
							key={3}
							id={3}
							image={imageBannerContainer3}
							classesH2={'lng-complex_banner_title3'}
							classesP={'lng-complex_banner_p3'}
							classesBtn={'lng-complex_banner_btn3 btn'}
						>
							<ListImage
								id={'export'}
								lt={1}
								rt={2}
								h2Class={'lng-complex_export_h2'}
								list={listComplexExport}
								image={imageExport}
								shadow={'shadow'}
							/>
							<ListImageText
								h2Class={'lng-complex_export2_h2'}
								listItems={listComplexExport2}
							/>
							<TextList
								id={'difficulties-farmers'}
								h2Class={'lng-complex_difficulties_farmers_h2'}
								src={iconTitleDiagnostics}
								h3Class={'lng-complex_difficulties_farmers_h3'}
								p1Class={'lng-complex_difficulties_farmers_p1'}
								p2Class={'lng-complex_difficulties_farmers_p2'}
								p3Class={'lng-complex_difficulties_farmers_p3'}
								minRow={1}
								maxRow={6}
								classListDesc={'lng-complex_difficulties_farmers_desc'}
							/>
						</SingleContainer>
					</ul>
				</div>
			</section>
		</main>
	);
};

export default Complex;

import React from 'react';
import './Services.scss'
import TitleH2 from '../../components/Title/TitleH2';
import CardService from '../../components/CardService/CardService';
import CardImage1 from '../../images/serv-item1.jpg';
import CardImage2 from '../../images/serv-item2.jpg';
import CardImage3 from '../../images/serv-item3.jpg';
import CardImage4 from '../../images/serv-item4.jpg';
import CardMobImage1 from '../../images/serv-item1-mob.jpg';
import CardMobImage2 from '../../images/serv-item2-mob.jpg';
import CardMobImage3 from '../../images/serv-item3-mob.jpg';
import CardMobImage4 from '../../images/serv-item4-mob.jpg';

const Services = (props) => {
	const list = [
		{
			id: 1,
			src: CardImage1,
			srcMob: CardMobImage1,
			alt: 'image1',
			classH3: 'lng-home_service1_h3',
			classP: 'lng-home_service1_p',
			linkItem: `/${props.locale}/ground`,
			color: '#0082ca',
			classBtn: 'lng-home_service1_btn'
		},
		{
			id: 2,
			src: CardImage2,
			srcMob: CardMobImage2,
			alt: 'image2',
			classH3: 'lng-home_service2_h3',
			classP: 'lng-home_service2_p',
			linkItem: `/${props.locale}/sowing`,
			color: '#00af41',
			classBtn: 'lng-home_service2_btn'
		},
		{
			id: 3,
			src: CardImage3,
			srcMob: CardMobImage3,
			alt: 'image3',
			classH3: 'lng-home_service3_h3',
			classP: 'lng-home_service3_p',
			linkItem: `/${props.locale}/protection`,
			color: '#bc6124',
			classBtn: 'lng-home_service3_btn'
		},
		{
			id: 4,
			src: CardImage4,
			srcMob: CardMobImage4,
			alt: 'image4',
			classH3: 'lng-home_service4_h3',
			classP: 'lng-home_service4_p',
			linkItem: `/${props.locale}/complex`,
			color: '#ffb700',
			classBtn: 'lng-home_service4_btn'
		}
	]
	
	return (
		<section id={'services'} className={'services'}>
			<div className="container">
				<div className="wrap">
					<TitleH2 h2Class={'lng-home_services_h2'}/>
					<div className="wrapper">
						{list.map(item =>
							<CardService
								key={item.id}
								src={item.src}
								srcMob={item.srcMob}
								alt={item.alt}
								classH3={item.classH3}
								classP={item.classP}
								link={item.linkItem}
								color={item.color}
								classBtn={item.classBtn}
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;

import React, {useState} from 'react';
import Logo from '../Logo/Logo';
import NavBar from '../NavBar/NavBar';
import LangButtons from '../LangButtons/LangButtons';
import BurgerIcon from '../BurgerIcon/BurgerIcon';

import './Header.scss'

const Header = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	
	const toggleMenu = () => {
		setIsOpen(isOpen => (!isOpen))
	}
	
	const closeMenu = () => {
		setIsOpen(isOpen => (false))
	}
	
	return (
		<header className="header">
			<div className="wrap">
				<div className="container">
					<Logo {...props} />
					
					{isOpen && <div className="navigate">
						<NavBar onClick={closeMenu} {...props}/>
					</div>}
					
					<div className="navigation">
						<NavBar onClick={null} {...props}/>
					</div>
					
					<BurgerIcon click={toggleMenu} classes={isOpen ? 'active' : ''}/>
					
					<LangButtons/>
				</div>
			</div>
		</header>
	);
};

export default Header;

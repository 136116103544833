import React from 'react';
import Btn from '../../components/Btn/Btn';
import Picture from '../../components/Picture/Picture';
import TitleH1 from '../../components/Title/TitleH1';
import TitleH3 from '../../components/Title/TitleH3';
import './OfferRadius.scss';

const OfferRadius = (props) => {
	return (
		<section id="offer-radius" className="offer-radius">
			<div className="offer-radius-bg">
				<Picture classes={props.classImgMax} src={props.srcMax} alt={props.altMax}/>
				<Picture classes={props.classImgMin} src={props.srcMin} alt={props.altMin}/>
				<div className="offer-radius-bg-radar">
					<div className="wrap">
						<div className="circle-line"/>
						<div className="circle-fill"/>
						<div className="circle-center"/>
					</div>
				</div>
			</div>
			<div className="offer-radius-wrap">
				<div className="container">
					<div className="offer-radius-wrap-title">
						<TitleH1 {...props} />
						<TitleH3 {...props} />
						<Btn {...props} />
					</div>
					{props.children}
				</div>
			</div>
		</section>
	);
};

export default OfferRadius;

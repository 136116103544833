import React, {useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {contextArrHeader} from '../../db/db_header';
import './NavBar.scss'

function NavBar(props) {
	let {locale} = props;
	let location = useLocation();
	
	const navItems = [
		{
			id: 1,
			url: `/${locale}/`,
			classes: (location.pathname === '/ru/' || location.pathname === '/ua/') ? 'lng-menu1 active' : 'lng-menu1',
			exact: true
		},
		{id: 2, url: 'ground', classes: 'lng-menu2', exact: false},
		{id: 3, url: 'sowing', classes: 'lng-menu3', exact: false},
		{id: 4, url: 'protection', classes: 'lng-menu4', exact: false},
		{id: 5, url: 'complex', classes: 'lng-menu5', exact: false}
	]
	
	useEffect(() => {
		for (let key in contextArrHeader) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrHeader[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<nav className="menu">
			<ul>
				{navItems.map(item => (
					<li key={item.id} onClick={props.onClick}>
						<NavLink exact={item.exact} to={item.url} className={item.classes}/>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default NavBar;

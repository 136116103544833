export const contextArrSowing = {
	// Page: Sowing - Посівні роботи
	sowing_offer_h1: {
		ua: 'Посівні роботи',
		ru: 'Посевные работы',
	},
	sowing_offer_h3: {
		ua: 'Посів насіння з одночасним внесенням стартових добрив: сухих і рідких',
		ru: 'Посев семян с одновременным внесением стартовых удобрений: сухих и жидких',
	},
	sowing_offer_btn: {
		ua: 'Замовити послугу',
		ru: 'Заказать услугу',
	},
	
	// Principles
	sowing_principles_h2: {
		ua: 'В роботі застосовуємо метод точного землеробства',
		ru: 'В работе применяем метод точного земледелия',
	},
	sowing_principles1_h3: {
		ua: 'Робимо точні заміри поля',
		ru: 'Делаем точные замеры поля',
	},
	sowing_principles_span1: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5см',
	},
	sowing_principles2_h3: {
		ua: 'Об\'єднуємо системи контролю',
		ru: 'Объединяем системы контроля',
	},
	sowing_principles_span2: {
		ua: 'Бездротова система контролю інформує спеціалістів на всіх етапах посіву, а саме - точність викладки насіння, норму посіву, прямолінійність руху агрегата',
		ru: 'Беспроводная система контроля информирует специалистов на всех этапах посева, а именно – точность выкладки семян, норму посева, прямолинейность движения агрегата',
	},
	sowing_principles3_h3: {
		ua: 'Користуємося платним сигналом RTK',
		ru: 'Пользуемся платным сигналом RTK',
	},
	sowing_principles_span3: {
		ua: 'Ми отримуємо мінімальну погрішність +- 2,5 см. Це в 10 разів ефективніше ніж при використанні безкоштовного сигналу (коли погрішність може бути +- 30 см)',
		ru: 'Мы получаем минимальную погрешность +- 2,5 см. Это в 10 раз эффективнее, чем при использовании бесплатного сигнала (когда погрешность может быть +- 30 см)',
	},
	
	// Success
	sowing_success_h2: {
		ua: 'Таким чином ми дотримуємося найважливіших критеріїв якості',
		ru: 'Таким образом мы придерживаемся важнейших критериев качества',
	},
	sowing_success_p1: {
		ua: '<b>Повністю контролюємо</b> всі переміщення насіння: від процесу дозування до закладення в грунт',
		ru: '<b>Полностью контролируем</b> все перемещения семян: от процесса дозировки до заделки в грунт',
	},
	sowing_success_p2: {
		ua: 'GPS Navigation System - сигнал бачить і ці тонкощі, так <b>підвищується точність руху</b> агрегата',
		ru: 'GPS Navigation System - сигнал видит и эти тонкости, так <b>повышается точность движения</b> агрегата',
	},
	sowing_success_p3: {
		ua: 'Ми не сіємо там, де вже посіяно. <b>На основі даних GPS</b> відключаються секції сівалки на перекриттях',
		ru: 'Мы не сеем там, где уже посеяно. <b>На основе данных GPS</b> отключаются секции сеялки на перекрытиях',
	},
	
	// Grows
	sowing_grows_h2: {
		ua: 'Для тих, хто засіває',
		ru: 'Для тех, кто засевает',
	},
	sowing_grows_p1: {
		ua: 'Працюємо по технології точного землеробства',
		ru: 'Работаем по технологии точного земледелия',
	},
	sowing_grows_p2: {
		ua: 'Заміряємо поле і створюємо електронну карту',
		ru: 'Замеряем поле и создаем электронную карту',
	},
	sowing_grows_p3: {
		ua: 'Формуємо карту посіву виходячи з даних NDVI та картографії врожайності',
		ru: 'Формируем карту посева исходя из данных NDVI и картографии урожайности',
	},
	sowing_grows_p4: {
		ua: 'Формуємо карту внесення добрив, виходячи з даних аналізів NPK',
		ru: 'Формируем карту внесения удобрений, исходя из данных анализов NPK',
	},
	sowing_grows_p5: {
		ua: 'Працюємо з платним сигналом RTK, для виконання точності робіт',
		ru: 'Работаем с платным сигналом RTK, для выполнения точности работ',
	},
	sowing_grows_p6: {
		ua: 'Контролюємо результати з висоти польоту (агроскаутинг дронами)',
		ru: 'Контролируем результаты с высоты полета (агроскаутинг дронами)',
	},
	sowing_grows_p7: {
		ua: 'Маємо повний набір техніки: від борони до безпілотних літальних апаратів, для виконання сучасних рішень.',
		ru: 'Есть полный набор техники: от бороны до беспилотных летательных аппаратов, для выполнения современных решений.',
	},
	sowing_grows_p8: {
		ua: 'Робимо агрооперації, з одночасним внесенням добрив.',
		ru: 'Проводим агрооперации, с одновременным внесением удобрений.',
	},
	
	// Slider ParkTechnics
	sowing_park_technics_h2: {
		ua: 'Для обробки поля використовуємо парк техніки',
		ru: 'Для обработки поля используем парк техники',
	},
	sowing_sl0_h3: {
		ua: 'Dorez RT-354',
		ru: 'Dorez RT-354',
	},
	sowing_sl0_h4: {
		ua: 'Протруювальний комплекс',
		ru: 'Протравочный комплекс',
	},
	sowing_sl0_p1: {
		ua: 'Маса: <b>6300 кг</b>',
		ru: 'Масcа: <b>6300 кг</b>',
	},
	sowing_sl0_p2: {
		ua: 'Продуктивність: <b>5.5 т/г</b>',
		ru: 'Производительность: <b>5.5 т/ч</b>',
	},
	sowing_sl0_p3: {
		ua: 'Цілісність сепарації: <b>98%</b>',
		ru: 'Целостность сепарации: <b>98%</b>',
	},
	sowing_sl0_p4: {
		ua: 'Рівномірність: <b>96%</b>',
		ru: 'Равномерность: <b>96%</b>',
	},
	sowing_sl0_p5: {
		ua: 'Комплекс DOREZ камерного типу (пряме уприскування в потік насіння як опція) очищує, калібрує та протруює зерна. Має додатково до протруювальної машини очисний сепаратор. Може обробляти насіння одночасно двома діючими речовинами. Переміщує посівний матеріал не традиційними шнеками, а норіями у спеціальному виконанні',
		ru: 'Комплекс DOREZ камерного типа (прямой впрыск в поток семян как опция) очищает, калибрует и протравливает зерна. Имеет дополнительно протравительную машину очистительный сепаратор. Может обрабатывать семена одновременно двумя действующими веществами. Перемещение посевного материала не традиционными шнеками, а нориями в специальном исполнении',
	},
	sowing_sl1_h3: {
		ua: 'Kello Bilt 225 TSW',
		ru: 'Kello Bilt 225 TSW',
	},
	sowing_sl1_h4: {
		ua: 'Важка дискова борона',
		ru: 'Тяжелая дисковая борона',
	},
	sowing_sl1_p1: {
		ua: 'Маса борони: <b>8000 кг</b>',
		ru: 'Масcа бороны: <b>8000 кг</b>',
	},
	sowing_sl1_p2: {
		ua: 'Робоча глибина: <b>50-250 мм</b>',
		ru: 'Рабочая глубина: <b>50-250 мм</b>',
	},
	sowing_sl1_p3: {
		ua: 'Діаметр дисків: <b>710 мм</b>',
		ru: 'Диаметр дисков: <b>710 мм</b>',
	},
	sowing_sl1_p4: {
		ua: 'Ширина захвату: <b>5.5 м</b>',
		ru: 'Ширина захвата: <b>5.5 м</b>',
	},
	sowing_sl1_p5: {
		ua: 'Розроблена для використання в екстремальних умовах. Завдяки високій потужності підходить для оброблення культур з міцним кореневищем, подрібнення кукурудзяного стеблостою, закладання великої кількості поживних залишків',
		ru: 'Разработана для использования в экстремальных условиях. Благодаря высокой мощности подходит для обработки культур с крепким корневищем, измельчения кукурузного стеблестоя, закладки большого количества питательных остатков',
	},
	sowing_sl2_h3: {
		ua: 'Horsch Joker 8 RT',
		ru: 'Horsch Joker 8 RT',
	},
	sowing_sl2_h4: {
		ua: 'Компактна дискова борона',
		ru: 'Компактная дисковая борона',
	},
	sowing_sl2_p1: {
		ua: '<b>60 дисків</b> в два ряди',
		ru: '<b>60 дисков</b> в два ряда',
	},
	sowing_sl2_p2: {
		ua: 'Робоча глибина: <b>30-150 мм</b>',
		ru: 'Рабочая глубина: <b>30-150 мм</b>',
	},
	sowing_sl2_p3: {
		ua: 'Діаметр дисків: <b>520 мм</b>',
		ru: 'Диаметр дисков: <b>520 мм</b>',
	},
	sowing_sl2_p4: {
		ua: 'Ширина захвату: <b>8 м</b>',
		ru: 'Ширина захвата: <b>8 м</b>',
	},
	sowing_sl2_p5: {
		ua: 'Розпушує верхній шар грунту<br>Подрібнює і перемішує пожнивні залишки<br>Вирівнює поверхню грунту<br>Підходить для передпосівного обробітку<br>Можна використовувати на полях з великою кількістю рослинних залишків',
		ru: 'Разрыхляет верхний шар грунта<br>Измельчает и перемешивает пожнивные остатки<br>Выравнивает поверхность почвы<br>Подходить для предпосевной обработки<br>Можно использовать на полях с большим количеством растительных остатков',
	},
	sowing_sl3_h3: {
		ua: 'Schulte 5026',
		ru: 'Schulte 5026',
	},
	sowing_sl3_h4: {
		ua: 'Мульчувач',
		ru: 'Мульчирователь',
	},
	sowing_sl3_p1: {
		ua: 'Min висота зрізу: <b>25 мм</b>',
		ru: 'Min высота среза: <b>25 мм</b>',
	},
	sowing_sl3_p2: {
		ua: 'Ширина захвату: <b>8 м</b>',
		ru: 'Ширина захвата: <b>8 м</b>',
	},
	sowing_sl3_p3: {
		ua: 'Діаметр дисків: <b>420 мм</b>',
		ru: 'Диаметр дисков: <b>420 мм</b>',
	},
	sowing_sl3_p4: {
		ua: 'Робоча висота: <b>30-380 мм</b>',
		ru: 'Рабочая высота: <b>30-380 мм</b>',
	},
	sowing_sl3_p5: {
		ua: 'Скошує різні види стерні – кукурудзи, ріпаку, соняшника, пшениці<br>Рівномірно подрібнює пожнивні залишки<br>Рівномірно розподіляє подрібнену масу  по всій ширині захвату<br>Повторює рельєф поля',
		ru: 'Скашивает различные виды стерни – кукурузы, рапса, подсолнечника, пшеницы<br>Равномерно измельчает пожнивные остатки<br>Равномерно распределяет измельченную массу по всей ширине захвата<br>Повторяет рельеф поля',
	},
	sowing_sl4_h3: {
		ua: 'Gregoire Besson DXRV',
		ru: 'Gregoire Besson DXRV',
	},
	sowing_sl4_h4: {
		ua: 'Дискова борона',
		ru: 'Дисковая борона',
	},
	sowing_sl4_p1: {
		ua: '<b>36 дисків</b> в два ряди',
		ru: '<b>36 дисків</b> в два ряда',
	},
	sowing_sl4_p2: {
		ua: '<b>9 лап</b> в два ряда',
		ru: '<b>9 лап</b> в два ряда',
	},
	sowing_sl4_p3: {
		ua: 'Ширина захвату: <b>4.7 м</b>',
		ru: 'Ширина захвата: <b>4.7 м</b>',
	},
	sowing_sl4_p4: {
		ua: 'Робоча глибина лап: <b>до 450 мм</b>',
		ru: 'Рабочая глубина лап: <b>до 450 мм</b>',
	},
	sowing_sl4_p5: {
		ua: 'Розпушує верхній шар грунту<br>Відновлює водо- і повітропроникність глибоких шарів<br>Подрібнює і перемішує пожнивні залишки<br>Вирівнює поверхню грунту',
		ru: 'Разрыхляет верхний слой почвы<br>Восстанавливает водо- и воздухопроницаемость глубоких слоев<br>Измельчает и перемешивает пожнивные остатки<br>Выравнивает поверхность почвы',
	},
	sowing_sl5_h3: {
		ua: 'Case IH Tiger Mate',
		ru: 'Case IH Tiger Mate',
	},
	sowing_sl5_h4: {
		ua: 'Культиватор',
		ru: 'Культиватор',
	},
	sowing_sl5_p1: {
		ua: 'Робоча глибина: <b>до 150 мм</b>',
		ru: 'Рабочая глубина: <b>до 150 мм</b>',
	},
	sowing_sl5_p2: {
		ua: 'Діаметр колес: <b>435 мм</b>',
		ru: 'Диаметр колес: <b>435 мм</b>',
	},
	sowing_sl5_p3: {
		ua: 'Ширина захвату: <b>12.2 м</b>',
		ru: 'Ширина захвата: <b>12.2 м</b>',
	},
	sowing_sl5_p4: {
		ua: 'Робоча глибина: <b>до 450 мм</b>',
		ru: 'Рабочая глубина: <b>до 450 мм</b>',
	},
	sowing_sl5_p5: {
		ua: 'Розпушує верхній шар грунту<br>Вирівнює поверхню поля<br>Ефективно зрізає бур\'яни<br>Рівномірно розподіляє рослинну масу по всій ширині захвату',
		ru: 'Разрыхляет верхний слой почвы<br>Выравнивает поверхность поля<br>Эффективно срезает сорняки<br>Равномерно распределяет растительную массу по всей ширине захвата',
	},
	sowing_sl6_h3: {
		ua: 'Strom',
		ru: 'Strom',
	},
	sowing_sl6_h4: {
		ua: 'Глибокорозпушувач',
		ru: 'Глубокорыхлитель',
	},
	sowing_sl6_p1: {
		ua: 'Робоча глибина: <b>250-500 мм</b>',
		ru: 'Рабочая глубина: <b>250-500 мм</b>',
	},
	sowing_sl6_p2: {
		ua: '<b>7 лап</b> в два ряда',
		ru: '<b>7 лап</b> в два ряда',
	},
	sowing_sl6_p3: {
		ua: 'Ширина захвату: <b>3 м</b>',
		ru: 'Ширина захвата: <b>3 м</b>',
	},
	sowing_sl6_p4: {
		ua: 'Маса: <b>770 кг</b>',
		ru: 'Масcа: <b>770 кг</b>',
	},
	sowing_sl6_p5: {
		ua: 'Розпушує глибокі шари грунту<br>Відновлює їх водо- і повітропроникніст, руйнує підплужну підошву<br>Попереджує ерозію грунту',
		ru: 'Разрыхляет глубокие слои почвы<br>Восстанавливает их водо- и воздухопроницаемость, разрушает подплужную подошву<br>Предупреждает эрозию почвы',
	},
	sowing_sl7_h3: {
		ua: 'DN-224',
		ru: 'DN-224',
	},
	sowing_sl7_h4: {
		ua: 'Розкидач добрив',
		ru: 'Разбрасыватель удобрений',
	},
	sowing_sl7_p1: {
		ua: 'Робоча ширина: <b>12-24 м</b>',
		ru: 'Рабочая ширина: <b>12-24 м</b>',
	},
	sowing_sl7_p2: {
		ua: 'Загальна ширина: <b>2.36 м</b>',
		ru: 'Общая ширина: <b>2.36 м</b>',
	},
	sowing_sl7_p3: {
		ua: 'Ємність ящика: <b>900-2100 л</b>',
		ru: 'Емкость ящика: <b>900-2100 л</b>',
	},
	sowing_sl7_p4: {
		ua: 'Маса: <b>350 кг</b>',
		ru: 'Масcа: <b>350 кг</b>',
	},
	sowing_sl7_p5: {
		ua: 'Розкидання на кордоні поля під контролем системи ECOBORD і дефлектора половини ширини (стандартна комплектація) або TRIBORD (на замовлення)',
		ru: 'Разбрасывание на границе поля под контролем системы ECOBORD и дефлектора половины ширины (стандартная комплектация) или TRIBORD (под заказ)',
	},
	
	// Banner #1
	sowing_banner_title: {
		ua: 'Повний комплекс обробки поля',
		ru: 'Полный комплекс обработки поля',
	},
	sowing_banner_p: {
		ua: 'Підготовка насіння на мобільних комплексах з функцією очистки і протруювання',
		ru: 'Подготовка семян на мобильных комплексах с функцией очистки и протравливания',
	},
	sowing_banner_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Slider Brands
	sowing_technics_h2: {
		ua: 'Наша техніка',
		ru: 'Наша техника',
	},
	sowing_technics_p: {
		ua: 'Парк <b>більше 30 одиниць техніки</b>, для виконання всіх виробничих агрооперацій. Серед них такі бренди: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
		ru: 'Парк <b>более 30 единиц техники</b>, для выполнения всех производственных агроопераций. Среди них такие бренды: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE',
	},
	
	// Benefits John Deere
	sowing_benefits_john_deere_h2: {
		ua: 'Головні переваги John Deere',
		ru: 'Главные преимущества John Deere',
	},
	sowing_benefits_john_deere1_h3: {
		ua: 'Найкращі пропозиції щодо фінансування',
		ru: 'Самые лучшие предложения по финансированию',
	},
	sowing_benefits_john_deere_p1: {
		ua: 'Гнучкі фінансові рішення: настільки ж <b>індивідуальні, як ви і ваш бізнес</b>, ваше правильне рішення для організації руху готівки - це всього лише дзвінок або візит до нас',
		ru: 'Гибкие финансовые решения: настолько же <b>индивидуальные, как вы и ваш бизнес</b>, ваше правильное решение для организации движения денежной наличности — это всего лишь звонок или визит к нам',
	},
	sowing_benefits_john_deere2_h3: {
		ua: 'Серія T-видатна продуктивність',
		ru: 'Серия T—выдающаяся производительность',
	},
	sowing_benefits_john_deere_p2: {
		ua: 'Революційна система обмолоту <b>Tangential Plus</b> забезпечує чудову якість зерна та соломи, а також високоекономічну витрату палива. Сепаратор <b>Tangetial Plus</b> має більше отворів, повністю округлий профіль дротів та поперечних планок',
		ru: 'Революционная система обмолота <b>Tangential Plus</b> обеспечивает превосходное качество зерна и соломы, а также высокоэкономичный расход топлива. Сепаратор <b>Tangetial Plus</b> имеет больше отверстий, полностью округлый профиль проволок и поперечных планок',
	},
	sowing_benefits_john_deere3_h3: {
		ua: 'Серія S–адаптивна автоматизації',
		ru: 'Серия S—адаптивная автоматизации',
	},
	sowing_benefits_john_deere_p3: {
		ua: 'Один із найбільш автоматизованих комбайнів на сучасному ринку. Для модельного 2022 року ми запровадимо <b>кілька нових доповнень</b> для підвищення продуктивності збирання врожаю та якості зерна',
		ru: 'Один из самых автоматизированных комбайнов на современном рынке. Для модельного 2022 года мы введем <b>несколько новых дополнений</b> для повышения производительности уборки урожая и качества зерна',
	},
	sowing_benefits_john_deere4_h3: {
		ua: 'Гарантія впевненості як зерно',
		ru: 'Гарантия уверенности в качестве зерна',
	},
	sowing_benefits_john_deere_p4: {
		ua: 'Завдяки нещодавно проведеному в John Deere внутрішньому випробуванню ми впевнені як зерно <b>машин серії S700</b>. Ми впевнені, що ви зможете досягти показника <1% подрібненого зерна',
		ru: 'Благодаря недавно проведенному в John Deere внутреннему испытанию мы уверены в качестве зерна <b>машин серии S700</b>. Мы уверены, что вы сможете достичь показателя <1% дробленого зерна',
	},
	
	// Benefits Claas
	sowing_benefits_claas_h2: {
		ua: 'Головні переваги Claas',
		ru: 'Главные преимущества Claas',
	},
	sowing_benefits_claas_li1: {
		ua: 'В конструкцію популярних моделей Claas входить надійний дизельний двигун <b>Daimler Chrysler, Caterpillar та Mercedes-Benz</b> з регульованими оператором режимами роботи та турбонаддувом. Двигуни мають солідний запас потужності і простоту в ремонті',
		ru: 'В конструкцию популярных моделей Claas входит надежный дизельный двигатель марки <b>Daimler Chrysler, Caterpillar и Mercedes-Benz</b> с регулируемыми оператором режимами работы и турбонаддувом. Двигатели обладают солидным запасом мощности и простотой в ремонте',
	},
	sowing_benefits_claas_li2: {
		ua: 'Комбайни Клаас обладнані якісним молотильним механізмом APS та барабаном прискорювачем. Посилена <b>система очищення зерна Jetstream</b> показує високу ефективність навіть при збиранні врожаю підвищеної вологості',
		ru: 'Комбайны Claas оборудованы качественным молотильным механизмом APS и барабаном ускорителем. Усиленная <b>система очистки зерна Jetstream</b> показывает высокую эффективность даже при уборке урожая повышенной влажности',
	},
	sowing_benefits_claas_li3: {
		ua: 'За управління поворотною рамою відповідає нова технологія компанії Claas – <b>система Multi Contour</b>. Вона дозволяє регулювати висоту зрізу стебел залежно від положення, в якому знаходяться мости. Це дає змогу збирати врожай на схилах',
		ru: 'За управление поворотной рамой отвечает новейшая разработка компании Claas – <b>система Multi Contour</b>. Она позволяет регулировать высоту среза стеблей в зависимости от положения, в котором пребывают мосты. Это дает возможность собирать урожай на склонах',
	},
	sowing_benefits_claas_li4: {
		ua: 'Обмолот стебел гарантує <b>сучасний молотильний барабан APS</b>. Бортова автоматика дає самостійно підбирає оптимальну швидкість обертання барабана, залежно від типу та вологості врожаю',
		ru: 'Обмолот стеблей гарантирует <b>современный молотильный барабан APS</b>. Бортовая автоматика дает самостоятельно подбирает оптимальную скорость вращения барабана, в зависимости от типа и влажности урожая',
	},
	
	// Slider Park Seeding
	sowing_park_seeding_h2: {
		ua: 'Парк сівалок',
		ru: 'Парк сеелок',
	},
	sowing_park_seeding_sl1_h3: {
		ua: 'Väderstad Tempo TPV 12',
		ru: 'Väderstad Tempo TPV 12',
	},
	sowing_park_seeding_sl1_h4: {
		ua: 'Cіялка',
		ru: 'Сеелка',
	},
	sowing_park_seeding_sl1_p1: {
		ua: 'Кількість рядів: <b>8/12</b>',
		ru: 'Количество рядов: <b>8/12</b>',
	},
	sowing_park_seeding_sl1_p2: {
		ua: 'Бункер: <b>2200 л</b>',
		ru: 'Бункер: <b>2200 л</b>',
	},
	sowing_park_seeding_sl1_p3: {
		ua: 'Робоча ширина: <b>5.6 м</b>',
		ru: 'Рабочая ширина: <b>5.6 м</b>',
	},
	sowing_park_seeding_sl1_p4: {
		ua: 'Швидкiсть посiву: <b>20 км/г</b>',
		ru: 'Скорость посева: <b>20 км/ч</b>',
	},
	sowing_park_seeding_sl1_p5: {
		ua: 'Навісна високошвидкісна сівалка Tempo V доступна з  6 до 12 рядковим типорозміром, у тому числі непарною кількістю висівних секцій. Один з можливих варіантів сівби полягає в тому, щоб наприклад, розпочати сезон із сівби цукрових буряків за допомогою 12-ти рядної сівалки із шириною міжряддя 450 мм, потім переобладнати сівалку на 8-ми рядну та продовжити сівбу кукурудзи із міжряддями 700 мм',
		ru: 'Навесная высокоскоростная сеялка Tempo V доступна с 6 до 12 строчным типоразмером, в том числе нечетным количеством высевных секций. Один из возможных вариантов сева состоит в том, чтобы, например, начать сезон с сева сахарной свеклы с помощью 12-ти рядной сеялки с шириной междурядья 450 мм, затем переоборудовать сеялку на 8-ми рядную и продолжить сев кукурузы с междурядьями 700 мм',
	},
	sowing_park_seeding_sl2_h3: {
		ua: 'Horsch Pronto 6 DC',
		ru: 'Horsch Pronto 6 DC',
	},
	sowing_park_seeding_sl2_h4: {
		ua: 'Cіялка',
		ru: 'Сеелка',
	},
	sowing_park_seeding_sl2_p1: {
		ua: 'Кількість рядів: <b>40</b>',
		ru: 'Количество рядов: <b>40</b>',
	},
	sowing_park_seeding_sl2_p2: {
		ua: 'Бункер: <b>3500 л</b>',
		ru: 'Бункер: <b>3500 л</b>',
	},
	sowing_park_seeding_sl2_p3: {
		ua: 'Робоча ширина: <b>6 м</b>',
		ru: 'Рабочая ширина: <b>6 м</b>',
	},
	sowing_park_seeding_sl2_p4: {
		ua: 'Швидкiсть посiву: <b>15 км/г</b>',
		ru: 'Скорость посева: <b>15 км/ч</b>',
	},
	sowing_park_seeding_sl2_p5: {
		ua: 'Універсальний посівний комплекс для роботи в будь-яких умовах після плуга або дискової борони. Принцип Pronto – підготовка насіннєвого ложа, ущільнення, посів, коткування – дозволяє точно викладати посівний матеріал у будь-яких умовах з високою робочою швидкістю',
		ru: 'Универсальный посевной комплекс для работы в любых условиях после плуга или дисковой бороны. Принцип Pronto – подготовка семенного ложа, уплотнения, посев, катка – позволяет точно выкладывать посевной материал в любых условиях с высокой рабочей скоростью',
	},
	sowing_park_seeding_sl3_h3: {
		ua: 'Väderstad Tempo TPV 8',
		ru: 'Väderstad Tempo TPV 8',
	},
	sowing_park_seeding_sl3_h4: {
		ua: 'Cіялка',
		ru: 'Сеелка',
	},
	sowing_park_seeding_sl3_p1: {
		ua: 'Кількість рядів: <b>8</b>',
		ru: 'Количество рядов: <b>8</b>',
	},
	sowing_park_seeding_sl3_p2: {
		ua: 'Бункер: <b>1700 л</b>',
		ru: 'Бункер: <b>1700 л</b>',
	},
	sowing_park_seeding_sl3_p3: {
		ua: 'Робоча ширина: <b>5.6 м</b>',
		ru: 'Рабочая ширина: <b>5.6 м</b>',
	},
	sowing_park_seeding_sl3_p4: {
		ua: 'Швидкiсть посiву: <b>20 км/г</b>',
		ru: 'Скорость посева: <b>20 км/ч</b>',
	},
	sowing_park_seeding_sl3_p5: {
		ua: 'Високошвидкісна сівалка нового покоління, яка вирізняється точністю і працює вдвічі швидше, ніж традиційні сівалки. Tempo відмінно працює за будь-яких умов  - після традиційної чи мінімальної технології обробітку ґрунту. Результатом є поява рівномірних та дружніх сходів, що забезпечує якнайкращий старт для росту рослин',
		ru: 'Высокоскоростная сеялка нового поколения, которая отличается точностью и работает вдвое быстрее традиционных сеялок. Tempo отлично работает при любых условиях – после традиционной или минимальной технологии обработки почвы. Результатом является появление равномерной и дружеской лестницы, что обеспечивает наилучший старт для роста растений',
	},
	sowing_park_seeding_sl4_h3: {
		ua: 'Väderstad Tempo TPV 12',
		ru: 'Väderstad Tempo TPV 12',
	},
	sowing_park_seeding_sl4_h4: {
		ua: 'Cіялка',
		ru: 'Сеелка',
	},
	sowing_park_seeding_sl4_p1: {
		ua: 'Кількість рядів: <b>12</b>',
		ru: 'Количество рядов: <b>12</b>',
	},
	sowing_park_seeding_sl4_p2: {
		ua: 'Бункер: <b>2200 л</b>',
		ru: 'Бункер: <b>2200 л</b>',
	},
	sowing_park_seeding_sl4_p3: {
		ua: 'Робоча ширина: <b>5.6 м</b>',
		ru: 'Рабочая ширина: <b>5.6 м</b>',
	},
	sowing_park_seeding_sl4_p4: {
		ua: 'Швидкiсть посiву: <b>20 км/г</b>',
		ru: 'Скорость посева: <b>20 км/ч</b>',
	},
	sowing_park_seeding_sl4_p5: {
		ua: 'Один з можливих варіантів сівби полягає в тому, щоб наприклад, розпочати сезон із сівби цукрових буряків за допомогою 12-ти рядної сівалки із шириною міжряддя 450 мм, потім переобладнати сівалку на 8-ми рядну та продовжити сівбу кукурудзи із міжряддями 700 мм і, нарешті, восени переобладнати сівалку знову на 12-ти рядну і висівати ріпак із міжряддям 450 мм',
		ru: 'Один из возможных вариантов сева состоит в том, чтобы, например, начать сезон с сева сахарной свеклы с помощью 12-ти рядной сеялки с шириной междурядья 450 мм, затем переоборудовать сеялку на 8-ми рядную и продолжить сев кукурузы с междурядьями 700 мм и, наконец , осенью переоборудовать сеялку снова на 12-ти рядную и высевать рапс с междурядьем 450 мм',
	},
	sowing_park_seeding_sl5_h3: {
		ua: 'Jоhn Deere D224',
		ru: 'Jоhn Deere D224',
	},
	sowing_park_seeding_sl5_h4: {
		ua: 'Розкидач добрив',
		ru: 'Разбрасыватель удобрений',
	},
	sowing_park_seeding_sl5_p1: {
		ua: 'Робоча ширина: <b>12-24 м</b>',
		ru: 'Рабочая ширина: <b>12-24 м</b>',
	},
	sowing_park_seeding_sl5_p2: {
		ua: 'Бункер: <b>1500 л</b>',
		ru: 'Бункер: <b>1500 л</b>',
	},
	sowing_park_seeding_sl5_p3: {
		ua: 'Загальна ширина: <b>2.36 м</b>',
		ru: 'Общая ширина: <b>2.36 м</b>',
	},
	sowing_park_seeding_sl5_p4: {
		ua: 'Загальна висота: <b>1.58 м</b>',
		ru: 'Общая высота: <b>1.58 м</b>',
	},
	sowing_park_seeding_sl5_p5: {
		ua: 'Розкидання на межі поля: ECOBORD та дефлектор половини ширини (стандартна комплектація) або TRIBORD 2D (на замовлення)',
		ru: 'Разбрасывание на границе поля: ECOBORD и дефлектор половины ширины (стандартная комплектация) или TRIBORD 2D (на заказ)',
	},
	
	// Diagnostics
	sowing_diagnostics_h2: {
		ua: 'В результаті посіву з CAREFIELD ви отримаєте',
		ru: 'В результате посева из CAREFIELD вы получите',
	},
	sowing_diagnostics_h3: {
		ua: 'Важливо враховувати:',
		ru: 'Важно учитывать:',
	},
	sowing_diagnostics_p1: {
		ua: 'Дана послуга платна, але якщо <b>ви вирішите співпрацювати з нами</b>, ми вирахуємо вартість діагностики з вартості комплексу. Якщо у вас є всі необхідні характеристики поля, агротехнічну консультацію можна не проводити',
		ru: 'Данная услуга платная, но если <b>вы решите сотрудничать с нами</b>, мы рассчитаем стоимость диагностики из стоимости комплекса. Если у вас есть все необходимые характеристики поля, агротехническую консультацию можно не проводить',
	},
	sowing_diagnostics_p2: {
		ua: 'Після <b>вивчення вашої анкети або діагностики</b> поля буде зрозуміла точна вартість робіт. Ми пропонуємо 2 варіанти оплати - по факту початку робіт або відстрочка платежу',
		ru: 'После <b>изучения вашей анкеты или диагностики</b> поля будет ясна точная стоимость работ. Мы предлагаем 2 варианта оплаты – по факту начала работ или отсрочка платежа',
	},
	sowing_diagnostics_p3: {
		ua: 'Працюємо з векселями, гарантіями, аграрними розписками. Відправляйте заявку, щоб <b>отримати консультацію</b> або замовити послугу',
		ru: 'Работаем с векселями, гарантиями, аграрными расписками. Отправляйте заявку, чтобы <b>получить консультацию</b> или заказать услугу',
	},
	sowing_diagnostics_desc1: {
		ua: '<b>Tочні дані про рельєф</b> і конфігурацію вашого поля',
		ru: '<b>Tочные данные о рельефе</b> и конфигурации вашего поля',
	},
	sowing_diagnostics_desc2: {
		ua: '<b>Економію палива</b> за рахунок одночасного з посівом внесення добрив',
		ru: '<b>Экономию топлива</b> за счет одновременного с посевом внесения удобрений',
	},
	sowing_diagnostics_desc3: {
		ua: '<b>Зменшення пропусків і перекриттів</b>, завдяки використанню, найсучаснішої техніки',
		ru: '<b>Уменьшение пропусков и перекрытий</b>, благодаря использованию, самой современной техники',
	},
	sowing_diagnostics_desc4: {
		ua: 'Агроскаутінг. <b>Контроль якості посіву</b> і підрахунок сходів, з використанням безпілотних технологій',
		ru: 'Агроскаутинг. <b>Контроль качества посева</b> и подсчет всходов, с использованием беспилотных технологий',
	},
	sowing_diagnostics_desc5: {
		ua: 'Зменшення витрат посівного матеріалу і добрив, <b>завдяки диференційованому посіву</b> з платним RTK сігналом',
		ru: 'Уменьшение расхода посевного материала и удобрений, <b>благодаря дифференцированному посеву</b> с платным RTK сигналом',
	},
	sowing_diagnostics_desc6: {
		ua: 'Відображення всіх виробничіх процесів і їх наслідків on-line. <b>Зробимо консалтинг і допоможемо</b> в підключені фармменеджменту',
		ru: 'Отображение всех производственных процессов и их последствий on-line. <b>Сделаем консалтинг и поможем</b> в подключении фармменеджмента',
	},
	
	// Tariffs
	sowing_tariffs_h2: {
		ua: 'Посів просапний - Vaderstad Tempo',
		ru: 'Посев пропашный - Vaderstad Tempo',
	},
	sowing_tariffs_h3: {
		ua: 'Що входить в пакет',
		ru: 'Что входит в пакет',
	},
	sowing_tariffs_desc1: {
		ua: 'Обміри поля RTK дроном і створення контурів',
		ru: 'Обмеры поля RTK дроном и создание контуров',
	},
	sowing_tariffs_desc2: {
		ua: 'Створення карт ущільнення грунту',
		ru: 'Создание карт уплотнения грунта',
	},
	sowing_tariffs_desc3: {
		ua: 'Подрібнення рослинних решток, Shulte',
		ru: 'Измельчение растительных остатков, Shulte',
	},
	sowing_tariffs_desc4: {
		ua: 'Аналіз грунту (середній зразок поля)',
		ru: 'Анализ почвы (средний образец поля)',
	},
	sowing_tariffs_desc5: {
		ua: 'Аналіз грунту (сітка 20га)',
		ru: 'Анализ грунта (сетка 20га)',
	},
	sowing_tariffs_desc6: {
		ua: 'Аналіз грунту (сітка 10га)',
		ru: 'Анализ грунта (сетка 10га)',
	},
	sowing_tariffs_desc7: {
		ua: 'Створення карт диф. внесення мін. добрив',
		ru: 'Создание карт диф. внесение мин. удобрений',
	},
	sowing_tariffs_desc8: {
		ua: 'Внесення добрив розкидачем (диф.внесення)',
		ru: 'Внесение удобрений разбрасывателем (диф.внесение)',
	},
	sowing_tariffs_desc9: {
		ua: 'Оранка',
		ru: 'Вспашка',
	},
	sowing_tariffs_desc10: {
		ua: 'Комбінований обробіток',
		ru: 'Комбинированная обработка',
	},
	sowing_tariffs_desc11: {
		ua: 'Лущення / культивація',
		ru: 'Шелушение / культивация',
	},
	sowing_tariffs_desc_after: {
		ua: '<sup>*</sup>наповнення кожного вищевказанного варіанту може корегуватися в залежності від умов поля та побажань кліента',
		ru: '<sup>*</sup>наполнение каждого вышеупомянутого варианта может корректироваться в зависимости от условий поля и пожеланий клиента',
	},
	sowing_tariffs_btn: {
		ua: 'Безкоштовна консультація',
		ru: 'Бесплатная консультация',
	},
	
	// Radius
	sowing_radius_h2: {
		ua: 'Пропонуємо гнучкі умови оплати',
		ru: 'Предлагаем гибкие условия оплаты',
	},
	sowing_radius_li1: {
		ua: 'Після <b>вивчення вашої анкети</b> або діагностики поля буде зрозуміла точна вартість робіт',
		ru: 'После <b>изучения вашей анкеты</b> или диагностики поля будет ясна точная стоимость работ',
	},
	sowing_radius_li2: {
		ua: 'Ми пропонуємо <b>два варіанти оплати</b> - по факту початку робіт або відстрочка платежу. Працюємо з векселями, гарантіями, аграрними розписками',
		ru: 'Мы предлагаем <b>два варианта оплаты</b> – по факту начала работ или отсрочка платежа. Работаем с векселями, гарантиями, аграрными расписками',
	},
	sowing_radius_li3: {
		ua: 'Ключові <b>технологічні параметри</b>: перекриття між проходами, швидкість, глибина обробітку',
		ru: 'Ключевые <b>технологические параметры</b>: перекрытие между проходами, скорость, глубина обработки',
	},
	sowing_radius_li4: {
		ua: 'Відправляйте заявку, щоб <b>отримати консультацію</b> або замовити послугу',
		ru: 'Отправляйте заявку, чтобы <b>получить консультацию</b> или заказать услугу',
	},
	
	// Extra
	sowing_extra_h2: {
		ua: 'Додатково ви отримуєте',
		ru: 'Дополнительно вы получаете',
	},
	sowing_extra_p1: {
		ua: 'Контролюємо якість обробітку грунту',
		ru: 'Контролируем качество обработки почвы',
	},
	sowing_extra_p2: {
		ua: 'Працюємо в полі цілодобово',
		ru: 'Работаем в поле круглосуточно',
	},
	sowing_extra_p3: {
		ua: 'Цілодобова робота служби підтримки',
		ru: 'Круглосуточная работа службы поддержки',
	},
	
	// Banner #2
	sowing_banner_title2: {
		ua: 'Повний комплекс обробки поля',
		ru: 'Полный комплекс обработки поля',
	},
	sowing_banner_p2: {
		ua: 'Підготовка насіння на мобільних комплексах з функцією очистки і протруювання',
		ru: 'Подготовка семян на мобильных комплексах с функцией очистки и протравливания',
	},
	sowing_banner_btn2: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
}

export const contextArrNotfound = {
	// Page: Not found
	not_found_offer_h1: {
		ua: 'Page not found!',
		ru: 'Page not found!',
	},
	not_found_offer_h3: {
		ua: 'Сторінка не знайдена!',
		ru: 'Страница не найдена!',
	},
}

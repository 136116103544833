import React from 'react';
import TitleH3 from '../Title/TitleH3';
import Logo from '../Logo/Logo';
import i_phone from '../../images/icons/i-phone.png';
import i_email from '../../images/icons/i-email.png';
import i_time from '../../images/icons/i-time.png';
import logo_fest from '../../images/logo-festival.png';
import i_fb from '../../images/icons/i-fb.png';
import i_youtube from '../../images/icons/i-youtube.png';
import i_insta from '../../images/icons/i-instagram.png';
import CustomLink from '../CustomLink/CustomLink';
import NavBarFooter from '../NavBarFooter/NavBarFooter';
import Picture from '../Picture/Picture';
import {NavLink} from 'react-router-dom';
import BtnScrollUp from '../BtnScrollUp/BtnScrollUp';
import './Footer.scss'

const Footer = (props) => {
	return (
		<footer id="footer" className="footer">
			<div className="footer-up">
				<div className="container">
					<Logo {...props} />
					<div className="wrap">
						
						<div className="wrap-item">
							<TitleH3 h3Class={'lng-footer_title1_h3'}/>
							<ul>
								<CustomLink href={'tel:'} img={i_phone} text={'+38063 813 9531'}/>
								<CustomLink href={'mailto:'} img={i_email} text={'support@carefield.com.ua'}/>
								<CustomLink href={'#'} img={i_time} classes={'lng-footer1_text1'}/>
							</ul>
						</div>
						
						<div className="wrap-item">
							<TitleH3 h3Class={'lng-footer_title2_h3'}/>
							<NavBarFooter {...props}/>
						</div>
						
						<div className="wrap-item">
							<TitleH3 h3Class={'lng-footer_title3_h3'}/>
							<Picture src={logo_fest}/>
							
							<NavLink to={'/' + props.locale + '/festival'} className={'lng-footer_btn btn'}/>
						
						</div>
						
						<div className="wrap-item">
							<TitleH3 h3Class={'lng-footer_title4_h3'}/>
							<ul>
								<CustomLink href={'#'} img={i_fb}/>
								<CustomLink href={'#'} img={i_youtube}/>
								<CustomLink href={'#'} img={i_insta}/>
							</ul>
						</div>
					</div>
				</div>
			</div>
			
			<div className="footer-down">
				<div className="container">
					<div className="wrap">
						<ul>
							<li>
								<NavLink to={'politics'} className={'lng-footer_link1'}/>
							</li>
							<li>
								<NavLink to={'cookies'} className={'lng-footer_link2'}/>
							</li>
							<li>
								<NavLink to={'information'} className={'lng-footer_link3'}/>
							</li>
							<CustomLink href={'#'} text={'Design by Rabbitmarketing'}/>
							<CustomLink href={'https://adbbox.com/'} text={'Development by adbbox.com'}/>
						</ul>
					</div>
				</div>
			</div>
			
			<BtnScrollUp/>
		</footer>
	);
};

export default Footer;

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Slider from '../../components/Slider/Slider';
import './Technics.scss'

const Technics = (props) => {
	return (
		<section id={'technics'} className={'technics'}>
			<div className="container wrap">
				<TitleH2 h2Class={props.classesH2}/>
				<div className="wrapper">
					<div className="left">
						<p className={props.classesP}/>
					</div>
					<div className="right">
						<Slider list={props.list} idsl={props.idsl} el={props.el}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Technics;

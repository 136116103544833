import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Slider from '../../components/Slider/Slider';
import Btn from '../../components/Btn/Btn';

const SliderBox = (props) => {
	return (
		<section id={props.id} className={props.id}>
			<div className="container wrap">
				<div className="wrapper">
					<div className="left">
						<TitleH2 h2Class={props.h2Class}/>
						<p className={props.pClass}/>
						<Btn onClick={props.onClick} btnClass={props.btnClass} styles={{marginTop: 50}}/>
					</div>
					<div className="right">
						<Slider list={props.list} idsl={props.idsl} el={props.el}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SliderBox;

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import SliderBig from '../../components/SliderBig/SlideBig';
import './SliderTitle.scss';

const SliderTitle = (props) => {
	return (
		<section id={props.id}>
			<div className="container wrap">
				<TitleH2 h2Class={props.h2Class}/>
				<SliderBig {...props} />
			</div>
		</section>
	);
};

export default SliderTitle;

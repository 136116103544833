export const contextArrComplex = {
	// Page: Complex
	complex_offer_h1: {
		ua: 'Купівля-продаж<br>сільгосп культур',
		ru: 'Купля-продажа<br>сельхоз культур',
	},
	complex_offer_h3: {
		ua: 'Вирощуємо, збираємо, купуємо урожай з поля, допомагаємо реалізувати з максимальною вигодою',
		ru: 'Выращиваем, собираем, покупаем урожай с поля, помогаем реализовать с максимальной выгодой',
	},
	complex_offer_btn: {
		ua: 'Отримати консультацію',
		ru: 'Заказать консультацию',
	},
	complex_title_h2: {
		ua: 'Натисніть на той варіант, який вас цікавить',
		ru: 'Нажмите на интересующий вас вариант',
	},
	
	// Banner #1 - title
	complex_banner_title1: {
		ua: 'Збір і викуп з поля',
		ru: 'Сбор и выкуп с поля',
	},
	complex_banner_p1: {
		ua: 'Якщо у вас немає власної техніки - організуємо збирання врожаю, оцінимо, і в той же день заплатимо',
		ru: 'Если у вас нет собственной техники – организуем уборку урожая, оценим, и в тот же день заплатим',
	},
	complex_banner_btn1: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Banner #2 - title
	complex_banner_title2: {
		ua: 'Купівля нестандартної продукції',
		ru: 'Покупка нестандартной продукции',
	},
	complex_banner_p2: {
		ua: 'Для нестандартної продукції вивчимо і підберемо покупця',
		ru: 'Для нестандартной продукции изучим и подберем покупателя',
	},
	complex_banner_btn2: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Banner #3 - title
	complex_banner_title3: {
		ua: 'Експорт вашої продукції',
		ru: 'Экспорт вашей продукции',
	},
	complex_banner_p3: {
		ua: 'Виконаємо експорт вашої продукції',
		ru: 'Выполним экспорт вашей продукции',
	},
	complex_banner_btn3: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
	
	// Banner #1 - Panel
	complex_working_h2: {
		ua: 'Збір врожаю і викуп з оплатою за фактом',
		ru: 'Уборка и выкуп с оплатой по факту',
	},
	complex_working1_h3: {
		ua: 'Швидко зберемо врожай',
		ru: 'Быстро соберем урожай',
	},
	complex_working_span1: {
		ua: 'За допомогаю нашого інноваційного технічного парку швидку зберемо врожай',
		ru: 'С помощью нашего инновационного технического парка скорую соберем урожай',
	},
	complex_working2_h3: {
		ua: 'Використовуємо бункер-перевантажувач',
		ru: 'Используем бункер-перегружатель',
	},
	complex_working_span2: {
		ua: 'При збиранні використовуємо бункер-перевантажувач та необхідний технічний парк за рішенням оператора',
		ru: 'При сборке используем бункер-перегрузчик и необходимый технический парк по решению оператора',
	},
	complex_working3_h3: {
		ua: 'Оцінимо об\'єм і оплатимо',
		ru: 'Оценим объем и оплатим',
	},
	complex_working_span3: {
		ua: 'Оцінимо об\'єм і оплатимо 50% в день збору',
		ru: 'Оценим объем и оплатим 50% в день сбора',
	},
	
	// Harvesting
	complex_harvesting_h2: {
		ua: 'Операція відбувається в кілька простих етапів',
		ru: 'Операція відбувається в кілька простих етапів',
	},
	complex_harvesting_p1: {
		ua: 'Оцінка конфігурації поля і швидкості збору',
		ru: 'Оценка конфигурации поля и скорости сбора',
	},
	complex_harvesting_p2: {
		ua: 'Використовуємо власну техніку для збору',
		ru: 'Используем собственную технику для сбора',
	},
	complex_harvesting_p3: {
		ua: 'Ви отримаєте гроші ще до завершення збору',
		ru: 'Вы получите деньги еще до завершения сбора',
	},
	
	// Phases
	complex_phases_h2: {
		ua: 'Перелік робіт збору та викупу врожаю:',
		ru: 'Перечень работ по сбору и выкупу урожая:',
	},
	complex_phases_li1: {
		ua: 'Перш ніж приступати до роботи, ми можемо дати <b>точну оцінку складності збору</b>, розміри поля і зорієнтувати за термінами',
		ru: 'Прежде чем приступать к работе, мы можем дать <b>точную оценку сложности сбора</b>, размеры поля и сориентировать по срокам',
	},
	complex_phases_li2: {
		ua: 'Якщо у вас немає своєї техніки, <b>шукати що-небудь в оренду не доведеться</b> - ми виконаємо збір власною технікою',
		ru: 'Если у вас нет своей техники, <b>искать что-нибудь в аренду не придется</b> – мы выполним сбор собственной техникой',
	},
	complex_phases_li3: {
		ua: 'Вже під час збору ми зможемо оцінити підсумковий обсяг і перевести вам <b>50% вартості з доплатою</b> після приймання на елеватору',
		ru: 'Уже при сборе мы сможем оценить итоговый объем и перевести вам <b>50% стоимости с доплатой</b> после приема на элеватор',
	},
	
	// Reloader Hopper
	complex_reloader_hoppers_h2: {
		ua: 'Під час збору використовуємо бункер-перевантажувач',
		ru: 'Во время сбора используем бункер-перегрузчик',
	},
	complex_reloader_hoppers_li1: {
		ua: '<b>Не потрібно постійно їздити</b> і вивантажувати врожай з комбайна, тому збір відбувається швидше',
		ru: '<b>Не нужно постоянно ездить</b> и выгружать урожай из комбайна, поэтому сбор происходит быстрее',
	},
	complex_reloader_hoppers_li2: {
		ua: 'Бункери не толочить верхній шар поля, <b>зберігаючи придатність грунту</b> для нового посіву',
		ru: 'Бункеры не толочит верхний слой поля, <b>сохраняя пригодность почвы</b> для нового посева',
	},
	complex_reloader_hoppers_li3: {
		ua: 'Проходить навіть в незручні погодні умови і значно <b>знижує % втрати зерна</b>',
		ru: 'Проходит даже в неловкие погодные условия и значительно <b>снижает % потери зерна</b>',
	},
	
	// Payment Harvest
	complex_payment_harvest_h2: {
		ua: 'Оплачуємо 50% обсягу врожаю в день збору',
		ru: 'Оплачиваем 50% объема урожая в день сбора',
	},
	complex_payment_harvest_li1: {
		ua: 'За допомогою перевантажувача зерна ми можемо <b>дати на 90% точний прогноз</b> обсягу врожаю ще до завершення збору',
		ru: 'Посредством перегружателя зерна мы можем <b>дать на 90% точный прогноз</b> объема урожая еще до завершения сбора',
	},
	complex_payment_harvest_li2: {
		ua: 'В цей же день ми вносимо передоплату у розмірі <b>50% від прогнозу, відразу після приймання</b> на елеватор робимо перерахунок і доплачуємо різницю',
		ru: 'В этот же день мы вносим предоплату в размере <b>50% от прогноза, сразу после приема</b> на элеватор производим перерасчет и доплачиваем разницу',
	},
	complex_payment_harvest_li3: {
		ua: '<b>Фіксуємо в договорі ціну</b>, терміни, умови',
		ru: '<b>Фиксируем в договоре цену</b>, сроки, условия',
	},
	complex_payment_harvest_li4: {
		ua: '<b>Платимо 50% в день збору</b> і ще 50% після приймань на елеваторі',
		ru: '<b>Платим 50% в день сбора</b> и еще 50% после приема на элеваторе',
	},
	
	// Non-Standard Quality
	complex_non_standard_quality_h2: {
		ua: 'Також можемо викупити врожай з нестандартною якістю',
		ru: 'Также можем выкупить урожай с нестандартным качеством',
	},
	complex_non_standard_quality_li1: {
		ua: 'Якщо до або в процесі збирання виявилося, що <b>товар не стандартної якості</b>, це не привід опускати руки - ми можемо підібрати покупця',
		ru: 'Если до или в процессе уборки оказалось, что <b>товар не стандартного качества</b>, это не повод опускать руки – мы можем подобрать покупателя',
	},
	complex_non_standard_quality_li2: {
		ua: 'За нашою базою переробних підприємств України і світу, <b>опрацювати можливість продажу</b> по найбiльш цікавою ціною',
		ru: 'По нашей базе перерабатывающих предприятий Украины и мира, <b>разработать возможность продажи</b> по наиболее интересной цене',
	},
	
	// List Non-Standard Quality
	complex_non_standard_quality2_h2: {
		ua: 'Як це відбувається',
		ru: 'Как это происходит',
	},
	complex_non_standard_quality_p1: {
		ua: 'Вивчаємо обсяг і характеристики <b>нестандартної продукції</b>',
		ru: 'Изучаем объем и характеристики <b>нестандартной продукции</b>',
	},
	complex_non_standard_quality_p2: {
		ua: 'За своїй основі контактів <b>шукаємо потенційних клієнтів</b> на переробку',
		ru: 'По своей основе контактов <b>ищем потенциальных клиентов</b> для переработки',
	},
	complex_non_standard_quality_p3: {
		ua: 'Обговорюємо з покупцем <b>оптимальні умови для реалізації</b> нестандартної продукції',
		ru: 'Обсуждаем с покупателем <b>оптимальные условия для реализации</b> нестандартной продукции',
	},
	complex_non_standard_quality_p4: {
		ua: 'З отриманої інформації формуємо <b>комерційну пропозицію</b> аграрію',
		ru: 'Из полученной информации формируем <b>коммерческое предложение</b> агрария',
	},
	complex_non_standard_quality_p5: {
		ua: '<b>Обговорюємо з аграрієм обсяги</b>, терміни, нашу комісію за послуги, порядок робіт',
		ru: '<b>Обсуждаем с аграрием объемы</b>, сроки, нашу комиссию за услуги, порядок работ',
	},
	complex_non_standard_quality_p6: {
		ua: 'Підписуємо договір і <b>виконуємо послугу</b>',
		ru: 'Подписываем договор и <b>оказываем услугу</b>',
	},
	complex_non_standard_quality_p7: {
		ua: 'Ви оплачуєте <b>тільки нашу комісію</b>. Нам вигідно <b>продати Ваш товар дорого</b>',
		ru: 'Вы оплачиваете <b>только нашу комиссию</b>. Нам выгодно <b>продать Ваш товар дорого</b>',
	},
	
	// Radius
	complex_radius_h2: {
		ua: 'Встигаємо до наших клієнтів завжди і всюди',
		ru: 'Предлагаем гибкие условия оплаты',
	},
	complex_radius_li1: {
		ua: 'Працюємо в Подільському регіоні, в радіусі 200 км від Вінниці',
		ru: 'Работаем в Подольском регионе, в радиусе 200 км от Винницы',
	},
	complex_radius_li2: {
		ua: 'Наша техніка знаходиться на кількох локаціях, щоб ми могли швидко добратися до клієнтів',
		ru: 'Наша техника находится на нескольких локациях, чтобы мы могли быстро добраться до клиентов',
	},
	complex_radius_li3: {
		ua: 'Тому будь-які роботи виконуємо вчасно',
		ru: 'Поэтому любые работы выполняем вовремя',
	},
	complex_radius_li4: {
		ua: 'Проводимо обмір полів з використанням RTK-сигналу з точністю вимірювання +- 2,5 см',
		ru: 'Проводим обмер полей с использованием RTK-сигнала с точностью измерения +- 2,5 см.',
	},
	
	// Banner #3 - Panel
	complex_export_h2: {
		ua: 'Вигідно розподіляємо продаж і виступаємо посередником при експорті',
		ru: 'Выгодно распределяем продажу и выступаем посредником при экспорте',
	},
	complex_export1_h3: {
		ua: 'Експертний розрахунок вашого врожаю',
		ru: 'Экспертный расчет вашего урожая',
	},
	complex_export_span1: {
		ua: 'Порахуємо правильну пропорцію продажу вашого врожаю на експорт і всередині країни',
		ru: 'Считаем правильную пропорцию продажи вашего урожая на экспорт и внутри страны',
	},
	complex_export2_h3: {
		ua: 'Документальне оформлення експорту',
		ru: 'Документальное оформление экспорта',
	},
	complex_export_span2: {
		ua: 'Правильно оформимо всі документи для оптимізації податків і зарахування коштів',
		ru: 'Правильно оформим все документы для оптимизации налогов и зачисления средств',
	},
	complex_export3_h3: {
		ua: 'Повний експортний супровід',
		ru: 'Полное экспортное сопровождение',
	},
	complex_export_span3: {
		ua: 'Повністю супроводимо експорт від пошуку клієнта до оформлення експортних документів, оплати і доставки',
		ru: 'Полностью сопроводим экспорт от поиска клиента к оформлению экспортных документов, оплате и доставке',
	},
	
	// Export2
	complex_export2_h2: {
		ua: 'Операція відбувається в кілька простих етапів',
		ru: 'Операція відбувається в кілька простих етапів',
	},
	complex_export2_p1: {
		ua: 'Фіксуємо в договорі умови продажу і ціну',
		ru: 'Фиксируем в договоре условия продажи и цену',
	},
	complex_export2_p2: {
		ua: 'Оптимізуємо податки і збільшимо чистий прибуток',
		ru: 'Оптимизируем налоги и увеличим чистую прибыль',
	},
	complex_export2_p3: {
		ua: 'Щоб дізнатися точні умови співпраці, передзвоніть нам',
		ru: 'Чтобы узнать точные условия сотрудничества, перезвоните нам',
	},
	
	// Difficulties farmers
	complex_difficulties_farmers_h2: {
		ua: 'Ми розуміємо складнощі фермерів в реалізації зібраного врожаю',
		ru: 'Мы понимаем сложности фермеров в реализации собранного урожая',
	},
	complex_difficulties_farmers_h3: {
		ua: 'Важливо враховувати:',
		ru: 'Важно учитывать:',
	},
	complex_difficulties_farmers_p1: {
		ua: 'Правильно спланувати продаж і забезпечити максимально вигідні умови по чистому прибутку',
		ru: 'Правильно спланировать продажу и обеспечить максимально выгодные условия по чистой прибыли',
	},
	complex_difficulties_farmers_p2: {
		ua: 'Детально розібратися, що і в якому обсязі продати на зовнішньому ринку, а що всередині країни',
		ru: 'Подробно разобраться, что и в каком объеме продать на внешнем рынке, а что внутри страны',
	},
	complex_difficulties_farmers_p3: {
		ua: 'Крім цього є багато незручних "треба" для реалізації продукції на експорт',
		ru: 'Кроме этого есть много неудобных "надо" для реализации продукции на экспорт',
	},
	complex_difficulties_farmers_desc1: {
		ua: '<b>Знайти покупця</b> на обсяг',
		ru: '<b>Найти покупателя</b> на объем',
	},
	complex_difficulties_farmers_desc2: {
		ua: 'Мати <b>акредитацію</b> на елеваторі митниці',
		ru: 'Иметь <b>аккредитацию</b> на элеваторе таможни',
	},
	complex_difficulties_farmers_desc3: {
		ua: 'Правильно <b>оформлені документи</b> на експорт',
		ru: 'Правильно <b>оформленные документы</b> на экспорт',
	},
	complex_difficulties_farmers_desc4: {
		ua: 'Розібратися з <b>логістикою</b> вашего експорту',
		ru: 'Разобраться с <b>логистикой</b> вашего экспорту',
	},
	complex_difficulties_farmers_desc5: {
		ua: 'Правильно <b>працювати</b> з банками',
		ru: 'Правильно <b>работать</b> с банками',
	},
	complex_difficulties_farmers_desc6: {
		ua: 'Роботи пов\'язані з експортом <b>вашим врожаєм</b>',
		ru: 'Работы, связанные с экспортом <b>вашим урожаем</b>',
	},
}

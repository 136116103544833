import React, {useEffect} from 'react';
import {contextArrProtection} from '../../db/db_page_protection';
import {
	listProtectionItemsServices,
	listProtectionPrinciples,
	listProtectionPlant,
	listProtectionItemsTechnology,
	listProtectionPreventionTreatment,
	listProtectionItemsLogistics,
	listProtectionLogisticsWater,
	listProtectionItemsProvideReports,
	listTechnicsProtection,
	listProtectionResult,
	listProtectionItemsResult,
	listTechnicsProtectionBrands,
	listProtectionItemsSuccess
} from '../../db/db_lists_protection';
import {listParkDrons, listProtectionParkTechnics} from '../../db/db_listSliderParkTechnics'
import './Protection.scss';

import offerMax from '../../images/protection/protection-bg.jpg';
import offerMin from '../../images/protection/protection-bg-mob.jpg';
import imagePrinciples from '../../images/ground/principles.jpg';
import imageBanner from '../../images/banner_box12.jpg';
import imageBanner2 from '../../images/banner_box16.jpg';
import imageBanner3 from '../../images/banner_box13.jpg';
import imagePlant from '../../images/protection/plant-bg.jpg';
import imageResult from '../../images/protection/result-bg.jpg';
import imagePreventionTreatment from '../../images/protection/agronomist.jpg';
import imageLogisticsWater from '../../images/protection/water-bg.jpg';

import Offer from '../../sections/Offer/Offer';
import ListImage from '../../sections/ListImage/ListImage';
import ListImageText from '../../sections/ListImageText/ListImageText';
import BannerBox from '../../sections/BannerBox/BannerBox';
import Technics from '../../sections/Technics/Technics';
import SliderTitle from '../../sections/SliderTitle/SliderTitle';

const Protection = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrProtection) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrProtection[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'protection'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-protection_offer_h1'}
				h3Class={'lng-protection_offer_h3'}
				btnClass={'btn lng-protection_offer_btn'}
				onClick={props.onClick}
				{...props}
			/>
			<ListImageText
				h2Class={'lng-protection_services_h2'}
				listItems={listProtectionItemsServices}
			/>
			<ListImage
				id={'principles'}
				lt={1}
				rt={2}
				h2Class={'lng-protection_principles_h2'}
				list={listProtectionPrinciples}
				image={imagePrinciples}
				shadow={''}
			/>
			<BannerBox
				image={imageBanner}
				classesH2={'lng-protection_banner_title'}
				classesP={'lng-protection_banner_p'}
				classesBtn={'lng-protection_banner_btn btn'}
				onClick={props.onClick}
			/>
			<ListImage
				id={'protection-plant'}
				lt={2}
				rt={1}
				h2Class={'lng-protection_plant_h2'}
				image={imagePlant}
				list={listProtectionPlant}
				shadow={'shadow'}
			/>
			<ListImageText
				h2Class={'lng-protection_technology_h2'}
				listItems={listProtectionItemsTechnology}
			/>
			<ListImage
				id={'protection-prevention-treatment'}
				lt={1}
				rt={2}
				h2Class={'lng-protection_prevention_treatment_h2'}
				image={imagePreventionTreatment}
				list={listProtectionPreventionTreatment}
				shadow={''}
			/>
			<ListImageText
				h2Class={'lng-protection_logistics_h2'}
				listItems={listProtectionItemsLogistics}
			/>
			<ListImage
				id={'protection-logistics_water'}
				lt={2}
				rt={1}
				h2Class={'lng-protection_logistics_water_h2'}
				image={imageLogisticsWater}
				list={listProtectionLogisticsWater}
				shadow={''}
			/>
			<ListImageText
				h2Class={'lng-protection_provide_reports_h2'}
				listItems={listProtectionItemsProvideReports}
			/>
			<BannerBox
				image={imageBanner2}
				classesH2={'lng-protection_banner2_title'}
				classesP={'lng-protection_banner2_p'}
				classesBtn={'lng-protection_banner2_btn btn'}
				onClick={props.onClick}
			/>
			<SliderTitle
				id={'protection-drons'}
				idsl={'protection-drons-slider'}
				item={'item'}
				h2Class={'lng-protection_drons_h2'}
				listSl={listParkDrons}
			/>
			<Technics
				idsl={'technics-slider'}
				el={'el'}
				classesH2={'lng-protection_technics_h2'}
				classesP={'lng-protection_technics_p'}
				list={listTechnicsProtection}
			/>
			<SliderTitle
				id={'protection-park-technics'}
				idsl={'protection-park-technics-slider'}
				item={'item'}
				h2Class={'lng-protection_park_technics_h2'}
				listSl={listProtectionParkTechnics}
			/>
			<ListImage
				id={'protection-result'}
				lt={1}
				rt={2}
				h2Class={'lng-protection_result_h2'}
				image={imageResult}
				list={listProtectionResult}
				shadow={''}
			/>
			<ListImageText
				h2Class={'lng-protection_result_desc_h2'}
				listItems={listProtectionItemsResult}
			/>
			<Technics
				idsl={'fertilizers-brands-slider'}
				el={'el'}
				classesH2={'lng-protection_fertilizers_brands_h2'}
				classesP={'lng-protection_fertilizers_brands_p'}
				list={listTechnicsProtectionBrands}
			/>
			<BannerBox
				image={imageBanner3}
				classesH2={'lng-protection_banner3_title'}
				classesP={'lng-protection_banner3_p'}
				classesBtn={'lng-protection_banner3_btn btn'}
				onClick={props.onClick}
			/>
			<ListImageText
				h2Class={'lng-protection_success_h2'}
				listItems={listProtectionItemsSuccess}
			/>
		</main>
	);
};

export default Protection;

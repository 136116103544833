import React, {useEffect} from 'react';
import {contextArrSowing} from '../../db/db_page_sowing';
import {
	listPrinciplesSowing,
	listSowingItemsSuccess,
	listGrowsSowing,
	listTextColBoxSowing,
	listJohnDeere,
	listBenefitsClaas,
	listClaas,
	listSowingSliderTariffs,
	listSowingItemsExtra,
	listSowingRadius
} from '../../db/db_lists_sowing';
import {listParkTechnicsSowing, listParkSeedingSowing} from '../../db/db_listSliderParkTechnics';
import {listTechnics} from '../../db/db_lists_home';

import offerRadiusMax from '../../images/ground/offer-bg-scaled.jpg';
import offerRadiusMin from '../../images/ground/offer-bg-min.jpg';
import imagePrinciples from '../../images/sowing/sec1-bg.jpg';
import imageGrows from '../../images/sec3_bg.jpg';
import imageBanner from '../../images/banner_box6.jpg';
import imageBanner2 from '../../images/banner_box7.jpg';

import imageBenefitsClaas1 from '../../images/sowing/claas1.jpg';
import imageBenefitsClaas2 from '../../images/sowing/claas2.jpg';
import imageBenefitsClaas3 from '../../images/sowing/claas3.jpg';

import iconTitleDiagnostics from '../../images/icons/i-money.jpg';

import OfferRadius from '../../sections/OfferRadius/OfferRadius';
import ListImage from '../../sections/ListImage/ListImage';
import ListImageText from '../../sections/ListImageText/ListImageText';
import SliderTitle from '../../sections/SliderTitle/SliderTitle';
import BannerBox from '../../sections/BannerBox/BannerBox';
import Technics from '../../sections/Technics/Technics';
import TextColBox from '../../sections/TextColBox/TextColBox';
import Slider from '../../components/Slider/Slider';
import ListImages from '../../sections/ListImages/ListImages';

import TextList from '../../sections/TextList/TextList';
import Tariffs from '../../sections/Tariffs/Tariffs';
import imageRadius from '../../images/radius.jpg';

import './Sowing.scss'

const Sowing = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrSowing) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrSowing[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'sowing'}>
			<OfferRadius
				classImgMax={'offer-radius-bg-max'}
				classImgMin={'offer-radius-bg-min dn'}
				srcMax={offerRadiusMax}
				srcMin={offerRadiusMin}
				altMax={'offer-radius-bg-max'}
				altMin={'offer-radius-bg-min'}
				h1Class={'lng-sowing_offer_h1'}
				h3Class={'lng-sowing_offer_h3'}
				btnClass={'btn lng-sowing_offer_btn'}
				onClick={props.onClick}
				{...props}
			>
				<ListImage
					id={'principles'}
					lt={2}
					rt={1}
					h2Class={'lng-sowing_principles_h2'}
					list={listPrinciplesSowing}
					image={imagePrinciples}
					shadow={''}
				/>
			</OfferRadius>
			<ListImageText
				h2Class={'lng-sowing_success_h2'}
				listItems={listSowingItemsSuccess}
			/>
			<ListImage
				id={'grows'}
				lt={1}
				rt={2}
				h2Class={'lng-sowing_grows_h2'}
				list={listGrowsSowing}
				image={imageGrows}
				shadow={''}
			/>
			<SliderTitle
				id={'park-technics'}
				idsl={'park-technics-slider'}
				item={'item'}
				h2Class={'lng-sowing_park_technics_h2'}
				listSl={listParkTechnicsSowing}
			/>
			<BannerBox
				image={imageBanner}
				classesH2={'lng-sowing_banner_title'}
				classesP={'lng-sowing_banner_p'}
				classesBtn={'lng-sowing_banner_btn btn'}
				onClick={props.onClick}
			/>
			<Technics
				idsl={'technics-slider'}
				el={'el'}
				classesH2={'lng-sowing_technics_h2'}
				classesP={'lng-sowing_technics_p'}
				list={listTechnics}
			/>
			<TextColBox
				idsl={'benefits_john_deere'}
				classesH2={'lng-sowing_benefits_john_deere_h2'}
				list={listTextColBoxSowing}
			>
				<Slider idsl={'john-deere-slider'} list={listJohnDeere}/>
			</TextColBox>
			<ListImages
				id={'benefits-claas'}
				classesH2={'lng-sowing_benefits_claas_h2'}
				image1={imageBenefitsClaas1}
				image2={imageBenefitsClaas2}
				image3={imageBenefitsClaas3}
				list={listBenefitsClaas}
			>
				<Slider idsl={'claas-slider'} list={listClaas}/>
			</ListImages>
			<SliderTitle
				id={'park-seeding'}
				idsl={'park-seeding-slider'}
				item={'item'}
				h2Class={'lng-sowing_park_seeding_h2'}
				listSl={listParkSeedingSowing}
			/>
			<TextList
				id={'diagnostics'}
				h2Class={'lng-sowing_diagnostics_h2'}
				src={iconTitleDiagnostics}
				h3Class={'lng-sowing_diagnostics_h3'}
				p1Class={'lng-sowing_diagnostics_p1'}
				p2Class={'lng-sowing_diagnostics_p2'}
				p3Class={'lng-sowing_diagnostics_p3'}
				minRow={1}
				maxRow={6}
				classListDesc={'lng-sowing_diagnostics_desc'}
			/>
			<BannerBox
				id={'sowing-banner-box-end'}
				image={imageBanner2}
				classesH2={'lng-sowing_banner_title2'}
				classesP={'lng-sowing_banner_p2'}
				classesBtn={'lng-sowing_banner_btn2 btn'}
				onClick={props.onClick}
			/>
			<Tariffs
				id={'tariffs'}
				h2Class={'lng-sowing_tariffs_h2'}
				h3Class={'lng-sowing_tariffs_h3'}
				minRow={1}
				maxRow={11}
				classListDesc={'lng-sowing_tariffs_desc'}
				idsl={'tariffs-slider'}
				listSl={listSowingSliderTariffs}
				classDescAfter={'lng-sowing_tariffs_desc_after'}
				btnClass={'btn btn-push lng-sowing_tariffs_btn'}
				onClick={props.onClick}
			/>
			<ListImage
				id={'radius'}
				lt={2}
				rt={1}
				h2Class={'lng-sowing_radius_h2'}
				image={imageRadius}
				list={listSowingRadius}
				shadow={'shadow'}
			/>
			<ListImageText
				h2Class={'lng-sowing_extra_h2'}
				listItems={listSowingItemsExtra}
			/>
		</main>
	);
};

export default Sowing;

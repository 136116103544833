import complexItemHarvestingImg1 from '../images/icons/sec3_3.jpg';
import complexItemHarvestingImg2 from '../images/icons/transaction-2.png';
import complexItemHarvestingImg3 from '../images/icons/transaction-3.png';

import complexItemNonStandardQualityImg1 from '../images/icons/sec3_1.jpg';
import complexItemNonStandardQualityImg2 from '../images/icons/transaction-1.png';
import complexItemNonStandardQualityImg3 from '../images/icons/sec3_2.jpg';
import complexItemNonStandardQualityImg4 from '../images/icons/transaction-2.png';
import complexItemNonStandardQualityImg5 from '../images/icons/services/item03.jpg';
import complexItemNonStandardQualityImg6 from '../images/icons/services/item04.jpg';
import complexItemNonStandardQualityImg7 from '../images/icons/services/item04.jpg';

import complexItemExport2Img1 from '../images/icons/i-price1.png';
import complexItemExport2Img2 from '../images/icons/i-profit.png';
import complexItemExport2Img3 from '../images/icons/services/item03.jpg';

export const listComplexWorking = [
	{id: 1, classesH3: 'lng-complex_working1_h3', classesP: 'dn', classesSpan: 'lng-complex_working_span1'},
	{id: 2, classesH3: 'lng-complex_working2_h3', classesP: 'dn', classesSpan: 'lng-complex_working_span2'},
	{id: 3, classesH3: 'lng-complex_working3_h3', classesP: 'dn', classesSpan: 'lng-complex_working_span3'}
]

export const listComplexItemsHarvesting = [
	{id: 1, classes: 'lng-complex_harvesting_p1', pic: complexItemHarvestingImg1},
	{id: 2, classes: 'lng-complex_harvesting_p2', pic: complexItemHarvestingImg2},
	{id: 3, classes: 'lng-complex_harvesting_p3', pic: complexItemHarvestingImg3}
]

export const listComplexPhases = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_phases_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_phases_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_phases_li3'}
]

export const listComplexReloaderHopper = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_reloader_hoppers_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_reloader_hoppers_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_reloader_hoppers_li3'}
]

export const listComplexPaymentHarvest = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_payment_harvest_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_payment_harvest_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_payment_harvest_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_payment_harvest_li4'}
]

export const listComplexNonStandardQuality = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_non_standard_quality_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_non_standard_quality_li2'}
]

export const listComplexItemsNonStandardQuality = [
	{id: 1, classes: 'lng-complex_non_standard_quality_p1', pic: complexItemNonStandardQualityImg1},
	{id: 2, classes: 'lng-complex_non_standard_quality_p2', pic: complexItemNonStandardQualityImg2},
	{id: 3, classes: 'lng-complex_non_standard_quality_p3', pic: complexItemNonStandardQualityImg3},
	{id: 4, classes: 'lng-complex_non_standard_quality_p4', pic: complexItemNonStandardQualityImg4},
	{id: 5, classes: 'lng-complex_non_standard_quality_p5', pic: complexItemNonStandardQualityImg5},
	{id: 6, classes: 'lng-complex_non_standard_quality_p6', pic: complexItemNonStandardQualityImg6},
	{id: 7, classes: 'lng-complex_non_standard_quality_p7', pic: complexItemNonStandardQualityImg7}
]

export const listSComplexRadius = [
	{id: 1, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_radius_li1'},
	{id: 2, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_radius_li2'},
	{id: 3, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_radius_li3'},
	{id: 4, classesH3: 'dn', classesSpan: 'dn', classesP: 'lng-complex_radius_li4'}
]

export const listComplexExport = [
	{id: 1, classesH3: 'lng-complex_export1_h3', classesP: 'dn', classesSpan: 'lng-complex_export_span1'},
	{id: 2, classesH3: 'lng-complex_export2_h3', classesP: 'dn', classesSpan: 'lng-complex_export_span2'},
	{id: 3, classesH3: 'lng-complex_export3_h3', classesP: 'dn', classesSpan: 'lng-complex_export_span3'}
]

export const listComplexExport2 = [
	{id: 1, classes: 'lng-complex_export2_p1', pic: complexItemExport2Img1},
	{id: 2, classes: 'lng-complex_export2_p2', pic: complexItemExport2Img2},
	{id: 3, classes: 'lng-complex_export2_p3', pic: complexItemExport2Img3}
]

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Picture from '../../components/Picture/Picture';
import TitleH3 from '../../components/Title/TitleH3';
import ListItems from '../../components/ListItems/ListItems';

import './TextList.scss';

function TextList(props) {
	return (
		<section id={props.id} className={'text-list'}>
			<div className="container wrap">
				<TitleH2 {...props}/>
				<div className="wrapper">
					<div className="left">
						<div className="title-box">
							<Picture src={props.src}/>
							<TitleH3 {...props}/>
						</div>
						<p className={props.p1Class}/>
						<p className={props.p2Class}/>
						<p className={props.p3Class}/>
					</div>
					<div className="right">
						<ListItems {...props}/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default TextList;

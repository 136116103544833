import React from 'react';
import './SingleContainer.scss'
import Picture from '../../components/Picture/Picture';
import TitleH2 from '../../components/Title/TitleH2';
import Btn from '../../components/Btn/Btn';

const SingleTitleContainer = (props) => {
	return (
		<div className={'banner-box'}>
			<div className="wrap">
				<Picture classes={'shadow'} src={props.image}/>
			</div>
			<div className="text a-center">
				<TitleH2 h2Class={props.classesH2}/>
				<p className={props.classesP}/>
				<Btn onClick={props.onClick} btnClass={props.classesBtn}/>
			</div>
		</div>
	);
};

export default SingleTitleContainer;

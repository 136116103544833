import React, {useEffect} from 'react';
import {contextArrHome} from '../../db/db_page_home';
import {
	listTechnics,
	listGrows,
	listHomeItemsSuccess,
	listBestBrands,
	listForWho,
	listRadius,
	listAgropark,
	listTextColBoxHome, listHomeItemsSuccessProfit
} from '../../db/db_lists_home';
import {listParkHardTechnics} from '../../db/db_listSliderParkTechnics'
import './Home.scss';

import offerMax from '../../images/offer-bg.jpg';
import offerMin from '../../images/offer-bg-mob.jpg';
import imageGrows from '../../images/sec3_bg.jpg';
import imageBanner from '../../images/banner_box1.jpg';
import imageWho1 from '../../images/for-who1.jpg';
import imageWho2 from '../../images/for-who2.jpg';
import imageWho3 from '../../images/for-who3.jpg';
import imageBestBrands from '../../images/best-brand.jpg';
import imageRadius from '../../images/radius.jpg';

import Offer from '../../sections/Offer/Offer';
import Technology from '../../sections/Technology/Technology';
import ListImage from '../../sections/ListImage/ListImage';
import WeHave from '../../sections/WeHave/WeHave';
import Services from '../../sections/Services/Services';
import Technics from '../../sections/Technics/Technics';
import BannerBox from '../../sections/BannerBox/BannerBox';
import ListImages from '../../sections/ListImages/ListImages';
import SliderBox from '../../sections/SliderBox/SliderBox';
import ListImageText from '../../sections/ListImageText/ListImageText';
import TextColBox from '../../sections/TextColBox/TextColBox';
import SliderTitle from '../../sections/SliderTitle/SliderTitle';

const Home = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrHome) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrHome[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'homepage'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-home_offer_h1'}
				h3Class={'lng-home_offer_h3'}
				btnClass={'btn lng-home_offer_btn'}
				onClick={props.onClick}
				{...props}
			/>
			<Technology {...props} />
			<ListImage
				id={'grows'}
				lt={1}
				rt={2}
				h2Class={'lng-home_grows_h2'}
				list={listGrows}
				image={imageGrows}
				shadow={''}
			/>
			<WeHave {...props} />
			<Services {...props} />
			<Technics
				idsl={'technics-slider'}
				el={'el'}
				classesH2={'lng-home_technics_h2'}
				classesP={'lng-home_technics_p'}
				list={listTechnics}
				{...props}
			/>
			<ListImageText
				h2Class={'lng-home_success_h2'}
				listItems={listHomeItemsSuccess}
			/>
			<SliderTitle
				id={'park-hard-technics'}
				idsl={'park-hard_technics-slider'}
				item={'item'}
				h2Class={'lng-home_park_hard_technics_h2'}
				listSl={listParkHardTechnics}
			/>
			<ListImage
				id={'best-brands'}
				lt={1}
				rt={2}
				h2Class={'lng-home_best_brands_h2'}
				list={listBestBrands}
				image={imageBestBrands}
				shadow={''}
			/>
			<TextColBox
				idsl={'benefits'}
				classesH2={'lng-home_benefits_h2'}
				list={listTextColBoxHome}
			/>
			<BannerBox
				image={imageBanner}
				classesH2={'lng-home_banner_title'}
				classesP={'lng-home_banner_p'}
				classesBtn={'lng-home_banner_btn btn'}
				onClick={props.onClick}
			/>
			<ListImages
				id={'for-who'}
				classesH2={'lng-home_for_who_h2'}
				image1={imageWho1}
				image2={imageWho2}
				image3={imageWho3}
				list={listForWho}
			/>
			<ListImage
				id={'radius'}
				lt={2}
				rt={1}
				h2Class={'lng-home_radius_h2'}
				image={imageRadius}
				list={listRadius}
				shadow={'shadow'}
			/>
			<SliderBox
				id={'agropark'}
				idsl={'agropark-slider'}
				el={'item'}
				h2Class={'lng-home_agropark_h2'}
				pClass={'lng-home_agropark_p'}
				btnClass={'lng-home_agropark_btn btn'}
				list={listAgropark}
				onClick={props.onClick}
			/>
			<ListImageText
				h2Class={'lng-home_success_profit_h2'}
				listItems={listHomeItemsSuccessProfit}
			/>
		</main>
	);
};

export default Home;

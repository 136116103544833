import React, {useEffect} from 'react';
import {contextArrCookies} from '../../db/db_page_cookies';
import './Cookies.scss'

import offerMax from '../../images/cookies/offer-bg.jpg';
import offerMin from '../../images/cookies/offer-bg-mob.jpg';

import Offer from '../../sections/Offer/Offer';
import TextBox from '../../components/TextBox/TextBox';

const Cookies = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrCookies) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrCookies[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'cookies'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-cookies_offer_h1'}
				h3Class={'lng-cookies_offer_h3'}
				btnClass={'btn lng-cookies_offer_btn'}
				onClick={props.onClick}
				{...props}
			/>
			<TextBox
				id={'cookies-text'}
				classesP={'lng-cookies_text'}
			/>
		</main>
	);
};

export default Cookies;

import React from 'react';
import Picture from '../Picture/Picture';

function SliderBigItem(props) {
	return (
		<li>
			<Picture src={props.icon}/>
			<p className={props.text}/>
		</li>
	);
}

export default SliderBigItem;

import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Card from '../../components/Card/Card';
import './WeHave.scss';

const WeHave = (props) => {
	const listLeft = [
		{id: 1, color: '#00af41', classSpan: 'lng-home_wehave_span1', classP: 'lng-home_wehave_p1'},
		{id: 2, color: '#0082ca', classSpan: 'lng-home_wehave_span2', classP: 'lng-home_wehave_p2'},
		{id: 3, color: '#bc6124', classSpan: 'lng-home_wehave_span3', classP: 'lng-home_wehave_p3'},
	]
	
	const listRight = [
		{id: 4, color: '#0082ca', classSpan: 'lng-home_wehave_span4', classP: 'lng-home_wehave_p4'},
		{id: 5, color: '#ffb700', classSpan: 'lng-home_wehave_span5', classP: 'lng-home_wehave_p5'},
		{id: 6, color: '#00af41', classSpan: 'lng-home_wehave_span6', classP: 'lng-home_wehave_p6'},
	]
	
	return (
		<section id={'wehave'} className={'wehave'}>
			<div className="container">
				<div className="wrap">
					<TitleH2 h2Class={'lng-home_wehave_h2'}/>
					<div className="wrapper">
						<div className="left">
							<ul>
								{listLeft.map(item =>
									<Card
										key={item.id}
										color={item.color}
										classes={'box-shadow'}
										classSpan={item.classSpan}
										classP={item.classP}
									/>
								)}
							</ul>
						</div>
						<div className="right">
							<ul>
								{listRight.map(item =>
									<Card
										key={item.id}
										color={item.color}
										classes={'box-shadow'}
										classSpan={item.classSpan}
										classP={item.classP}
									/>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WeHave;

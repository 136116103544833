import React, {useEffect} from 'react';
import {contextArrNotfound} from '../../db/db_page_notfound';
import './NotFiund.scss';

import offerMax from '../../images/error404/offer-bg.jpg';
import offerMin from '../../images/error404/offer-bg-mob.jpg';

import Offer from '../../sections/Offer/Offer';

const NotFound = (props) => {
	let {locale} = props;
	
	useEffect(() => {
		for (let key in contextArrNotfound) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrNotfound[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'not-found'}>
			<Offer
				id={'offer'}
				classImgMax={'offer-bg-max'}
				classImgMin={'offer-bg-min dn'}
				srcMax={offerMax}
				srcMin={offerMin}
				h1Class={'lng-not_found_offer_h1'}
				h3Class={'lng-not_found_offer_h3'}
			/>
		</main>
	);
};

export default NotFound;

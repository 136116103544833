import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import TextColBoxItem from './TextColBoxItem';

import './TextColBox.scss';

function TextColBox(props) {
	return (
		<section id={props.idsl} className={'text-col-box'}>
			<div className="container">
				<TitleH2 h2Class={props.classesH2}/>
				<ul>
					{props.list.map(item =>
						<TextColBoxItem key={item.id} h3Class={item.classesH3} classesP={item.classesP}/>
					)}
				</ul>
				{props.children}
			</div>
		</section>
	);
}

export default TextColBox;

import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import App from '../App';

const LangRouting = () => {
	// localStorage.clear();
	let [locale, setLocale] = useState(window.localStorage.getItem('locale'));
	// let [locale, setLocale] = useState('ua');
	
	if (locale === null || locale === undefined) {
		window.localStorage.setItem('locale', 'ua');
	} else {
		window.localStorage.getItem('locale');
	}
	
	let location = useLocation();
	let urlLocale = location.pathname.substring(1, 3)
	
	useEffect(() => {
		if (locale !== urlLocale) {
			setLocale(urlLocale);
		}
	}, [locale, urlLocale]);
	
	useEffect(() => {
		let newLocale = locale;
		if (!['ua', 'ru'].includes(newLocale)) {
			newLocale = 'ua';
		}
		
		window.localStorage.setItem('locale', newLocale);
		setLocale(newLocale);
	}, [locale]);
	
	return (
		<Switch>
			<Route exact path="/">
				<Redirect to={locale + '/'}/>
			</Route>
			<Route path="/ua/*">
				<App locale={locale} setLocale={setLocale}/>
			</Route>
			<Route path="/ru/*">
				<App locale={locale} setLocale={setLocale}/>
			</Route>
			<Redirect to={'/ua/'}/>
		</Switch>
	)
}

export default LangRouting;

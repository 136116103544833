export const contextArrPolitics = {
	// Page: Politics
	politics_offer_h1: {
		ua: 'Політика конфіденційності',
		ru: 'Политика конфиденциальности',
	},
	politics_offer_h3: {
		ua: 'Персональні дані користувачів сайту обробляються у порядку, передбаченому чинним законодавством',
		ru: 'Персональные данные пользователей сайта обрабатываются в порядке, предусмотренном действующим законодательством',
	},
	politics_offer_btn: {
		ua: 'Детальнiше',
		ru: 'Детальнее',
	},
	
	// Text
	politics_text: {
		ua: `
		<h2>Політика конфіденційності</h2><br/>
		Персональні дані користувачів сайту обробляються у порядку, передбаченому чинним законодавством, зокрема Законом України "Про захист персональних даних", Законом України "Про інформацію", Законом України "Про електронну комерцію" та іншими нормативними актами.<br>
		<h3>Мета обробки</h3><br/>
		Персональні дані обробляються CAREFIELD з метою покращення взаємодії користувача з сайтом carefield.adbbox.com, а також із рекламною та маркетинговою метою.<br/>
		<h3>Підставі обробки</h3><br/>
		Персональні дані обробляються на підставі згоди користувача, що надається в цифровому (електронному) вигляді з використанням цифрових засобів сайту.<br/>
		<h3>Склад персональних даних</h3><br/>
		До персональних даних належать всі відомості про користувача, що надаються користувачем під час заповнення відповідних інформаційних граф при заповненні заявки зворотного зв'язку на сайті carefield.adbbox.com.<br/>
		<h3>Порядок обробки</h3><br/>
		Персональні дані збираються, накопичуються та зберігаються у цифровому (електронному) вигляді на інформаційних носіях, визначених Продавцем у лиці CAREFIELD протягом строку, що визначається CAREFIELD та об’єктивно необхідний для виконання мети обробки таких даних, однак у будь-якому випадку не менше трьох років.<br/>
		Зміна, видалення або знищення персональних даних здійснюється за самостійним рішенням CAREFIELD та/або на вимогу користувача сайту.<br/>
		Заходи забезпечення захисту персональних даних визначаються окремо на підставі відповідних рішень CAREFIELD. Заходи забезпечення захисту персональних даних у будь-якому випадку мають забезпечувати їх збереження, попередження несанкціонованому доступу третіх осіб до таких даних (розголошенню) та/або їх втраті, несанкціонованій зміні, знищенню.<br/>
		<h3>Правила користувача, як суб'єкта персональних даних</h3><br/>
		Покупець має щодо своїх персональних даних всі права, передбачені Законом України "Про захист персональних даних":<br/>
		- знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки;<br/>
		- отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані;<br/>
		- на доступ до своїх персональних даних;<br/>
		- отримувати не пізніше як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються його персональні дані, а також отримувати зміст таких персональних даних;<br/>
		- пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх персональних даних;<br/>
		- пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;<br/>
		- на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;<br/>
		- звертатися зі скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;<br/>
		- застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;<br/>
		- вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;<br/>
		- відкликати згоду на обробку персональних даних.<br/>
		<h3>Правила використання</h3><br/>
		Всі елементи сайту: доменне ім'я, дизайн сторінок, програмні рішення, бази даних, контент: текст, фото — належать CAREFIELD.<br/>
		Будь-яке використання або копіювання матеріалів (тексти, фото, аудіо, відео), елементів дизайну та оформлення допускається. лише з дозволу правовласника.
`,
		ru: `
		<h2>Политика конфиденциальности</h2><br/>
		Персональные данные пользователей сайта обрабатываются в порядке, предусмотренном действующим законодательством, в частности, Законом Украины "О защите персональных данных", Законом Украины "Об информации", Законом Украины "Об электронной коммерции" и другими нормативными актами.<br>
		<h3>Цель обработки</h3><br/>
		Персональные данные обрабатываются CAREFIELD с целью улучшения взаимодействия пользователя с сайтом carefield.adbbox.com, а также в рекламных и маркетинговых целях.<br/>
		<h3>Основания обработки</h3><br/>
		Персональные данные обрабатываются на основании согласия пользователя, предоставляемого в цифровом (электронном) виде с использованием цифровых средств сайта.<br/>
		<h3>Состав персональных данных</h3><br/>
		К персональным данным относятся все сведения о пользователе, предоставляемые пользователем при заполнении соответствующих информационных граф при заполнении заявки обратной связи на сайте carefield.adbbox.com.<br/>
		<h3>Порядок обработки</h3><br/>
		Персональные данные собираются, накапливаются и хранятся в цифровом (электронном) виде на информационных носителях, определенных Продавцом в лице CAREFIELD в течение срока, определяемого CAREFIELD и объективно необходимым для выполнения целей обработки таких данных, однако в любом случае не менее трех лет .<br/>
		Изменение, удаление или уничтожение персональных данных производится по самостоятельному решению CAREFIELD и/или по требованию пользователя сайта.<br/>
		Меры по обеспечению защиты персональных данных определяются отдельно на основании соответствующих решений CAREFIELD. Меры обеспечения защиты персональных данных в любом случае должны обеспечивать их сохранность, предупреждение несанкционированному доступу третьих лиц к таким данным (разглашению) и/или их потере, несанкционированному изменению, уничтожению.<br/>
		<h3>Правила пользователя как субъекта персональных данных</h3><br/>
		Покупатель имеет по своим персональным данным все права, предусмотренные Законом Украины "О защите персональных данных":<br/>
		- знать об источниках сбора, местонахождении своих персональных данных, цели их обработки;<br/>
		- получать информацию об условиях предоставления доступа к персональным данным, в частности, информацию о третьих лицах, которым передаются его персональные данные;<br/>
		- на доступ к своим персональным данным;<br/>
		- получать не позднее чем через тридцать календарных дней со дня поступления запроса, кроме случаев, предусмотренных законом, ответ о том, обрабатываются ли его персональные данные, а также получать содержание таких персональных данных;<br/>
		- предъявлять мотивированное требование владельцу персональных данных с возражением против обработки своих персональных данных;<br/>
		- предъявлять мотивированное требование об изменении или уничтожении своих персональных данных любым владельцем и распорядителем персональных данных, если эти данные обрабатываются незаконно или недостоверны;<br/>
		- на защиту своих персональных данных от незаконной обработки и случайной потери, уничтожения, повреждения в связи с умышленным сокрытием, непредоставлением или несвоевременным их предоставлением, а также на защиту от предоставления сведений, недостоверных или порочащих честь, достоинство и деловую репутацию физической. лица;<br/>
		- обращаться с жалобами на обработку своих персональных данных в Уполномоченный Верховной Рады Украины по правам человека или в суд;<br/>
		- применять средства правовой защиты в случае нарушения законодательства о защите персональных данных;<br/>
		- вносить оговорки об ограничении права на обработку своих персональных данных при предоставлении согласия;<br/>
		- отозвать согласие на обработку персональных данных.<br/>
		<h3>Правила использования</h3><br/>
		Все элементы сайта: доменное имя, дизайн страниц, программные решения, базы данных, контент: текст, фото - принадлежат CAREFIELD.<br/>
		Любое использование или копирование материалов (тексты, фото, аудио, видео), элементы дизайна и оформления допускается. только с разрешения правообладателя.
		`,
	},
}

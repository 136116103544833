import React from 'react';
import TitleH3 from '../Title/TitleH3';
import {Link} from 'react-router-dom';
import Picture from '../Picture/Picture';
import './CardService.scss'

const CardService = (props) => {
	return (
		<div className="card-item">
			<Picture classes={'image-max shadow'} src={props.src} alt={props.alt}/>
			<Picture classes={'image-min shadow dn'} src={props.srcMob} alt={props.alt}/>
			<div className="text a-center">
				<TitleH3 h3Class={props.classH3} style={{color: props.color}} />
				<p className={props.classP} />
				<Link className={props.classBtn} to={props.link} style={{backgroundColor: props.color}} />
			</div>
		</div>
	);
};

export default CardService;

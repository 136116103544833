export const contextArrFestival = {
	// Page: Festival
	festival_offer_h1: {
		ua: 'AGRO TECHNO FESTIVAL',
		ru: 'AGRO TECHNO FESTIVAL',
	},
	festival_offer_h3: {
		ua: 'Наш фестиваль поєднує нетворкінг і розвиток. Інноваційні ідеї від найкращих спікерів агробізнесу',
		ru: 'Наш фестиваль совмещает нетворкинг и развитие. Инновационные идеи от лучших спикеров агробизнеса',
	},
	festival_offer_btn: {
		ua: 'Детальнiше',
		ru: 'Детальнее',
	},
	
	// Principles
	festival_principles_h2: {
		ua: 'Принципи AGRO TECHNO FESTIVAL',
		ru: 'Принципы AGRO TECHNO FESTIVAL',
	},
	festival_principles1_h3: {
		ua: 'Експозіцiя техники',
		ru: 'Экспозиция техники',
	},
	festival_principles_span1: {
		ua: 'Спеціалізована виставка сільськогосподарської техніки, технологій, обладнання та запасних частин',
		ru: 'Специализированная выставка сельскохозяйственной техники, технологий, оборудования и запасных частей',
	},
	festival_principles2_h3: {
		ua: 'Демонстраційні покази',
		ru: 'Демонстрационные показы',
	},
	festival_principles_span2: {
		ua: 'Польова демонстрація найкращих зразків сільськогосподарської техніки',
		ru: 'Полевая демонстрация лучших образцов сельскохозяйственной техники',
	},
	festival_principles3_h3: {
		ua: 'Ділова програма',
		ru: 'Деловая программа',
	},
	festival_principles_span3: {
		ua: 'Ділові заходи - форуми, зустрічі та конференції з питань ефектівного агробізнесу',
		ru: 'Деловые мероприятия – форумы, встречи и конференции по вопросам эффективного агробизнеса',
	},
	
	// Festival About
	festival_about_h2: {
		ua: 'AGRO TECHNO FESTIVAL - це:',
		ru: 'AGRO TECHNO FESTIVAL - это:',
	},
	festival_about_li1: {
		ua: 'Головна виставкова подія у агропромисловому комплексі регіону',
		ru: 'Главное выставочное событие в агропромышленном комплексе региона',
	},
	festival_about_li2: {
		ua: 'Ефективна платформа для демонстрації найновіших галузевих досягнень',
		ru: 'Эффективная платформа для демонстрации новейших отраслевых достижений',
	},
	festival_about_li3: {
		ua: 'Презентація нової техніки та устаткування, інноваційних рішень і технологій',
		ru: 'Презентация новой техники и оборудования, инновационных решений и технологий',
	},
	festival_about_li4: {
		ua: 'Масштабна польова демонстрація роботи техніки',
		ru: 'Масштабная полевая демонстрация работы техники',
	},
	festival_about_li5: {
		ua: 'Зустріч ключових учасників агропромислового ринку',
		ru: 'Встреча ключевых участников агропромышленного рынка',
	},
	festival_about_li6: {
		ua: 'Відкриття нових можливостей для розвитку локального і національного бізнесу',
		ru: 'Открытие новых возможностей для развития локального и национального бизнеса',
	},
	
	// Slider Brands
	festival_participants_h2: {
		ua: 'Учасники AGRO TECHNO FESTIVAL',
		ru: 'Участники AGRO TECHNO FESTIVAL',
	},
	festival_participants_p: {
		ua: '<b>Більшь ніж 300 учасніків агропродукції</b>, світові лідері агрохімії та агротехники, для виконання всіх виробничих агрооперацій. Серед них такі бренди: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE, AVGUST, BAYER, ADAMA, PIONEER, ALFA AGRO SMART, SYNGENTA',
		ru: '<b>Более 300 участников агропродукции</b>, мировые лидеры агрохимии и агротехники, для выполнения всех производственных агроопераций. Среди них такие бренды: HORSCH, CASE, STROM, KELLO BILT, SCHULTE, DOREZ, VADERSTAD, JACTO, DJI, XAG, CLAAS, JOHN DEERE, AVGUST, BAYER, ADAMA, PIONEER, ALFA AGRO SMART, SYNGENTA',
	},
	
	// Festival Numbers
	festival_numbers_h2: {
		ua: 'AGRO TECHNO FESTIVAL в цифрах:',
		ru: 'AGRO TECHNO FESTIVAL в цифрах:',
	},
	festival_numbers_p1: {
		ua: '<b>25 000 м<sup>2</sup> виставкова площа</b>',
		ru: '25 000 м<sup>2</sup> виставкова площа',
	},
	festival_numbers_p2: {
		ua: '<b>17 000 відвідувачів</b>',
		ru: '<b>17 000 посетителей</b>',
	},
	festival_numbers_p3: {
		ua: '<b>близько 300 учасників</b>',
		ru: '<b>около 300 участников</b>',
	},
	
	// Banner #1
	festival_banner_title: {
		ua: 'AGRO TECHNO FESTIVAL',
		ru: 'AGRO TECHNO FESTIVAL',
	},
	festival_banner_p: {
		ua: 'Демонстрація найкращих зразків сільськогосподарської техніки та агрохімії',
		ru: 'Демонстрация лучших образцов сельскохозяйственной техники и агрохимии',
	},
	festival_banner_btn: {
		ua: 'Детальнiше',
		ru: 'Подробней',
	},
}

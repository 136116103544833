import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './LangButtons.scss'

const LangButtons = () => {
	let [locale, setLocale] = useState(window.localStorage.getItem('locale'));
	let [langActive, setLangActive] = useState(true);
	let location = useLocation();
	let history = useHistory();
	
	if (locale === null || locale === undefined) {
		window.localStorage.setItem('locale', 'ua');
	} else {
		window.localStorage.getItem('locale');
	}
	
	function switchLocale(newLocale) {
		let url = '/' + newLocale + location.pathname.substring(3);
		history.push(url);
		setLangActive(!langActive);
		setLocale(newLocale);
		
		// console.log(newLocale);
		// console.log(url);
	}
	
	return (
		<div className="lang">
			<button className={(locale === 'ua') ? 'first-btn active' : 'first-btn'} onClick={() => switchLocale('ua')}>ua
			</button>
			<button className={(locale === 'ru') ? 'second-btn active' : 'second-btn'} onClick={() => switchLocale('ru')}>ru
			</button>
		</div>
	)
	
}

export default LangButtons;

export const contextArrHeader = {
	// Menu items
	menu1: {
		ua: 'Головна',
		ru: 'Главная',
	},
	menu2: {
		ua: 'Підготовка грунту',
		ru: 'Подготовка грунта',
	},
	menu3: {
		ua: 'Посівні роботи',
		ru: 'Посевные работы',
	},
	menu4: {
		ua: 'Захист рослин',
		ru: 'Защита урожая',
	},
	menu5: {
		ua: 'Збір і продаж',
		ru: 'Сбор и продажа',
	}
}

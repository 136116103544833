import React from 'react';
import TitleH2 from '../../components/Title/TitleH2';
import Picture from '../../components/Picture/Picture';
import TitleH3 from '../../components/Title/TitleH3';
import './ListImageText.scss';

const ListImageText = (props) => {
	
	return (
		<section className="list-image-text">
			<div className="container">
				<TitleH2 {...props} />
				<ul>
					{props.listItems.map(item =>
						<li key={item.id}>
							{item.pic ? <Picture src={item.pic}/> : <TitleH3 h3Class={item.h3Class}/>}
							<p className={item.classes}/>
						</li>
					)}
				</ul>
			</div>
		</section>
	);
};

export default ListImageText;

import React, {useEffect} from 'react';
import './Ground.scss';

import {contextArrGround} from '../../db/db_page_ground';
import {listParkTechnics, listParkFertilization} from '../../db/db_listSliderParkTechnics';
import {
	listSixItems,
	listTechnics,
	listPrinciples,
	listMethod,
	listGroundItemsServices,
	listWorking,
	listGroundItemsResult,
	listSliderTariffs,
	listGroundItemsSuccess,
	listRadius,
	listСonditions
} from '../../db/db_lists_ground';

import offerRadiusMax from '../../images/ground/offer-bg-scaled.jpg';
import offerRadiusMin from '../../images/ground/offer-bg-min.jpg';

import imageBanner from '../../images/banner_box2.jpg';
import imageBanner2 from '../../images/banner_box3.jpg';
import imageBanner3 from '../../images/banner_box4.jpg';
import imageBanner4 from '../../images/banner_box5.jpg';
import imagePrinciples from '../../images/ground/principles.jpg';
import imageMethod from '../../images/ground/method-bg.jpg';
import imageWorking from '../../images/ground/working.jpg';
import imageRadius from '../../images/radius.jpg';
import imageConditions from '../../images/ground/conditions.jpg';

import OfferRadius from '../../sections/OfferRadius/OfferRadius';
import Technics from '../../sections/Technics/Technics';
import SliderTitle from '../../sections/SliderTitle/SliderTitle';
import BannerBox from '../../sections/BannerBox/BannerBox';
import ListImage from '../../sections/ListImage/ListImage';
import ListImageText from '../../sections/ListImageText/ListImageText';
import Tariffs from '../../sections/Tariffs/Tariffs';

const Ground = (props) => {
	let {locale} = props
	
	useEffect(() => {
		for (let key in contextArrGround) {
			let elem = document.querySelector('.lng-' + key);
			if (elem) {
				elem.innerHTML = contextArrGround[key][locale];
			}
		}
	}, [locale]);
	
	return (
		<main id={'ground'}>
			<OfferRadius
				classImgMax={'offer-radius-bg-max'}
				classImgMin={'offer-radius-bg-min dn'}
				srcMax={offerRadiusMax}
				srcMin={offerRadiusMin}
				altMax={'offer-radius-bg-max'}
				altMin={'offer-radius-bg-min'}
				h1Class={'lng-ground_offer_h1'}
				h3Class={'lng-ground_offer_h3'}
				btnClass={'btn lng-ground_offer_btn'}
				onClick={props.onClick}
				{...props}
			>
				<ListImageText
					h2Class={'lng-ground_offer_h2'}
					listItems={listSixItems}
				/>
			</OfferRadius>
			<Technics
				idsl={'technics-slider'}
				el={'el'}
				classesH2={'lng-ground_technics_h2'}
				classesP={'lng-ground_technics_p'}
				list={listTechnics}
				{...props}
			/>
			<BannerBox
				image={imageBanner}
				classesH2={'lng-ground_banner_title'}
				classesP={'lng-ground_banner_p'}
				classesBtn={'lng-ground_banner_btn btn'}
				onClick={props.onClick}
			/>
			<SliderTitle
				id={'park-technics'}
				idsl={'park-technics-slider'}
				item={'item'}
				h2Class={'lng-ground_park_technics_h2'}
				listSl={listParkTechnics}
			/>
			<ListImage
				id={'principles'}
				lt={1}
				rt={2}
				h2Class={'lng-ground_principles_h2'}
				list={listPrinciples}
				image={imagePrinciples}
				shadow={''}
			/>
			<ListImage
				id={'method'}
				lt={2}
				rt={1}
				h2Class={'lng-ground_method_h2'}
				list={listMethod}
				image={imageMethod}
				shadow={''}
			/>
			<BannerBox
				image={imageBanner2}
				classesH2={'lng-ground_banner_title2'}
				classesP={'lng-ground_banner_p2'}
				classesBtn={'lng-ground_banner_btn2 btn'}
				onClick={props.onClick}
			/>
			<SliderTitle
				id={'technics-fertilization'}
				idsl={'technics-fertilization-slider'}
				item={'item'}
				h2Class={'lng-ground_technics_fertilization_h2'}
				listSl={listParkFertilization}
			/>
			<ListImageText
				h2Class={'lng-ground_services_h2'}
				listItems={listGroundItemsServices}
			/>
			<ListImage
				id={'working'}
				lt={1}
				rt={2}
				h2Class={'lng-ground_working_h2'}
				list={listWorking}
				image={imageWorking}
				shadow={''}
			/>
			<ListImageText
				h2Class={'lng-ground_result_h2'}
				listItems={listGroundItemsResult}
			/>
			<BannerBox
				image={imageBanner3}
				classesH2={'lng-ground_banner_title3'}
				classesP={'lng-ground_banner_p3'}
				classesBtn={'lng-ground_banner_btn3 btn'}
				onClick={props.onClick}
			/>
			<Tariffs
				id={'tariffs'}
				h2Class={'lng-ground_tariffs_h2'}
				h3Class={'lng-ground_tariffs_h3'}
				minRow={1}
				maxRow={15}
				classListDesc={'lng-ground_tariffs_desc'}
				idsl={'tariffs-slider'}
				listSl={listSliderTariffs}
				classDescAfter={'lng-ground_tariffs_desc_after'}
				btnClass={'btn btn-push lng-ground_tariffs_btn'}
				onClick={props.onClick}
			/>
			<ListImage
				id={'radius'}
				lt={2}
				rt={1}
				h2Class={'lng-ground_radius_h2'}
				image={imageRadius}
				list={listRadius}
				shadow={'shadow'}
			/>
			<ListImage
				id={'сonditions'}
				lt={1}
				rt={2}
				h2Class={'lng-ground_сonditions_h2'}
				image={imageConditions}
				list={listСonditions}
				shadow={''}
			/>
			<BannerBox
				image={imageBanner4}
				classesH2={'lng-ground_banner_title4'}
				classesP={'lng-ground_banner_p4'}
				classesBtn={'lng-ground_banner_btn4 btn'}
				onClick={props.onClick}
			/>
			<ListImageText
				h2Class={'lng-ground_success_h2'}
				listItems={listGroundItemsSuccess}
			/>
		</main>
	);
};

export default Ground;

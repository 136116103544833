import React from 'react';
import './BtnScrollUp.scss'

function BtnScrollUp() {
	const handlerScrollUp = () => {
		if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}
	
	window.onscroll = () => {
		let scrolled = window.pageYOffset || document.documentElement.scrollTop;
		if (scrolled > 1000) {
			document.querySelector('.btn-scroll-up').style.display = 'block';
		} else {
			document.querySelector('.btn-scroll-up').style.display = 'none';
		}
	}
	
	return (
		<div className={'btn-scroll-up'} onClick={handlerScrollUp}>&#9650;</div>
	);
}

export default BtnScrollUp;

import React from 'react';

const Picture = (props) => {
	const styles = {
		overflow: 'hidden',
		borderRadius: '20px'
	}

	return (
		<img className={props.classes} src={props.src}
		     alt={props.alt} style={styles}
		/>
	);
};

export default Picture;

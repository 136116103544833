export const contextArrFooter = {
	// Footer
	footer_title1_h3: {
		ua: 'Контакти',
		ru: 'Контакты',
	},
	footer1_text1: {
		ua: 'На зв\'язку 24/7',
		ru: 'На связи 24/7',
	},
	footer_title2_h3: {
		ua: 'Навігація',
		ru: 'Навигация',
	},
	footer_title3_h3: {
		ua: 'Фестиваль для тих, хто вирощує',
		ru: 'Фестиваль для тeх, кто выращивает',
	},
	footer_title4_h3: {
		ua: 'Слідкувати',
		ru: 'Следить',
	},
	
	// Menu items footer
	menu1_footer: {
		ua: 'Головна',
		ru: 'Главная',
	},
	menu2_footer: {
		ua: 'Підготовка грунту',
		ru: 'Подготовка грунта',
	},
	menu3_footer: {
		ua: 'Посівні роботи',
		ru: 'Посевные работы',
	},
	menu4_footer: {
		ua: 'Захист рослин',
		ru: 'Защита урожая',
	},
	menu5_footer: {
		ua: 'Збір і продаж',
		ru: 'Сбор и продажа',
	},
	footer_btn: {
		ua: 'Сторінка фестивалю',
		ru: 'Страница фестиваля',
	},
	footer_link1: {
		ua: 'Політика конфіденційності',
		ru: 'Политика конфиденциальности',
	},
	footer_link2: {
		ua: 'Політика cookies',
		ru: 'Политика cookies',
	},
	footer_link3: {
		ua: 'Обробка інформації',
		ru: 'Обработка информации',
	},
	modal_btn_text: {
		ua: 'Вiдправити',
		ru: 'Отправить',
	},
}
